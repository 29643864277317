require("dotenv").config();

export function addActivity() {
  return process.env.REACT_APP_API_URL + "/activity/add_activity";
}

export function getActivity() {
  return process.env.REACT_APP_API_URL + "/activity/get_activities";
}

export function getActivityDetailsByID(id) {
  return `/dashboard/activity/edit_activity/${id}`;
}

export function getActivityDetail() {
  return process.env.REACT_APP_API_URL + "/activity/get_activity_details";
}

export function updateActivity() {
  return process.env.REACT_APP_API_URL + "/activity/update_activity_details";
}

export function updateActivityImage() {
  return process.env.REACT_APP_API_URL + "/activity/update_activity_image";
}

export function activityStatus() {
  return process.env.REACT_APP_API_URL + `/activity/update_activity_status`;
}
