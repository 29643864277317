// WYSIWYG COMPONENTS
import { ContentState, convertToRaw, EditorState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import React, { Fragment, useEffect, useState } from "react";

export default function RichText(props) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [content, setContent] = useState("");

  useEffect(() => {
    const contentBlock = htmlToDraft(props.context);
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    const _editorState = EditorState.createWithContent(contentState);

    setEditorState(_editorState);
  }, [props.context]);

  // HTML AND JSX RENDER
  return (
    <Fragment>
      <div id="rich-text">
        <Editor
          editorState={editorState}
          onEditorStateChange={(newState) => {
            setEditorState(newState);
            setContent(draftToHtml(convertToRaw(newState.getCurrentContent())));
            props.setContextValue(content);
          }}
          id={"rich-text-editor"}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbar-class"
          spellCheck
          toolbar={{
            inline: { inDropdown: true },
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            link: { inDropdown: true },
            history: { inDropdown: true },
          }}
        />
      </div>
    </Fragment>
  );
}
