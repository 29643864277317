import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";

import authHeader from "../../../service/auth-header";

// CONTROLS
import {Input} from "../../../component/common/input";
import {DisplayModal} from "../../../component/modal/modal";
import Paginate from "../../../component/pagination/paginate";

// IMAGE URL
import occupationImg from "../../../assets/img/worker.png";

// RESTful
import {
  _updateOccupation,
  editOccupation,
  getOccupation,
  getOccupationByID,
  insertOccupation,
} from "../../../service/occupation";

//  OCCUPATION COMPONENT
export const Occupation = () => {
  return (
    <section className="occupation">
      <div className="container">
        <h1 className="display-3">Occupation</h1>
        <img src={occupationImg} alt="occupation.png" className="img-fluid" />
      </div>
    </section>
  );
};

// ADD NEW OCCUPATION COMPONENT
export const AddOccupation = () => {
  // HOOKS
  const [occupation, setOccupation] = useState("");
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);

  // USE NAVIGATE HOOK
  const navigate = useNavigate();

  // OPEN/CLOSE MODAL FUNCTION
  function onModalOpen() {
    setShow(true);
  }

  function onModalClose() {
    setShow(false);
    if (success) navigate("/dashboard/occupation/view_occupation");
  }

  // ADD NEW COMPONENT FUNCTION
  async function addOccupation(e) {
    e.preventDefault();

    axios
      .post(
        insertOccupation(),
        { occupation },
        { headers: authHeader.authHeader() }
      )
      .then((data) => {
        if (data.data.success) {
          setSuccess(true);
          setMessage(data.data.message);

          onModalOpen();
        } else {
          setSuccess(false);
          setMessage(data.data.message);

          onModalOpen();
        }
      })
      .catch((error) => {
        setSuccess(false);
        setMessage(
          `${occupation} could not be added right now. ${error.message}.`
        );
        onModalOpen();
      });
  }

  // HTML AND JSX RENDER
  return (
    <section className="add-occupation">
      <div className="container">
        <h1>Add Occupation</h1>

        <div className="card border border-primary shadow-sm">
          <div className="card-body">
            <form onSubmit={(e) => addOccupation(e)}>
              <Input
                label={"Enter occupation"}
                type={"text"}
                name={"occupation"}
                placeholder={"Enter occupation type e.g Tertiary student"}
                onChange={(e) => setOccupation(e.target.value)}
              />

              <div className="text-center">
                <button className="btn btn-primary shadow-sm" type="submit">
                  Add occupation
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* MODAL */}
      <DisplayModal
        show={show}
        close={onModalClose}
        message={message}
        title={"Occupation"}
      />
    </section>
  );
};

// VIEW UPLOADED COMPONENTS
export const ViewOccupation = () => {
  // HOOKS
  const [occupation, setOccupation] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");

  // NEW PAGINATION IMPLEMENTATION
  const [activePage, setActivePage] = useState(1);
  const rowsPerPage = 5;
  const [count, setCount] = useState(0);
  const totalPages = Math.ceil(count / rowsPerPage);
  const calculatedRows = occupation.slice(
    (activePage - 1) * rowsPerPage,
    activePage * rowsPerPage
  );

  // OPEN/CLOSE MODAL FUNCTIONS
  function onModalOpen() {
    setShow(true);
  }

  function onModalClose() {
    setShow(false);
  }

  // TABLE COLUMNS
  const columnLabel = [{ label: "No." }, { label: "Type of occupation" }];

  // USE EFFECT
  useEffect(() => {
    // RETRIEVE OCCUPATION THROUGH API CALL
    async function _occupation() {
      setLoading(true);

      await axios
        .get(getOccupation())
        .then((data) => {
          if (data.data.success) {
            setLoading(false);
            setOccupation(data.data.result);
            setCount(data.data.result.length);
          } else {
            setLoading(false);
            setMessage(data.data.message);

            onModalOpen();
          }
        })
        .catch((error) => {
          setLoading(false);
          onModalOpen();
          setMessage(`Occupations could not be displayed. ${error.message}.`);
        });
    }

    _occupation();
  }, []);

  // HTML AND JSX RENDER
  return (
    <section className="view-occupation">
      <div className="container">
        <h1>List of Occupation</h1>

        <div className="card shadow-sm border-primary">
          <div className="card-body">
            <table className="table table-hover">
              <thead>
                <tr>
                  {columnLabel.map((item, index) => {
                    return <th key={index}>{item.label}</th>;
                  })}
                </tr>
              </thead>

              <tbody>
                {loading && (
                  <tr>
                    <td colSpan={3} className={"text-center"}>
                      <p className="text-muted">Loading occupations...</p>

                      <div className="admin-spinner">
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}

                {!occupation.length && !loading && (
                  <tr>
                    <td colSpan={3} className={"text-center"}>
                      <p className="text-muted">
                        There are no occupations to view.
                      </p>
                    </td>
                  </tr>
                )}

                {!loading &&
                  calculatedRows.map((occItem, index) => {
                    return (
                      <tr key={occItem.occupationID}>
                        <td>{index + 1}</td>
                        <td>{occItem.nameOfOccupation}</td>
                        <td>
                          <a
                            className="btn btn-primary btn-sm"
                            href={editOccupation(occItem.occupationID)}
                          >
                            Edit {occItem.nameOfOccupation}'s details
                          </a>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>

            {/*NEW PAGINATION*/}
            <Paginate
              activePage={activePage}
              count={count}
              rowsPerPage={rowsPerPage}
              totalPages={totalPages}
              setActivePage={setActivePage}
            />
          </div>
        </div>
      </div>

      {/* MODAL */}
      <DisplayModal
        show={show}
        close={onModalClose}
        message={message}
        title={"Occupation"}
      />
    </section>
  );
};

// EDIT SELECTED OCCUPATION COMPONENT
export const EditOccupation = () => {
  // HOOKS
  const [occupation, setOccupation] = useState([]);
  const [occupationName, setOccupationName] = useState("");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);

  // USE NAVIGATE HOOK
  const navigate = useNavigate();

  // OCCUPATION ID
  const { id } = useParams();

  // OPEN/CLOSE MODAL FUNCTIONS
  function onModalOpen() {
    setShow(true);
  }

  function onModalClose() {
    setShow(false);
    if (success) navigate("/dashboard/occupation/view_occupation");
  }

  // UPDATE OCCUPATION DETAILS
  async function updateOccupation(e) {
    e.preventDefault();

    await occupation.forEach((item) => {
      if (item) {
        axios
          .put(
            _updateOccupation(),
            {
              id: id,
              occupationName:
                occupationName === "" ? item.nameOfOccupation : occupationName,
            },
            { headers: authHeader.authHeader() }
          )
          .then((data) => {
            if (data.data.success) {
              setSuccess(true);
              setMessage(data.data.message);

              onModalOpen();
            } else {
              setSuccess(false);
              setMessage(data.data.message);

              onModalOpen();
            }
          })
          .catch((error) => {
            setSuccess(false);
            setMessage(`Occupation could not be updated. ${error.message}.`);

            onModalOpen();
          });
      }
    });
  }

  // USE EFFECT
  useEffect(() => {
    // RETRIEVE INFORMATION OF SELECTED OCCUPATION THROUGH API CALL
    async function occupationDetail() {
      setLoading(true);

      await axios
        .get(getOccupationByID(id), {
          headers: authHeader.authHeader(),
        })
        .then((data) => {
          if (data.data.success) {
            setLoading(false);
            setOccupation(data.data.result);
          } else {
            setMessage(data.data.message);
            setLoading(false);

            onModalOpen();
          }
        })
        .catch((error) => {
          setMessage(
            `Occupation details could not be loaded. ${error.message}.`
          );
          setLoading(false);

          onModalOpen();
        });
    }

    occupationDetail();
  }, [id]);

  // HTML AND JSX RENDER
  return (
    <section className="edit-occupation">
      <div className="container">
        <h1>Edit Occupation</h1>

        {loading && (
          <div>
            <p className={"text-muted center"}>Loading occupation details...</p>

            <div className="spinner">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        )}

        {!occupation.length && !loading && (
          <p className={"text-muted center"}>
            Occupation details could not be loaded.
          </p>
        )}

        <div className="card shadow-sm border-primary">
          <div className="card-body">
            {!loading &&
              occupation.map((x, index) => {
                return (
                  <form onSubmit={(e) => updateOccupation(e)} key={index}>
                    <Input
                      label={"Occupation name"}
                      type={"text"}
                      name={"occupationName"}
                      onChange={(e) => setOccupationName(e.target.value)}
                      defaultValue={x.nameOfOccupation}
                    />

                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn btn-primary shadow-sm"
                      >
                        Update occupation
                      </button>
                    </div>
                  </form>
                );
              })}
          </div>
        </div>
      </div>

      {/* MODAL */}
      <DisplayModal
        show={show}
        close={onModalClose}
        message={message}
        title={"Occupation"}
      />
    </section>
  );
};
