import React from "react";

// STYLING BLOG MAIN IMAGE
const BlogHeader = ({ image, title, author }) => {
  let headerStyle = {
    background:
      "linear-gradient(rgba(36, 20, 38, 0.5), rgba(36, 39, 38, 0.5)), url(" +
      image +
      ") no-repeat center center",
    WebkitBackgroundSize: "cover",
    MozBackgroundSize: "cover",
    OBackgroundSize: "cover",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    paddingTop: "100px",
    paddingBottom: "100px",
  };

  // HTML AND JSX RENDER
  return (
    <div style={headerStyle}>
      <div className="blog-header-text">
        <h1 className={"display-3 text-white"}>{title}</h1>
        <h2 className={"text-white"}>By {author}</h2>
      </div>
    </div>
  );
};
export default BlogHeader;
