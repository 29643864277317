import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import axios from "axios";

// RESTful
import {resendVerificationEmail} from "../../service/auth";

// COMPONENT
import {DisplayModal} from "../../component/modal/modal";
import {PageHeader} from "../../component/common/PageHeader";

// RESEND VERIFICATION EMAIL COMPONENT
const ResendVerification = ({ email }) => {
  // HOOKS
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);

  // REDIRECT
  const navigate = useNavigate();

  // OPEN/CLOSE MODAL FUNCTION
  const onModalOpen = () => {
    setShow(true);
  };

  const onModalClose = () => {
    setShow(false);
    if (success) navigate("/login");
  };

  // REQUEST ACCOUNT VERIFICATION LINK THROUGH API CALL
  const sendVerificationEmail = async () => {
    await axios
      .post(resendVerificationEmail(), { email })
      .then((data) => {
        switch (data.data.success) {
          case true:
            setMessage(data.data.message);
            setSuccess(true);
            onModalOpen();
            break;
          case false:
            setSuccess(false);
            break;
          default:
            break;
        }
      })
      .catch(() => {
        setMessage(
          "Verification email could not be sent. Please try again later."
        );
        setSuccess(false);
        onModalOpen();
      });
  };

  // HTML AND JSX RENDER
  return (
    <div className={"send-verification-section"}>
      <PageHeader title={"Send Verification - Stepout"} />

      <div className="center-button">
        <button
          className={"btn btn-sm btn-outline-primary"}
          onClick={sendVerificationEmail}
        >
          Click here to send verification link
        </button>
      </div>

      <DisplayModal
        show={show}
        message={message}
        close={onModalClose}
        title={"Account verification"}
      />
    </div>
  );
};

export default ResendVerification;
