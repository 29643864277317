require("dotenv").config();

// MEMBERS
export function getVerifiedMembers() {
  return process.env.REACT_APP_API_URL + "/analytics/count_verified_members";
}

export function getNonVerifiedMembers() {
  return process.env.REACT_APP_API_URL + "/analytics/count_nonverified_members";
}

// ACTIVITIES
export function getActiveActivityCount() {
  return process.env.REACT_APP_API_URL + "/analytics/count_active_activities";
}

export function getInactiveActivityCount() {
  return process.env.REACT_APP_API_URL + "/analytics/count_inactive_activities";
}

// BLOGS
export function getActiveBlogCount() {
  return process.env.REACT_APP_API_URL + "/analytics/count_active_blogs";
}

export function getInactiveBlogCount() {
  return process.env.REACT_APP_API_URL + "/analytics/count_inactive_blogs";
}

// EVENTS
export function getActiveEventsCount() {
  return process.env.REACT_APP_API_URL + "/analytics/count_active_events";
}

export function getInactiveEventsCount() {
  return process.env.REACT_APP_API_URL + "/analytics/count_inactive_events";
}

// OCCUPATION
export function getOccupationCount() {
  return process.env.REACT_APP_API_URL + "/analytics/count_occupations";
}

// RESIDENTIAL AREA
export function getResidentialAreaCount() {
  return process.env.REACT_APP_API_URL + "/analytics/count_residential_areas";
}
