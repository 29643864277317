import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router";
import {useNavigate, useSearchParams} from "react-router-dom";
import Axios from "axios";

// IMAGE URL
import activityImg from "../../../assets/img/rubik.png";
import {adminActivityImageUrl, adminGalleryImageUrl,} from "../../../config/config.json";

// FONTAWESOME
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import authHeader from "../../../service/auth-header";

// RESTful
import {
  activityStatus,
  addActivity,
  getActivity,
  getActivityDetail,
  getActivityDetailsByID,
  updateActivity,
  updateActivityImage,
} from "../../../service/activity";
import {
  changeActivityGalleryImage,
  getActivityGalleryImagesByID,
  updateActivityGalleryImages,
  uploadActivityGalleryImages,
} from "../../../service/gallery";

// TOKEN
import {getCurrentUser} from "../../../service/auth";

// CONTROLS
import {Input} from "../../../component/common/input";
import {DisplayModal} from "../../../component/modal/modal";
import Paginate from "../../../component/pagination/paginate";

// WYSIWYG COMPONENTS
import {convertToRaw, EditorState} from "draft-js";
import draftToHtml from "draftjs-to-html";
import {Editor} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import RichText from "../../../component/common/TextEditor";

// STATUS CHANGE FUNCTION
import ChangeStatus from "../../../utils/StatusToggle";

// ACTIVITY LANDING SECTION
const Act = () => {
  return (
    <div className="act">
      <div className="container">
        <h1 className="display-3">Activity Section</h1>
        <img src={activityImg} alt="activity.png" className={"img-fluid"} />
      </div>
    </div>
  );
};

// ADD NEW ACTIVITY
const AddActivity = () => {
  // ACTIVITY INFORMATION HOOKS
  const [activityName, setActivityName] = useState("");
  const [activityDescription, setActivityDescription] = useState("");
  const [activityImage, setActivityImage] = useState();
  const [imagePreview, setImagePreview] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [show, setShow] = useState(false);

  // REDIRECT
  const navigate = useNavigate();

  // WYSIWYG HOOKS
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [content, setContent] = useState("");

  // ACTIVITY GALLERY HOOKS
  const [activityGalleryImage, setActivityGalleryImage] = useState([]);
  const [previewGallryImages, setPreviewGalleryImages] = useState([]);

  // GETTING ADMIN ID THROUGH TOKEN
  const user = getCurrentUser();
  const adminID = user.id;

  // USE REF
  const inputImageFile = useRef(null);
  const galleryInputImageFiles = useRef(null);

  function handleImageRef() {
    inputImageFile.current.click();
  }

  function handleActivityGalleryImageRef() {
    galleryInputImageFiles.current.click();
  }

  // OPEN AND CLOSE MODAL FUNCTIONS
  function onModalOpen() {
    setShow(true);
  }

  function onModalClose() {
    setShow(false);
    if (success) navigate("/dashboard/activity/view_activity");
  }

  // ACTIVITY IMAGE HANDLER
  function activityImageHandler(e) {
    setActivityImage(e.target.files[0]);
    activityImagePreviewHandler(e);
  }

  // ACTIVITY GALLERY IMAGE HANDLER
  function handleActivityGalleryImages(e) {
    setActivityGalleryImage([...activityGalleryImage, ...e.target.files]);
  }

  // ACTIVITY IMAGE PREVIEW FUNCTION
  function activityImagePreviewHandler(e) {
    setImagePreview(URL.createObjectURL(e.target.files[0]));
  }

  // UPLOAD ACTIVITY GALLERY IMAGE
  async function uploadGalleryImages(e) {
    e.preventDefault();

    if (activityGalleryImage.length > 0) {
      let formData = new FormData();

      // FORM DATA APPEND
      for (let i = 0; i < activityGalleryImage.length; i++) {
        formData.append("activity-gallery-img", activityGalleryImage[i]);
      }

      await Axios.post(
        uploadActivityGalleryImages(activityName.replaceAll(/\s/g, "+")),
        formData,
        { headers: authHeader.contentHeader() }
      )
        .then((x) => {
          if (!x.data.success) {
            setMessage(x.data.message);

            onModalOpen();
          }
        })
        .catch((error) => {
          setMessage(`Gallery images could not be uploaded. ${error.message}.`);

          onModalOpen();
        });
    }
  }

  // UPLOAD ACTIVITY INFORMATION
  async function uploadActivity(e) {
    e.preventDefault();

    let formData = new FormData();

    setLoading(true);

    formData.append("adminID", adminID);
    formData.append("activityName", activityName);
    formData.append("activityDescription", activityDescription);
    formData.append("activity-image", activityImage);

    await Axios.post(addActivity(), formData, {
      headers: authHeader.multiHeader(),
    })
      .then((activityUpload) => {
        setLoading(false);
        if (activityUpload.data.success) {
          setMessage(activityUpload.data.message);

          setSuccess(true);

          onModalOpen();
          uploadGalleryImages(e);
        } else {
          setMessage(activityUpload.data.message);
          setSuccess(false);

          onModalOpen();
        }
      })
      .catch((error) => {
        setLoading(false);
        setSuccess(false);
        setMessage(`New activity could not be uploaded. ${error.message}.`);

        onModalOpen();
      });
  }

  // FUNCTION USED TO DISABLE IMAGE INPUT WHEN MORE THAN 3 IMAGES ARE UPLOADED
  function imageCapacity() {
    return activityGalleryImage.length > 2;
  }

  // USE EFFECT
  useEffect(() => {
    if (activityGalleryImage.length < 1) return;

    const newImageUrls = [];

    activityGalleryImage.forEach((image) =>
      newImageUrls.push(URL.createObjectURL(image))
    );

    setPreviewGalleryImages(newImageUrls);
  }, [activityGalleryImage]);

  // HTML AND JSX RENDER
  return (
    <div className={"add-activity"}>
      <div className="container">
        <h1>Add Activity</h1>

        <div className="card shadow-sm border-primary">
          <div className="card-body">
            <form method="post" encType="multipart/form-data">
              <div className="row">
                <div className="col-md-6">
                  <Input
                    label={"Enter activity name"}
                    type={"text"}
                    name={"activityName"}
                    placeholder={
                      "Enter the name of the activity being uploaded"
                    }
                    onChange={(e) => setActivityName(e.target.value)}
                  />

                  <Editor
                    editorState={editorState}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    spellCheck
                    toolbar={{
                      inline: { inDropdown: true },
                      list: { inDropdown: true },
                      textAlign: { inDropdown: true },
                      link: { inDropdown: true },
                      history: { inDropdown: true },
                    }}
                    onEditorStateChange={(newState) => {
                      setEditorState(newState);
                      setContent(
                        draftToHtml(convertToRaw(newState.getCurrentContent()))
                      );
                      setActivityDescription(content);
                    }}
                  />
                </div>

                <div className="col-md-6 mb-4">
                  <p className={"text-muted text-center"}>
                    *Image must not be larger than 2MB
                  </p>

                  {/* BUTTONS */}
                  <button
                    className="btn btn-primary shadow-sm"
                    type="button"
                    disabled={activityImage}
                    onClick={handleImageRef}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </button>

                  {/* HIDDEN INPUT */}
                  <input
                    id="activity-image"
                    name="activity-image"
                    type={"file"}
                    accept="image/*"
                    multiple="false"
                    ref={inputImageFile}
                    onChange={activityImageHandler}
                    style={{ display: "none" }}
                  />

                  {imagePreview.length > 0 ? (
                    <img
                      src={imagePreview}
                      alt={imagePreview}
                      className={"img-fluid mb-2 mt-3"}
                      onChange={imagePreview}
                    />
                  ) : (
                    <p className={"image-preview-text text-muted"}>
                      Image preview
                    </p>
                  )}

                  <div className="border-bottom border-primary mt-2 mb-4" />

                  <div className="mt-5">
                    {/* BUTTONS */}
                    <button
                      className="btn btn-primary shadow-sm"
                      type="button"
                      disabled={imageCapacity()}
                      onClick={handleActivityGalleryImageRef}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </button>

                    {/* HIDDEN INPUT */}
                    <input
                      id="activity-gallery-img"
                      name="activity-gallery-img"
                      type={"file"}
                      accept="image/*"
                      multiple="true"
                      ref={galleryInputImageFiles}
                      onChange={handleActivityGalleryImages}
                      style={{ display: "none" }}
                      disabled={imageCapacity()}
                    />

                    <div className="row row-cols-1 row-cols-md-1 rows-col-lg-2 row-cols-xl-2 g-4 mt-2">
                      {previewGallryImages.map((x, index) => {
                        return (
                          <div key={index}>
                            <img src={x} alt={x} className={"img-fluid"} />
                          </div>
                        );
                      })}
                    </div>

                    {previewGallryImages.length === 0 && (
                      <p className="text-center text-muted">
                        Image gallery preview
                      </p>
                    )}
                  </div>
                </div>
              </div>

              {loading ? (
                <button className="btn btn-primary shadow-sm btn-lg" disabled>
                  <span
                    className="spinner-grow spinner-grow-sm me-2"
                    role="status"
                    aria-hidden="true"
                  />
                  Adding activity...
                </button>
              ) : (
                <button
                  className="btn btn-primary shadow-sm"
                  onClick={(e) => uploadActivity(e)}
                >
                  Add Activity
                </button>
              )}
            </form>
          </div>
        </div>
      </div>

      {/* MODAL */}
      <DisplayModal
        show={show}
        message={message}
        title={"Activity"}
        close={onModalClose}
      />
    </div>
  );
};

// OBTAIN UPLOADED ACTIVITIES
const ViewActivity = () => {
  // HOOKS
  const [activityData, setActivityData] = useState([]);
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  // TABLE COLUMNS
  const columnLabel = [{ label: "Name" }, { label: "Status" }];

  // NEW PAGINATION IMPLEMENTATION
  const [activePage, setActivePage] = useState(1);
  const rowsPerPage = 5;
  const [count, setCount] = useState(0);
  const totalPages = Math.ceil(count / rowsPerPage);
  const calculatedRows = activityData.slice(
    (activePage - 1) * rowsPerPage,
    activePage * rowsPerPage
  );

  useEffect(() => {
    // RETRIEVE ACTIVITY INFORMATION THROUGH API CALL
    async function activity() {
      setLoading(true);

      await Axios.get(getActivity())
        .then((data) => {
          if (data.data.success) {
            setActivityData(data.data.result);
            setCount(data.data.result.length);
          } else {
            setMessage(
              `Activities could not be displayed. ${data.data.message}.`
            );
            setShow(true);
          }
        })
        .catch((error) => {
          setMessage(`Activities could not be displayed. ${error.message}`);
          setShow(true);
        });
    }

    activity().finally(() => {
      setLoading(false);
    });
  }, [refresh]);

  // CLOSE MODAL FUNCTION
  function onModalClose() {
    setShow(false);
  }

  // HTML AND JSX RENDER
  return (
    <div className="container">
      <div className="view-activity">
        <h1>List of Stepout Activities</h1>

        <div className="card shadow-sm border-primary">
          <div className="card-body">
            <table className="table table-hover">
              <thead>
                <tr>
                  {columnLabel.map((item, index) => {
                    return <th key={index}>{item.label}</th>;
                  })}
                </tr>
              </thead>

              <tbody>
                {loading && (
                  <tr>
                    <td colSpan={5} className={"text-center"}>
                      <h3 className={"text-muted"}>Loading activities...</h3>

                      <div className="admin-spinner">
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}

                {!activityData.length && !loading && (
                  <tr>
                    <td colSpan={5} className={"text-center"}>
                      <p className="text-muted">
                        There are no activities to view.
                      </p>
                    </td>
                  </tr>
                )}

                {!loading &&
                  calculatedRows.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.activityName}</td>
                        <td>{item.status}</td>
                        <td>
                          <div className="btn-group">
                            <a
                              href={getActivityDetailsByID(item.activityID)}
                              className={"btn btn-sm btn-primary"}
                            >
                              Edit activity details
                            </a>

                            <a
                              href={changeActivityGalleryImage(
                                item.activityID,
                                item.activityName.replaceAll(/\s/g, "+")
                              )}
                              className={"btn btn-sm btn-outline-primary"}
                            >
                              Change activity gallery images
                            </a>

                            <button
                              onClick={() =>
                                ChangeStatus(
                                  item.activityID,
                                  item.activityName,
                                  item.status,
                                  activityStatus(),
                                  setMessage,
                                  setRefresh,
                                  setShow
                                )
                              }
                              className="btn btn-sm btn-outline-primary"
                            >
                              Change {item.activityName} status
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>

            {/*NEW PAGINATION*/}
            <Paginate
              activePage={activePage}
              count={count}
              rowsPerPage={rowsPerPage}
              totalPages={totalPages}
              setActivePage={setActivePage}
            />
          </div>
        </div>
      </div>

      {/* MODAL */}
      <DisplayModal
        show={show}
        message={message}
        title={"Activity"}
        close={onModalClose}
      />
    </div>
  );
};

// EDIT SELECTED ACTIVITY COMPONENT
const EditActivity = () => {
  // HOOKS
  const [activity, setActivity] = useState([]);
  const [activityName, setActivityName] = useState("");
  const [activityDescription, setActivityDescription] = useState("");
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  // USE REF HOOK AND FUNCTION
  const activityImageFile = useRef(null);

  // NAVIGATE HOOK
  const navigate = useNavigate();

  function activityImageHandlerRef() {
    activityImageFile.current.click();
  }

  // ACTIVITY ID
  const { id } = useParams();

  // USE EFFECT
  useEffect(() => {
    // RETRIEVE SELECTED ACTIVITY INFORMATION THROUGH API CALL
    async function activityDetails() {
      setLoading(true);

      await Axios.post(getActivityDetail(), { id: id })
        .then((data) => {
          if (data.data.success) {
            setActivity(data.data.result);
          } else {
            setMessage(data.data.message);
            onModalOpen();
          }
        })
        .catch((error) => {
          setMessage(
            `Activity information could not be displayed. ${error.message}.`
          );
          onModalOpen();
        });
    }

    activityDetails().finally(() => {
      setLoading(false);
    });
  }, [id]);

  // OPEN AND CLOSE MODAL FUNCTION
  function onModalOpen() {
    setShow(true);
  }

  function onModalClose() {
    setShow(false);
    if (success) navigate("/dashboard/activity/view_activity");
  }

  // UPDATE ACTIVITY IMAGE HANDLER
  const updateActivityImageHandler = async (e) => {
    e.preventDefault();

    const image = e.target.files[0];
    const formData = new FormData();

    formData.append("activity-image", image);
    formData.append("id", id);

    await Axios.put(updateActivityImage(), formData, {
      headers: authHeader.adminMultiHeader(),
    })
      .then((data) => {
        if (data.data.update) {
          setMessage(data.data.message);
          setSuccess(true);
          onModalOpen();
        } else {
          e.target.value = "";
          setSuccess(false);
          setMessage(data.data.message);
          onModalOpen();
        }
      })
      .catch((error) => {
        setSuccess(false);
        setMessage(`Activity image could not be updated. ${error.message}.`);
        onModalOpen();
      });
  };

  // UPDATE ACTIVITY INFORMATION
  async function updateActivityDetails(e) {
    e.preventDefault();

    try {
      await activity.forEach((item) => {
        if (item) {
          Axios.put(
            updateActivity(),
            {
              id: id,
              title: activityName === "" ? item.activityName : activityName,
              description:
                activityDescription === ""
                  ? item.activityDescription
                  : activityDescription,
            },
            { headers: authHeader.authHeader() }
          )
            .then((data) => {
              if (data.data.updated) {
                setMessage(data.data.message);
                setSuccess(true);
                onModalOpen();
              } else {
                setSuccess(false);
                setMessage(data.data.message);
                onModalOpen();
              }
            })
            .catch((error) => {
              setSuccess(false);
              setMessage(
                `An error has occurred while attempting to update activity details. ${error.message}.`
              );
              onModalOpen();
            });
        }
      });
    } catch (error) {
      setMessage(`Activity details could not be updated. ${error.message}.`);

      onModalOpen();
    }
  }

  // HTML AND JSX RENDER
  return (
    <div className={"container edit-activity"}>
      {loading && (
        <div>
          <p className={"text-muted center"}>Loading activity details...</p>

          <div className="spinner">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      )}

      {!activity.length && !loading && (
        <p className={"text-muted center"}>
          Activity details could not be loaded.
        </p>
      )}

      {!loading &&
        activity.map((item) => {
          return (
            <form key={item.activityID} encType="multipart/form-data">
              <div className="card-group">
                <div className="card shadow-sm border-primary">
                  <div className="card-body">
                    <h1>Details for {item.activityName}</h1>

                    <Input
                      label={"Activity Name"}
                      type={"text"}
                      name={"activityName"}
                      onChange={(e) => setActivityName(e.target.value)}
                      defaultValue={item.activityName}
                    />

                    <RichText
                      context={item.activityDescription}
                      setContextValue={setActivityDescription}
                    />

                    <button
                      type={"button"}
                      className="btn btn-primary shadow-sm mt-3 mb-3"
                      onClick={updateActivityDetails}
                    >
                      Update {item.activityName} Details
                    </button>
                  </div>
                </div>

                <div className="card shadow-sm border-primary">
                  <div className="card-body">
                    <h1>Update Activity Image</h1>

                    <img
                      src={adminActivityImageUrl + item.activityImage}
                      alt={item.activityImage}
                      className="img-fluid"
                    />

                    <div className="button-container">
                      <button
                        className="btn btn-primary shadow-sm"
                        type="button"
                        onClick={activityImageHandlerRef}
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </button>
                    </div>

                    <input
                      type={"file"}
                      name={"activity-image"}
                      ref={activityImageFile}
                      style={{ display: "none" }}
                      onChange={(e) => updateActivityImageHandler(e)}
                    />

                    <p className={"text-muted text-center"}>
                      *Image must not be larger than 2MB
                    </p>
                  </div>
                </div>
              </div>
            </form>
          );
        })}

      {/* MODAL */}
      <DisplayModal
        show={show}
        message={message}
        close={onModalClose}
        title={"Activity"}
      />
    </div>
  );
};

// CHANGE ACTIVITY GALLERY IMAGES COMPONENT
const ActivityGallery = () => {
  // HOOKS
  const [message, setMessage] = useState("");
  const [gallery, setGallery] = useState([]);
  const [show, setShow] = useState(false);
  const [files, setFiles] = useState([]);
  const [imagesURL, setImagesURL] = useState([]);
  const [activityGalleryID, setActivityGalleryID] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [success, setSuccess] = useState(false);

  // USE REF HOOKS
  const inputImageFile = useRef(null);
  const updateEventGalleryImage = useRef(null);

  // NAVIGATE HOOK
  const navigate = useNavigate();

  // ACTIVITY ID AND NAME
  const searchID = searchParams.get("id") || "";
  const searchName = searchParams.get("name") || "";

  // OPEN AND CLOSE MODAL FUNCTION
  function onModalOpen() {
    setShow(true);
  }

  function onModalClose() {
    setShow(false);
    if (success) navigate("/dashboard/activity/view_activity");
  }

  function handleActivityGallery() {
    inputImageFile.current.click();
  }

  function handleActivityGalleryImageUpdate(id) {
    updateEventGalleryImage.current.click();
    setActivityGalleryID(id);
  }

  // HANDLE SELECTED IMAGES
  function handleImages(e) {
    setFiles([...files, ...e.target.files]);
  }

  // UPLOAD GALLERY IMAGES
  async function insertGalleryImages(e) {
    e.preventDefault();

    if (files.length > 0) {
      let formData = new FormData();

      // FORM DATA APPEND
      formData.append("activityName", searchName);

      for (let i = 0; i < files.length; ++i)
        formData.append("activity-gallery-img", files[i]);

      await Axios.post(uploadActivityGalleryImages(searchName), formData, {
        headers: authHeader.adminMultiHeader(),
      })
        .then((x) => {
          if (!x.data.success) {
            setMessage(x.data.message);

            onModalOpen();
          } else {
            setMessage(x.data.message);
            setSuccess(true);

            onModalOpen();
          }
        })
        .catch((error) => {
          setMessage(`Gallery image could not be uploaded. ${error.message}.`);
          onModalOpen();
        });
    }
  }

  // UPDATE GALLERY IMAGES THROUGH API CALL
  async function updateGalleryImages(e) {
    e.preventDefault();

    const formData = new FormData();
    const image = e.target.files[0];

    formData.append("id", searchID);
    formData.append("galleryID", activityGalleryID);
    formData.append("activity-gallery-img", image);

    await Axios.put(updateActivityGalleryImages(), formData, {
      headers: authHeader.adminMultiHeader(),
    })
      .then((data) => {
        if (data.data.success) {
          setMessage(data.data.message);
          setSuccess(true);
          onModalOpen();
        } else {
          setMessage(data.data.message);
          setSuccess(false);
          onModalOpen();
        }
      })
      .catch((error) => {
        setMessage(
          `An error has occurred while attempting to update gallery images. ${error.message}.`
        );
        onModalOpen();
      });
  }

  // FUNCTION USED TO DISABLE IMAGE INPUT WHEN MORE THAN 3 IMAGES ARE UPLOADED
  function imageCapacity() {
    const sum = gallery.length + files.length;
    if (sum > 3) return true;
    return files.length > 3;
  }

  // DISABLE UPLOAD BUTTON IF THERE ARE NO IMAGES
  function disableUploadButton() {
    return files.length === 0;
  }

  // USE EFFECT
  useEffect(() => {
    //RETRIEVE GALLERY IMAGES OF SELECTED ACTIVITY THROUGH API CALL
    async function getGalleryImages() {
      await Axios.post(getActivityGalleryImagesByID(), { id: searchID })
        .then((data) => {
          if (data.data.success) {
            setGallery(data.data.result);
          }
        })
        .catch((error) => {
          setMessage(
            `An error has occurred while attempting to load activity gallery images. ${error.message}.`
          );
          onModalOpen();
        });
    }

    getGalleryImages();

    if (files.length < 1) return;

    const newImageUrls = [];

    files.forEach((image) => newImageUrls.push(URL.createObjectURL(image)));

    setImagesURL(newImageUrls);
  }, [files, searchID]);

  // HTML AND JSX RENDER
  return (
    <div className="container mb-3">
      <h2 className="text-center mb-4">Currently used activity gallery</h2>

      <div className="row row-cols-1 row-cols-md-2 rows-col-lg-3 row-cols-xl-3 g-4">
        {gallery.length > 0 ? (
          gallery.map((x, index) => {
            return (
              <div key={index}>
                <div className="col">
                  <div className="gallery-wrap">
                    <img
                      src={adminGalleryImageUrl + x.image}
                      alt={x.image}
                      className={"img-fluid"}
                    />

                    <div className="gallery-info">
                      <button
                        className="btn btn-primary btn-sm shadow-sm"
                        onClick={() =>
                          handleActivityGalleryImageUpdate(x.galleryActivityID)
                        }
                      >
                        Update image
                      </button>

                      {/* HIDDEN INPUT */}
                      <form encType="multipart/form-data">
                        <input
                          type="file"
                          name="activity-gallery-img"
                          id="activity-gallery-img"
                          ref={updateEventGalleryImage}
                          onChange={(e) => updateGalleryImages(e)}
                          accept={"image/*"}
                          multiple={false}
                          style={{ display: "none" }}
                        />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="col">
            <p className="text-center text-muted">
              This activity does not have any gallery images
            </p>
          </div>
        )}
      </div>

      <div className="border-bottom border-primary mt-3" />

      <div className="mt-4">
        <div className="text-center">
          {/* BUTTONS */}
          <button
            className="btn btn-primary shadow-sm me-3"
            onClick={() => handleActivityGallery()}
            disabled={imageCapacity()}
          >
            <FontAwesomeIcon icon={faPlus} size="2x" />
          </button>

          {/* HIDDEN INPUT */}
          <input
            type="file"
            name="image"
            id="image"
            ref={inputImageFile}
            multiple={true}
            onChange={handleImages}
            style={{ display: "none" }}
            disabled={imageCapacity()}
          />

          {/* IMAGE PREVIEW */}
          <div className="row row-cols-1 row-cols-md-2 rows-col-lg-3 row-cols-xl-3 g-4 mt-2">
            {imagesURL.map((x, index) => {
              return (
                <div className="mt-3 mb-3" key={index}>
                  <div className="col">
                    <img src={x} alt={x} className={"img-fluid"} />
                  </div>
                </div>
              );
            })}
          </div>

          <div className="border-bottom border-primary mt-4 mb-3" />

          <button
            className="btn btn-primary shadow-sm"
            onClick={(e) => insertGalleryImages(e)}
            disabled={disableUploadButton()}
          >
            {"Upload activity gallery images"}
          </button>
        </div>
      </div>

      {/* MODAL */}
      <DisplayModal
        show={show}
        message={message}
        close={onModalClose}
        title={"Activity"}
      />
    </div>
  );
};

export { Act, AddActivity, ViewActivity, EditActivity, ActivityGallery };
