import React from "react";
import errorImg from "../../assets/img/error-404.png";

const NotFound = () => {
  return (
    <div className={"not-found"}>
      <div className="container">
        <img src={errorImg} className={"img-fluid"} alt="404.png" />
        <p className={"text-muted"}>Page could not be found...</p>
      </div>
    </div>
  );
};

export default NotFound;
