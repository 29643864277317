import React from "react";

const Textarea = ({
  label,
  name,
  value,
  defaultValue,
  onChange,
  placeholder,
  cols,
  rows,
}) => {
  return (
    <div className="form-group mb-3">
      <label htmlFor={name} className="form-label">
        {label}
      </label>
      <textarea
        name={name}
        id={name}
        cols={cols}
        rows={rows}
        className="form-control"
        defaultValue={defaultValue}
        onChange={onChange}
        placeholder={placeholder}
      />
    </div>
  );
};

export default Textarea;
