import React from "react";
import Modal from "react-bootstrap/Modal";
import ResendVerification from "../../views/access/ResendVerification";

const DisplayModal = ({
  show,
  title,
  message,
  subText,
  close,
  link,
  extra,
  images,
}) => {
  /*SEND VERIFICATION LINK TO CHANGED VERIFIED STATUS FOR MEMBER*/
  const resendEmailVerification = (email) => {
    return link ? <ResendVerification email={email} /> : null;
  };

  return (
    <Modal show={show} onHide={close} centered>
      <div className="p-3">
        <Modal.Header closeButton={true}>
          <div className="col-12 text-center">
            <Modal.Title>{title}</Modal.Title>
          </div>
        </Modal.Header>
      </div>
      <Modal.Body>
        <div className={"container mx-auto text-center"}>
          <p dangerouslySetInnerHTML={{ __html: message }} />
        </div>
        <div className="container mx-auto text0center">
          <p className={"text-muted"}>
            <small>{subText}</small>
          </p>
          <div className="shadow">{images}</div>
          {resendEmailVerification(extra)}
        </div>
      </Modal.Body>
    </Modal>
  );
};

const ModalOpen = () => {
  return true;
};

const ModalClose = () => {
  return false;
};
export { DisplayModal, ModalOpen, ModalClose };
