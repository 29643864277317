require("dotenv").config();

export function getRole() {
  return process.env.REACT_APP_API_URL + "/admin/admin_role";
}

export function getAdministrator() {
  return process.env.REACT_APP_API_URL + "/admin/get_admins";
}

export function getAdminDetail(id) {
  return `/dashboard/get_admin/${id}`;
}

export function getAdminInfo(id) {
  return process.env.REACT_APP_API_URL + `/admin/get_admin/${id}`;
}

export function updateRole(id) {
  return process.env.REACT_APP_API_URL + `/admin/update_role/${id}`;
}

export function updateAdminProfileImage() {
  return process.env.REACT_APP_API_URL + "/admin/update_admin_profile_image";
}

export function updateAdminDetail() {
  return process.env.REACT_APP_API_URL + "/admin/update_admin_details";
}

export function removeAdminProfileImage() {
  return process.env.REACT_APP_API_URL + "/admin/remove_admin_profile_image";
}

export function updateAdminStatus() {
  return process.env.REACT_APP_API_URL + `/admin/update_admin_status`;
}

export function updateMemberStatus() {
  return process.env.REACT_APP_API_URL + `/admin/update_member_status`;
}

export function updateMemberCommentStatus() {
  return process.env.REACT_APP_API_URL + `/admin/update_member_comment_status`;
}
