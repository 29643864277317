import React from "react";

const Paginate = ({
  activePage,
  count,
  rowsPerPage,
  totalPages,
  setActivePage,
}) => {
  const beginning = activePage === 1 ? 1 : rowsPerPage * (activePage - 1) + 1;
  const end = activePage === totalPages ? count : beginning + rowsPerPage - 1;

  return (
    <>
      <div className="pagination btn-group">
        <button
          disabled={activePage === 1}
          onClick={() => setActivePage(1)}
          className={"btn btn-sm btn-outline-primary"}
        >
          ⏮️ First
        </button>
        <button
          disabled={activePage === 1}
          onClick={() => setActivePage(activePage - 1)}
          className={"btn btn-sm btn-outline-primary"}
        >
          ⬅️ Previous
        </button>
        <button
          disabled={activePage === totalPages}
          onClick={() => setActivePage(activePage + 1)}
          className={"btn btn-sm btn-outline-primary"}
        >
          Next ➡️
        </button>
        <button
          disabled={activePage === totalPages}
          onClick={() => setActivePage(totalPages)}
          className={"btn btn-sm btn-outline-primary"}
        >
          Last ⏭️
        </button>
      </div>
      <div className="text-center">
        <p className={"text-muted mb-0 mt-3"}>
          Page {activePage} of {totalPages}
        </p>
        <p className={"text-muted"}>
          Rows: {beginning === end ? end : `${beginning} - ${end}`} of {count}
        </p>
      </div>
    </>
  );
};

export default Paginate;
