import React, { Component, Fragment } from "react";
import Axios from "axios";
import Joi from "joi";

// DATE-FNS FUNCTION
import { DateFns } from "../../utils/DateFunctions";

// TOKEN
import { getCurrentUser, getToken } from "../../service/auth";

// RESTful
import { admin_comment, allComments, comment } from "../../service/blog";

// IMAGE
import { adminProfileImgUrl } from "../../config/config.json";

// Variables
let commentCounter = 1;

// CHILD CLASS
// TEXT BOX THAT IS USED TO WRITE AND POST COMMENT
class CommentBox extends Component {
  render() {
    // PROPS
    const {
      commentValue,
      handleCommentValue,
      enterCommentLine,
      submitCommentLine,
      user,
      adminSubmitCommentLine,
      adminEnterCommentLine,
    } = this.props;

    // ENABLE SEND COMMENT BUTTON
    function enableCommentButton() {
      return !commentValue;
    }

    // ENABLE TEXT AREA
    function enableTextArea() {
      return !user;
    }

    // HTML AND JSX RENDER
    return (
      <div className="container">
        <div className="comment-box">
          <h1 className={"mb-5"}>Comments</h1>
          <textarea
            onKeyPress={
              user
                ? this.props.user.role
                  ? adminEnterCommentLine
                  : enterCommentLine
                : ""
            }
            value={commentValue}
            className={"form-control"}
            id="comments-input"
            onChange={handleCommentValue}
            rows={5}
            placeholder={
              user
                ? "Have your say..."
                : "You must be logged in to make a comment..."
            }
            disabled={enableTextArea()}
          />
          <button
            onClick={
              user && this.props.user.role
                ? adminSubmitCommentLine
                : submitCommentLine
            }
            type="submit"
            className="btn btn-lg btn-primary mt-3 shadow-sm"
            disabled={enableCommentButton()}
          >
            Post
          </button>
        </div>
      </div>
    );
  }
}

// DISPLAY ALL OLD AND NEWLY ADDED COMMENTS POST
class DisplayComment extends Component {
  render() {
    // PROPS
    const { comments } = this.props;

    const BlogCommentDisplay = (name, surname, comment, date, image) => {
      return (
        <>
          <div className="mb-3">
            {/*Display profile image if it exists*/}

            {image !== null && (
              <img
                src={adminProfileImgUrl + image}
                alt={image}
                className="img-fluid me-3"
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                }}
              />
            )}

            {/*Display person name*/}
            <strong className="text-muted">
              {name + " " + surname + " " + DateFns(date)}
            </strong>
          </div>

          {/*Display comment*/}
          <p className={"text-muted"}>{comment}</p>
        </>
      );
    };

    // HTML AND JSX RENDER
    return (
      <Fragment>
        <div className="mb-4 mt-4">
          <ul className={"comment-list"}>
            {comments.map((item, index) => {
              return item.adminRole !== null && item.status === "active" ? (
                <li key={index}>
                  <div className="row mb-3 mt-3">
                    <div className="col">
                      {BlogCommentDisplay(
                        item.adminName,
                        item.adminSurname,
                        item.commentText,
                        item.dateOfComment,
                        item.adminProfileImage
                      )}
                    </div>
                  </div>
                  {!item.commentText ? "" : <div className={"border-bottom"} />}
                </li>
              ) : (
                item.status === "active" && (
                  <li key={index}>
                    <div className="row mb-3 mt-3">
                      <div className="col">
                        {BlogCommentDisplay(
                          item.name,
                          item.surname,
                          item.commentText,
                          item.dateOfComment,
                          item.profileImage
                        )}
                      </div>
                    </div>
                    {!item.commentText ? (
                      ""
                    ) : (
                      <div className={"border-bottom"} />
                    )}
                  </li>
                )
              );
            })}
          </ul>
        </div>
      </Fragment>
    );
  }
}

// PARENT CLASS
export class Comment extends Component {
  // CLASS CONSTRUCTOR
  constructor(props) {
    super(props);

    // STATE
    this.state = {
      id: "",
      commentValue: "",
      blogID: "",
      commentLine: [
        { commentId: "", text: "", personName: "", personSurname: "" },
      ],
      comments: [],
      blocked: false,
      message:
        "You have been blocked from making comments, " +
        "for more information send an email enquiry to the administrator on the contact page.",
    };
  }

  // JOI SCHEMA
  schema = Joi.object({
    commentValue: Joi.string().min(2),
  }).options({ abortEarly: false });

  // GET USER INFORMATION THROUGH TOKEN
  user = getCurrentUser();

  // CODE TO EXECUTE ON COMPONENT LOAD
  componentDidMount() {
    const { blogID, canComment } = this.props;

    // CHECK IF THERE IS A LOGGED IN MEMBER
    if (this.user) {
      // CHECK IF THE MEMBER IS NOT AN ADMIN AND IF THE MEMBER HAS BEEN BLOCKED FROM MAKING COMMENTS
      if (!this.user.role && !canComment) this.setState({ blocked: true });
    }

    // GET ALL COMMENTS BASED ON SELECTED BLOG POST
    Axios.get(allComments(blogID))
      .then((data) => {
        if (data.data.success) {
          this.setState({ comments: data.data.result });
        }
      })
      .catch(() => {
        return null;
      });
  }

  // HANDLE INPUT VALUES CONTAINED IN INPUT CONTROL
  handleCommentValue = (e) => {
    this.setState({ commentValue: e.target.value });
  };

  // COMMENT CREATION INFORMATION BY MEMBER
  setCommentLine = () => {
    this.setState({
      commentLine: [
        ...this.state.commentLine,
        {
          commentID: commentCounter++,
          text: this.state.commentValue,
          personName: this.user.name,
          personSurname: this.user.surname,
        },
      ],
      commentValue: "",
    });
  };

  // POST BLOG COMMENT MADE BY MEMBER THROUGH API CALL
  submitCommentLine = async (e) => {
    let { id, commentValue } = this.state;
    const { blogID } = this.props;

    e.preventDefault();
    try {
      if (this.user) {
        this.setState({
          id: this.user.id,
        });
      }

      let data = {
        id: id,
        commentValue: commentValue,
        blogID: blogID,
      };

      await Axios.post(comment(), data, {
        headers: { "x-access-token": getToken() },
      })
        .then((personComment) => {
          if (personComment.data.success)
            window.alert(personComment.data.message);
          else window.alert(personComment.data.message);
        })
        .catch(() => {
          window.alert(
            "Unfortunately your comment could not be posted. Please report the issue you are " +
              "encountering by sending us an email from the Contact page."
          );
        });

      this.setCommentLine();
    } catch (e) {
      return null;
    }
  };

  // POST BLOG COMMENT MADE BY MEMBER THROUGH API CALL
  enterCommentLine = (e) => {
    let { id, commentValue } = this.state;
    const { blogID } = this.props;

    try {
      if (this.user) {
        this.setState({
          id: this.user.id,
        });
      }

      let data = {
        id: id,
        commentValue: commentValue,
        blogID: blogID,
      };

      if (e.charCode === 13) {
        Axios.post(comment(), data, {
          headers: { "x-access-token": getToken() },
        })
          .then((personComment) => {
            if (personComment.data.success)
              window.alert(personComment.data.message);
            else window.alert(personComment.data.message);
          })
          .catch(() => {
            window.alert(
              "Unfortunately your comment could not be posted. " +
                "Please report the issue you are encountering by sending us an email from the Contact page."
            );
          });
        this.setCommentLine();
      }
    } catch (e) {}
  };

  // COMMENT CREATION INFORMATION BY ADMINISTRATOR
  adminSubmitCommentLine = (e) => {
    let { id, commentValue } = this.state;
    const { blogID } = this.props;

    e.preventDefault();
    try {
      if (this.user) {
        this.setState({
          id: this.user.id,
        });
      }

      let data = {
        id: id,
        commentValue: commentValue,
        blogID: blogID,
      };

      Axios.post(admin_comment(), data, {
        headers: { "x-access-token": getToken() },
      })
        .then((adminComment) => {
          if (adminComment.data.success) {
            window.alert(adminComment.data.message);
          } else window.alert(adminComment.data.message);
        })
        .catch(() => {
          window.alert(
            "Unfortunately your comment could not be posted." +
              " Please report the issue you are encountering by sending us an email from the Contact page."
          );
        });
      this.setCommentLine();
    } catch (e) {
      return null;
    }
  };

  // POST BLOG COMMENT MADE BY ADMINISTRATOR THROUGH API CALL
  adminEnterCommentLine = (e) => {
    let { id, commentValue } = this.state;
    const { blogID } = this.props;

    try {
      if (this.user) {
        this.setState({
          id: this.user.id,
        });
      }

      let data = {
        id: id,
        commentValue: commentValue,
        blogID: blogID,
      };

      if (e.charCode === 13) {
        Axios.post(admin_comment(), data, {
          headers: { "x-access-token": getToken() },
        })
          .then((adminComment) => {
            if (adminComment.data.success)
              window.alert(adminComment.data.message);
            else window.alert(adminComment.data.message);
          })
          .catch(() => {
            window.alert(
              "Unfortunately your comment could not be posted. " +
                "Please report the issue you are encountering by sending us an email from the Contact page."
            );
          });
        this.setCommentLine();
      }
    } catch (e) {}
  };

  render() {
    // STATE DESTRUCTURE
    const { commentValue, commentLine, comments, blocked, message } =
      this.state;

    // HTML AND JSX RENDER
    return (
      <Fragment>
        <div className="container">
          {blocked ? (
            <p className={"text-center text-muted m-5"}>
              <strong>{message}</strong>
            </p>
          ) : (
            <CommentBox
              commentValue={commentValue}
              handleCommentValue={this.handleCommentValue}
              enterCommentLine={this.enterCommentLine}
              submitCommentLine={this.submitCommentLine}
              adminSubmitCommentLine={this.adminSubmitCommentLine}
              adminEnterCommentLine={this.adminEnterCommentLine}
              user={this.user}
              canComment={this.props.canComment}
            />
          )}
          <DisplayComment commentLine={commentLine} comments={comments} />
        </div>
      </Fragment>
    );
  }
}
