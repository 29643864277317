import { getToken } from "./auth";

const user = localStorage.getItem("token");

class AuthHeaders {
  authHeader() {
    if (user) return { "x-access-token": getToken() };
    else return {};
  }

  contentHeader() {
    if (user) return { "content-type": "multipart/form-data" };
    else return {};
  }

  adminMultiHeader() {
    if (user)
      return {
        "content-type": "multipart/form-data",
        "x-access-token": getToken(),
      };
    else return {};
  }

  multiHeader() {
    if (user)
      return {
        "content-type": "multipart/form-data",
        "x-access-token": getToken(),
      };
    else return {};
  }
}

export default new AuthHeaders();
