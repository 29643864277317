import React, {useState} from "react";
import axios from "axios";
import {anotherTest} from "../../service/EmailTest";
import Testing from "./ModularTest";

// .ENV
require("dotenv").config();
export default function Test() {
  const [name, setName] = useState("");
  const [show, setShow] = useState(false);
  const [text, setText] = useState("");

  function handleShow() {
    setShow(true);
  }

  function handleClose() {
    setShow(false);
  }

  function greeting() {
    return "Hello World";
  }

  async function SendTest() {
    await axios
      .post(anotherTest(), { name })
      .then((x) => {
        if (!x.data.success) alert(x.data.message);
        else alert(x.data.name);
      })
      .catch((e) => {
        alert(`Error message: ${e.message}`);
      });
  }

  return (
    <>
      <div className="container">
        <h1>Testing Base</h1>
        <p>{text}</p>
        <button
          className="btn btn-primary"
          onClick={() => Testing(setText, greeting())}
        >
          <small>Send</small>
        </button>
        {/* <ConfirmModal show={show} close={handleClose} />
        <input
          type="text"
          className="form-control"
          onChange={(e) => setName(e.target.value)}
        />
        <button className="btn btn-primary" onClick={handleShow}>
          Send name
        </button>*/}

        <div className="email-form">
          <div className="row gx-lg-0 gy-4">
            <div className="col-lg-4">
              <div className="info-container">
                <div className="info-item d-flex">
                  <i className="bi bi-envelope" />
                  <div>
                    <h4>Email:</h4>
                    <p>{process.env.REACT_APP_STEPOUT_EMAIL}</p>
                  </div>
                </div>
                <div className="info-item d-flex">
                  <i className="bi bi-phone" />
                  <div>
                    <h4>Call/WhatsApp Contact:</h4>
                    <p>{process.env.REACT_APP_STEPOUT_CONTACT}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-8">
              <form action="" className="contact-form">
                <div className="row">
                  <div className="col-md-6 form-group">
                    <input type="text" className="form-control" />
                  </div>

                  <div className="col-md-6 form-group mt-3 mt-md-0">
                    <input type="text" className="form-control" />
                  </div>
                </div>

                <div className="form-group mt-3">
                  <input type="text" className="form-control" />
                </div>

                <div className="form-group mt-3">
                  <textarea
                    name=""
                    id=""
                    rows="7"
                    className="form-control"
                  ></textarea>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
