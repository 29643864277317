require("dotenv").config();

export function getResidentialArea() {
  return process.env.REACT_APP_API_URL + "/api/get_residential_area";
}

export function insertResidentialArea() {
  return process.env.REACT_APP_API_URL + "/api/add_residential_area";
}

export function editResidentialArea(id) {
  return `/dashboard/residential_area/edit_residential_area/${id}`;
}

export function getResidentialAreaByID(id) {
  return process.env.REACT_APP_API_URL + `/api/area_details/${id}`;
}

export function _updateResidentialArea() {
  return process.env.REACT_APP_API_URL + "/api/update_residential_area";
}

export function getMembersByResidentialArea() {
  return process.env.REACT_APP_API_URL + "/member/get_members_by_area";
}
