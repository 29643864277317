import React, {useEffect, useRef, useState} from "react";
import Axios from "axios";

// RESTful
import {getAbout, insertAbout, updateAbout, updateAboutImage,} from "../../../service/about";

import authHeader from "../../../service/auth-header";

// IMAGE URL
import aboutImg from "../../../assets/img/about.png";
import {adminAboutImageUrl} from "../../../config/config.json";

// CONTROLS
import {Input} from "../../../component/common/input";
import Textarea from "../../../component/common/TextArea";
import {DisplayModal} from "../../../component/modal/modal";

// FONTAWESOME
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import RichText from "../../../component/common/TextEditor";

// ADMIN ABOUT
export const AdminAbout = () => {
  // HTML AND JSX RENDER
  return (
    <div id={"admin-about"}>
      <div className="container">
        <h1 className="display-3">About section</h1>
        <img src={aboutImg} alt={aboutImg} className={"img-fluid"} />
      </div>
    </div>
  );
};

// ADD ABOUT SECTION
export const AddAbout = () => {
  // HOOKS
  const [aboutTitle, setAboutTitle] = useState("");
  const [aboutDescription, setAboutDescription] = useState("");
  const [image, setImage] = useState("");

  // ABOUT IMAGE HANDLER
  const aboutImageHandler = (e) => {
    setImage(e.target.files[0]);
  };

  // ADD ABOUT INFORMATION THROUGH API CALL
  const _addAbout = async (e) => {
    try {
      e.preventDefault();
      let formData = new FormData();
      formData.append("aboutTitle", aboutTitle);
      formData.append("aboutDescription", aboutDescription);
      formData.append("about-image", image);

      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      await Axios.post(insertAbout(), formData, config).then((about) => {
        if (about.data.success) window.alert(about.data.message);
        else window.alert(about.data.message);
      });
    } catch (e) {
      return null;
    }
  };

  // HTML AND JSX RENDER
  return (
    <div id={"add-about"}>
      <div className="container">
        <h1>Add About</h1>
        <div className="card shadow-sm border-primary">
          <div className="card-body">
            <form method={"post"} encType={"multipart/form-data"}>
              <div className="row">
                <div className="col-md-6">
                  <Input
                    label={"Enter title"}
                    type={"text"}
                    name={"aboutTitle"}
                    placeholder={"About title"}
                    onChange={(e) => setAboutTitle(e.target.value)}
                  />

                  <Textarea
                    label={"Enter description"}
                    name={"aboutDescription"}
                    placeholder={"Enter description about the business"}
                    cols={"30"}
                    rows={"10"}
                    onChange={(e) => setAboutDescription(e.target.value)}
                  />
                </div>

                <div className="col-md-6">
                  <Input
                    label={"Upload Image"}
                    name={"about-image"}
                    type={"file"}
                    accept={"image/*"}
                    multiple={false}
                    onChange={aboutImageHandler}
                  />
                </div>
              </div>

              <button className="btn btn-primary" onClick={_addAbout}>
                Add About
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

// EDIT ABOUT SECTION
export const SetAbout = () => {
  //  HOOKS
  const [about, setAbout] = useState([]);
  const [aboutName, setAboutName] = useState("");
  const [aboutDescription, setAboutDescription] = useState("");
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  // USE REF HOOK
  const aboutImageFile = useRef(null);

  function aboutImageHandlerRef() {
    aboutImageFile.current.click();
  }

  // RETRIEVE ABOUT INFORMATION THROUGH API CALL
  async function _getAbout() {
    setLoading(true);

    await Axios.get(getAbout())
      .then((data) => {
        if (data.data.success) {
          setLoading(false);
          setAbout(data.data.result);
        } else setLoading(false);
      })
      .catch((error) => {
        setMessage(
          `An error has occurred while attempting to display about details. ${error.message}.`
        );
        setLoading(false);

        onModalOpen();
      });
  }

  // USE EFFECT
  useEffect(() => {
    _getAbout();
  }, []);

  // OPEN AND CLOSE MODAL FUNCTION
  function onModalOpen() {
    setShow(true);
  }

  function onModalClose() {
    setShow(false);
  }

  // UPDATE ABOUT INFORMATION
  async function aboutData(e) {
    e.preventDefault();
    await about.forEach((item) => {
      if (item) {
        Axios.put(
          updateAbout(),
          {
            aboutName: aboutName === "" ? item.aboutName : aboutName,
            aboutDescription:
              aboutDescription === ""
                ? item.aboutDescription
                : aboutDescription,
          },
          { headers: authHeader.authHeader() }
        )
          .then((data) => {
            if (data.data.success) {
              setMessage(data.data.message);
              onModalOpen();
            } else {
              setMessage(data.data.message);
              onModalOpen();
            }
          })
          .catch((res) => {
            setMessage(`About details could not be updated.`);
            console.log(res);
            onModalOpen();
          });
      }
    });
  }

  // ABOUT IMAGE HANDLER
  async function aboutImageHandler(e) {
    e.preventDefault();

    const image = e.target.files[0];
    const formData = new FormData();

    formData.append("about-image", image);

    await Axios.put(updateAboutImage(), formData, {
      headers: authHeader.contentHeader(),
    })
      .then((data) => {
        e.preventDefault();
        if (data.data.update) {
          setMessage(data.data.message);
          onModalOpen();
        } else {
          e.target.value = "";
          setMessage(data.data.message);
          onModalOpen();
        }
      })
      .catch((error) => {
        e.target.value = "";
        setMessage(`About image could not be updated. ${error.message}`);
        onModalOpen();
      });
  }

  // HTML AND JSX RENDER
  return (
    <div id="set-about" className={"container"}>
      <div className={"about-header"}>
        <h1>About Section</h1>
        <h2 className={"text-muted"}>
          Set what gets to be displayed on the about page of the website
        </h2>
      </div>

      {loading && (
        <div>
          <p className={"text-muted"}>Loading about details...</p>
          <div className="spinner">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      )}

      {!about.length && !loading && (
        <p className={"text-muted"}>About details could not be displayed.</p>
      )}

      {!loading &&
        about.map((item, index) => {
          return (
            <form encType="multipart/form-data" method="post" key={index}>
              <div className="card-group">
                <div className="card shadow-sm border-primary">
                  <div className="card-body">
                    <h2>Change the About image</h2>

                    <div className="about-context mb-3">
                      <p>Current about page image</p>
                      <img
                        src={adminAboutImageUrl + item.aboutImage}
                        alt={item.aboutImage}
                        className="img-fluid"
                      />
                    </div>

                    <button
                      className="btn btn-primary shadow-sm mb-3"
                      onClick={aboutImageHandlerRef}
                      type="button"
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </button>

                    <input
                      type={"file"}
                      name={"about-image"}
                      accept={"image/*"}
                      ref={aboutImageFile}
                      multiple={false}
                      onChange={(e) => aboutImageHandler(e)}
                      style={{ display: "none" }}
                    />

                    <p className={"text-muted text-center"}>
                      *Image must not be larger than 2MB
                    </p>
                  </div>
                </div>

                <div className="card shadow-sm border-primary">
                  <div className="card-body">
                    <h3>Edit about page details</h3>
                    <Input
                      label={"Enter/Edit about display name"}
                      type={"text"}
                      name={"about-name"}
                      defaultValue={item.aboutName}
                      onChange={(e) => setAboutName(e.target.value)}
                      placeholder={
                        "e.g About, What's Stepout's story, What's the big deal with Stepout..."
                      }
                    />

                    <RichText
                      context={item.aboutDescription}
                      setContextValue={setAboutDescription}
                    />

                    <div className="text-center mb-3 mt-4">
                      <button
                        className="btn btn-primary shadow-sm"
                        onClick={(e) => aboutData(e)}
                      >
                        Update About Details
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          );
        })}

      {/* MODAL */}
      <DisplayModal
        show={show}
        message={message}
        close={onModalClose}
        title={"About"}
      />
    </div>
  );
};
