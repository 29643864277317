import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import { getCurrentUser, logout } from "../../service/auth";

// IMAGE URL
import { adminProfileImgUrl } from "../../config/config.json";

// ADMIN INFO
import { getAdminInfo } from "../../service/admin";

// MEMBER INFO
import { getMember } from "../../service/member";

import authHeader from "../../service/auth-header";

// .ENV
require("dotenv").config();

// NAVIGATION BAR LINK ITEMS
const NavItems = [
  {
    title: "Home",
    url: "/",
    class: "nav-link",
  },
  {
    title: "About",
    url: "/about",
    class: "nav-link",
  },
  {
    title: "Activities",
    url: "/activity",
    class: "nav-link",
  },
  {
    title: "Contact",
    url: "/contact",
    class: "nav-link",
  },
  {
    title: "Blog",
    url: "/blog",
    class: "nav-link",
  },
  {
    title: "Upcoming Event",
    url: "/events",
    class: "nav-link",
  },
];

// HEADER CLASS COMPONENT
class Header extends Component {
  state = {
    image: "",
    name: "",
    currentUser: undefined,
    role: undefined,
    status: undefined,
    loading: false,
  };

  componentDidMount() {
    try {
      const user = getCurrentUser();
      const info = user.role;
      const status = user.status;

      if (user && info) {
        this.admin(user.id).finally(() => {
          this.setState({
            loading: false,
            currentUser: user,
            role: info,
            status: status,
          });
        });
      }

      if (user && !info) {
        this.member(user.id).finally(() => {
          this.setState({
            loading: false,
            currentUser: user,
            status: status,
          });
        });
      }
    } catch (e) {
      return null;
    }
  }

  async member(id) {
    this.setState({ loading: true });
    //await new Promise((resolve) => setTimeout(resolve, 3000));

    await axios
      .post(getMember(), { id })
      .then((x) => {
        if (x.data.success) {
          this.setState({
            name: x.data.result[0].name,
            image: x.data.result[0].profileImage,
          });
        }
      })
      .catch(() => {
        return {};
      });
  }

  async admin(id) {
    this.setState({ loading: true });
    //await new Promise((resolve) => setTimeout(resolve, 3000));

    await axios
      .get(getAdminInfo(id), { headers: authHeader.authHeader() })
      .then((x) => {
        if (x.data.success) {
          this.setState({
            name: x.data.result[0].adminName,
            image: x.data.result[0].adminProfileImage,
          });
        }
      })
      .catch(() => {
        return {};
      });
  }

  // HTML AND JSX RENDER
  render() {
    const { currentUser, name, image, role, status, loading } = this.state;
    return (
      <nav
        className={
          "navbar navbar-expand-lg navbar-light bg-light border-bottom border-primary nav-menu"
        }
      >
        <div className="container">
          <Link to="/" className="navbar-brand">
            Step<span>Out</span>
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#menu"
          >
            <span className="navbar-toggler-icon" />
          </button>

          <div className="collapse navbar-collapse" id={"menu"}>
            <ul className="navbar-nav mb-lg-0 ps-lg-5 menu-item">
              {/*Load nav item links*/}
              {NavItems.map((item, index) => {
                return (
                  <li key={index} className={"nav-item"}>
                    <Link to={item.url} className={item.class}>
                      {item.title}
                    </Link>
                  </li>
                );
              })}
            </ul>

            <div>
              {/*Check for logged-in user*/}
              {!currentUser && !loading && (
                <div className={"link-wrapper"}>
                  <Link to={"/register"} className={"me-4 register-link"}>
                    Sign Up
                  </Link>

                  <Link
                    to={"/login"}
                    className={"btn btn-outline-primary btn-small"}
                  >
                    Sign In
                  </Link>
                </div>
              )}

              {loading && (
                <div className="wrapper">
                  <div className="nav-spinner">
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </div>
              )}

              {currentUser && (
                <div className={"wrapper"}>
                  <div className="dropdown">
                    {image !== null && (
                      <img
                        src={adminProfileImgUrl + image}
                        alt={image}
                        className="img-fluid profile-img me-2"
                      />
                    )}

                    <Link
                      to={"#"}
                      className={"pe-2 dropdown-toggle user-menu"}
                      data-bs-toggle={"dropdown"}
                    >
                      Hello {name}
                    </Link>

                    <ul className="dropdown-menu">
                      <li>
                        <h6 className="dropdown-header">Menu</h6>
                      </li>
                      <li>
                        <Link to="/profile" className={"dropdown-item"}>
                          Profile
                        </Link>
                      </li>

                      {/*Check for admin login*/}
                      {role && status === process.env.REACT_APP_ACTIVE && (
                        <li>
                          <Link to="/dashboard" className="dropdown-item">
                            Dashboard
                          </Link>
                        </li>
                      )}

                      <li>
                        <hr className="dropdown-divider" />
                      </li>

                      <li>
                        <button
                          type={"button"}
                          onClick={logout}
                          className="dropdown-item"
                        >
                          Logout
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default Header;
