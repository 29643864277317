import React, {useEffect, useState} from "react";
import Axios from "axios";
import {useParams} from "react-router-dom";
import * as xlsx from "xlsx/xlsx.mjs";
import {format} from "date-fns";
import {resendVerificationEmail} from "../../../service/auth";

// RESTful
import {updateMemberCommentStatus, updateMemberStatus,} from "../../../service/admin";

import {
  getNonVerifiedMembers,
  getSpecificMemberDetails,
  getUser,
  getUserById,
  getUserDetail,
  memberComments,
} from "../../../service/member";

import authHeader from "../../../service/auth-header";

// IMAGE URL
import {adminProfileImgUrl} from "../../../config/config.json";
import memberImg from "../../../assets/img/people.png";

// CONTROLS
import {DisplayModal} from "../../../component/modal/modal";
import Paginate from "../../../component/pagination/paginate";
import ChangeStatus from "../../../utils/StatusToggle";
import ChangeMemberCommentStatus from "../../../utils/CommentStatusToggle";

//
export const MemberHomePage = () => {
  return (
    <div className="main-member-page">
      <div className="container">
        <div className="text-center">
          <h1 className="display-3">Stepout Members</h1>
          <img src={memberImg} alt={memberImg} />
        </div>
      </div>
    </div>
  );
};

// MEMBERS COMPONENT
export const Member = () => {
  // HOOKS
  const [userData, setUserData] = useState([]);
  const [memberInfo, setMemberInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [message, setMessage] = useState("");

  // PAGINATION IMPLEMENTATION
  const [activePage, setActivePage] = useState(1);
  const rowsPerPage = 5;
  const [count, setCount] = useState(0);
  const totalPages = Math.ceil(count / rowsPerPage);
  const calculatedRows = userData.slice(
    (activePage - 1) * rowsPerPage,
    activePage * rowsPerPage
  );

  // COLUMN LABELS
  const columnLabel = [
    {
      label: "Name",
      key: "name",
    },
    {
      label: "Surname",
      key: "surname",
    },
    {
      label: "Account status",
      key: "accountStatus",
    },
    {
      label: "Comment status",
      key: "commentStatus",
    },
  ];

  //  OPEN/CLOSE MODAL FUNCTIONS
  function onModalOpen() {
    setShow(true);
  }

  function onModalClose() {
    setShow(false);
  }

  // TOGGLE COMMENT ACTIVE/INACTIVE STATE OF REGISTERED MEMBER
  async function commentStatus(comment, id, name) {
    let prompt = window.confirm(
      `Do you want to change ${name}'s comment status?`
    );

    if (prompt) {
      setRefresh(false);

      await Axios.put(
        updateMemberCommentStatus(),
        { comment, id, name },
        {
          headers: authHeader.authHeader(),
        }
      )
        .then((x) => {
          if (x.data.success) {
            setMessage(x.data.message);
            setRefresh(true);
            onModalOpen();
          } else {
            setMessage(x.data.message);
            setRefresh(false);
            onModalOpen();
          }
        })
        .catch((error) => {
          setMessage(
            `An error has occurred while attempting to update ${name}'s comment status. ${error.message}.`
          );
          setRefresh(false);
          onModalOpen();
        });
    }
  }

  // USE EFFECT
  useEffect(() => {
    // GET ALL MEMBERS REGISTERED ONTO THE SYSTEM
    async function member() {
      setLoading(true);

      await Axios.get(getUser())
        .then((response) => {
          setUserData(response.data.result);
          setCount(response.data.result.length);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setMessage(
            `Could not get stepout members information. ${error.message}`
          );
          onModalOpen();
        });
    }

    // GET SPECIFIC USER DATA
    async function selectUserData() {
      await Axios.get(getSpecificMemberDetails())
        .then((data) => {
          setMemberInfo(data.data.result);
        })
        .catch((error) => {
          setMessage(`Stepout member could not be loaded. ${error.message}.`);
          onModalOpen();
        });
    }

    member();
    selectUserData();
  }, [refresh]);

  // CREATING AN EXCEL FILE OF REGISTERED MEMBERS
  function handleOnExport() {
    const wb = xlsx.utils.book_new(),
      ws = xlsx.utils.json_to_sheet(memberInfo);

    xlsx.utils.book_append_sheet(wb, ws, "Registered_Members");

    xlsx.writeFile(wb, "StepoutPE_Registered_Members.xlsx");
  }

  // HTML AND JSX RENDER
  return (
    <div className="container" id="viewCustomer">
      <h1>Verified Members of Stepout</h1>

      <div className="card shadow-sm border-primary">
        <div className="row">
          <div className="col-md-12 mx-auto text-center mt-3">
            <button
              className="btn btn-primary btn-sm download"
              onClick={handleOnExport}
            >
              Download registered member's information
            </button>
          </div>
        </div>

        <div className="card-body">
          <table className={"table table-hover"}>
            <thead>
              <tr>
                {columnLabel.map((item, index) => {
                  return <th key={index}>{item.label}</th>;
                })}
              </tr>
            </thead>

            <tbody>
              {loading && (
                <tr>
                  <td colSpan={5} className={"text-center"}>
                    <p className={"text-muted"}>Loading stepout members...</p>

                    <div className="admin-spinner">
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </td>
                </tr>
              )}

              {!userData.length && !loading && (
                <tr>
                  <td colSpan={5} className={"text-center"}>
                    <p className={"text-muted"}>No registered members.</p>
                  </td>
                </tr>
              )}

              {!loading &&
                calculatedRows.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.name}</td>
                      <td>{item.surname}</td>
                      <td>{item.status}</td>
                      <td>{item.canComment === 1 ? "active" : "inactive"}</td>
                      <td>
                        <div className="btn-group">
                          <a
                            href={getUserById(item.memberID)}
                            className="btn btn-sm btn-primary text-nowrap"
                          >
                            {item.name}'s information
                          </a>

                          <button
                            onClick={() =>
                              ChangeStatus(
                                item.memberID,
                                item.name,
                                item.status,
                                updateMemberStatus(),
                                setMessage,
                                setRefresh,
                                setShow
                              )
                            }
                            className="btn btn-sm btn-outline-primary text-nowrap"
                          >
                            Change {item.name}'s status
                          </button>

                          <button
                            onClick={
                              () =>
                                ChangeMemberCommentStatus(
                                  item.memberID,
                                  item.name,
                                  item.canComment,
                                  updateMemberCommentStatus(),
                                  setMessage,
                                  setRefresh,
                                  setShow
                                )
                            }
                            className="btn btn-sm btn-primary text-nowrap"
                          >
                            Change comment status
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>

          {/* NEW PAGINATION */}
          <Paginate
            activePage={activePage}
            count={count}
            rowsPerPage={rowsPerPage}
            totalPages={totalPages}
            setActivePage={setActivePage}
          />
        </div>
      </div>

      {/* MODAL */}
      <DisplayModal
        show={show}
        close={onModalClose}
        message={message}
        title={"Members"}
      />
    </div>
  );
};

export const NonVerifiedMembers = () => {
  // HOOKS
  const [members, setMembers] = useState([]);
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);

  // PAGINATION IMPLEMENTATION
  const [activePage, setActivePage] = useState(1);
  const rowsPerPage = 5;
  const [count, setCount] = useState(0);
  const totalPages = Math.ceil(count / rowsPerPage);
  const calculatedRows = members.slice(
    (activePage - 1) * rowsPerPage,
    activePage * rowsPerPage
  );

  // COLUMN LABELS
  const columnLabel = [
    {
      label: "Name",
      key: "name",
    },
    {
      label: "Surname",
      key: "surname",
    },
  ];

  // OPEN AND CLOSE MODAL FUNCTION
  function onModalOpen() {
    setShow(true);
  }

  function onModalClose() {
    setShow(false);
  }

  async function sendVerificationEmail(email) {
    setSendEmail(true);

    await Axios.post(resendVerificationEmail(), { email })
      .then((x) => {
        if (x.data.success) {
          setSendEmail(false);
          setMessage(x.data.message);
          onModalOpen();
        } else {
          setSendEmail(false);
          setMessage(x.data.message);
          onModalOpen();
        }
      })
      .catch((error) => {
        setSendEmail(false);
        setMessage(
          `An error has occurred while attempting to resend email verification. ${error.message}.`
        );
        onModalOpen();
      });
  }

  useEffect(() => {
    async function nonVerifiedMembers() {
      setLoading(true);

      await Axios.get(getNonVerifiedMembers())
        .then((x) => {
          if (x.data.success) {
            setMembers(x.data.result);
            setCount(x.data.result.length);
          } else {
            setMessage(x.data.message);
            onModalOpen();
          }
        })
        .catch((error) => {
          setMessage(
            `Could not get non-verified members information. ${error.message}`
          );
          onModalOpen();
        });
    }

    nonVerifiedMembers().finally(() => {
      setLoading(false);
    });
  }, []);

  return (
    <div className="nonverified-members-page">
      <div className="container">
        <h1 className="text-center">Non-verified members</h1>

        <div className="card shadow-sm border-primary">
          <div className="card-body">
            <table className="table table-hover">
              <thead>
                <tr>
                  {columnLabel.map((item, index) => {
                    return <th key={index}>{item.label}</th>;
                  })}
                </tr>
              </thead>

              <tbody>
                {loading && (
                  <tr>
                    <td colSpan={3} className={"text-center"}>
                      <p className="text-muted">Loading stepout members...</p>

                      <div className="admin-spinner">
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}

                {!members.length && !loading && (
                  <tr>
                    <td colSpan={5} className={"text-center"}>
                      <p className="text-muted">No non-verified members.</p>
                    </td>
                  </tr>
                )}

                {!loading &&
                  calculatedRows.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.name}</td>
                        <td>{item.surname}</td>
                        <td>
                          <div className="btn-group">
                            <a
                              href={getUserById(item.memberID)}
                              className="btn btn-sm btn-primary text-nowrap"
                            >
                              {item.name}'s information
                            </a>

                            {!sendEmail ? (
                              <button
                                className="btn btn-sm btn-outline-primary text-nowrap"
                                onClick={() =>
                                  sendVerificationEmail(item.email)
                                }
                              >
                                Send email verification
                              </button>
                            ) : (
                              <button
                                className="btn btn-primary btn-large"
                                disabled
                              >
                                <span
                                  className="spinner-grow spinner-grow-sm me-2"
                                  role="status"
                                  aria-hidden="true"
                                />
                                Sending email verification...
                              </button>
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>

            {/* PAGINATION */}
            <Paginate
              activePage={activePage}
              count={count}
              rowsPerPage={rowsPerPage}
              totalPages={totalPages}
              setActivePage={setActivePage}
            />
          </div>
        </div>
      </div>

      {/* MODAL */}
      <DisplayModal
        show={show}
        message={message}
        title={"Members"}
        close={onModalClose}
      />
    </div>
  );
};

// VIEW REGISTERED MEMBER INFORMATION COMPONENT
export const ViewUserDetails = () => {
  // HOOKS
  const [userDetails, setUserDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);

  // MEMBER ID
  const { id } = useParams();

  // OPEN AND CLOSE MODAL FUNCTION
  function onModalOpen() {
    setShow(true);
  }

  function onModalClose() {
    setShow(false);
  }

  // USE EFFECT
  useEffect(() => {
    // RETRIEVE SELECTED MEMBER'S INFORMATION THROUGH API CALL
    async function getMemberDetails() {
      setLoading(true);

      await Axios.post(getUserDetail(), {
        id: id,
      })
        .then((response) => {
          setUserDetails(response.data.result);
        })
        .catch((error) => {
          setMessage(`Member details could not be loaded. ${error.message}.`);
          onModalOpen();
        });
    }

    getMemberDetails().finally(() => {
      setLoading(false);
    });
  }, [id]);

  // HTML AND JSX RENDER
  return (
    <section className={"customer-information"}>
      {loading && (
        <div className={"text-center"}>
          <p className={"text-muted"}>Loading member information...</p>

          <div className="spinner">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      )}

      {!userDetails.length && !loading && (
        <p className={"text-muted text-center"}>
          Member information could not be displayed.
        </p>
      )}

      {userDetails.map((item, index) => {
        return (
          <div className="container" key={index}>
            <div className="card shadow-sm border-primary">
              <div className="card-header">
                <h2>Details for {item.name + " " + item.surname}</h2>
              </div>

              <div className="card-body p-4">
                <p className="card-text">Contact: {item.contact}</p>

                <hr />

                <p className="card-text">Email: {item.email}</p>

                <hr />

                <p className="card-text">
                  Date joined:{" "}
                  {format(new Date(item.dateJoined), "yyyy-MM-dd' 'HH:mm")}
                </p>

                <hr />

                <p className="card-text">Occupation: {item.occupation}</p>

                <hr />

                <p className="card-text">
                  Residential area: {item.residentialArea}
                </p>

                <hr />

                {item.profileImage != null ? (
                  <img
                    src={adminProfileImgUrl + item.profileImage}
                    alt={item.profileImage}
                    className={"img-fluid mb-2 mt-2"}
                  />
                ) : (
                  ""
                )}

                <br />

                <a
                  href={memberComments(item.memberID)}
                  className="btn btn-primary shadow-sm mt-3"
                >
                  View {item.name}'s comments
                </a>
              </div>
            </div>
          </div>
        );
      })}

      {/* MODAL */}
      <DisplayModal
        show={show}
        message={message}
        title={"Members"}
        close={onModalClose}
      />
    </section>
  );
};
