require("dotenv").config();

export function updateAbout() {
  return process.env.REACT_APP_API_URL + "/about/update_about";
}

export function insertAbout() {
  return process.env.REACT_APP_API_URL + "/about/insert_about";
}

export function getAbout() {
  return process.env.REACT_APP_API_URL + "/about/get_about";
}

export function updateAboutImage() {
  return process.env.REACT_APP_API_URL + "/about/update_about_image";
}
