import {format} from "date-fns";

// DATE CONVERSATION FUNCTION
export const date = (date) => {
  return new Date(date).toLocaleDateString(undefined, {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

export const DateFns = (date) => {
  return format(new Date(date), "yyyy-MM-dd' 'HH:mm");
};
