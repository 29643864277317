import React from "react";

export const Input = ({
  name,
  label,
  value,
  onChange,
  type,
  placeholder,
  defaultValue,
  accept,
  multiple,
  error,
  togglePassword,
  disabled,
  ref,
  style,
  onLoad,
}) => {
  return (
    <div className="form-group mb-2">
      <label htmlFor={name} className="form-label">
        {label}
      </label>
      {togglePassword}
      <input
        type={type}
        className="form-control"
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        onLoad={onLoad}
        placeholder={placeholder}
        defaultValue={defaultValue}
        accept={accept}
        ref={ref}
        style={style}
        multiple={multiple}
        disabled={disabled}
      />
      {error && <p className={"text-danger"}>{error}</p>}
    </div>
  );
};

export const InputWithIcon = ({
  name,
  label,
  value,
  onChange,
  type,
  placeholder,
  defaultValue,
  accept,
  multiple,
  error,
  togglePassword,
  inputGroupText,
  disabled,
}) => {
  return (
    <>
      <label htmlFor={name} className="form-label">
        {label}
      </label>
      <div className={"input-group mb-3"}>
        {togglePassword}
        <div className="input-group-text">{inputGroupText}</div>
        <input
          type={type}
          className={"form-control"}
          id={name}
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          defaultValue={defaultValue}
          accept={accept}
          multiple={multiple}
          disabled={disabled}
        />

        {error && <p className={"text-danger"}>{error}</p>}
      </div>
    </>
  );
};

export const FloatInput = ({
  name,
  label,
  value,
  onChange,
  type,
  placeholder,
  error,
  togglePassword,
  disabled,
}) => {
  return (
    <div className="form-floating">
      <input
        type={type}
        className={"form-control"}
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
      />
      <label htmlFor={name}>{label}</label>
      {togglePassword}
      {error && <p className={"text-danger"}>{error}</p>}
    </div>
  );
};
