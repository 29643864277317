import React, {useEffect, useRef, useState} from "react";
import axios from "axios";

// RESTful
import {getHeroImage, updateHeroImage} from "../../../service/hero";

import authHeader from "../../../service/auth-header";

// IMAGE URL
import {adminHeroImageUrl} from "../../../config/config.json";
import homeImg from "../../../assets/img/home.png";

// CONTROLS
import {Input} from "../../../component/common/input";
import {DisplayModal} from "../../../component/modal/modal";

// FONTAWESOME
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

// ADMINISTRATOR HERO COMPONENT
export const AdminHero = () => {
  return (
    <div className={"admin-hero"}>
      <div className="container">
        <h1 className={"text-center display-3"}>Home Page Image Section</h1>
        <img src={homeImg} alt="home.png" className="img-fluid" />
      </div>
    </div>
  );
};

// ADD NEW HERO IMAGE COMPONENT
export const AddHeroImage = () => {
  // HOOKS
  const [imagePreview, setImagePreview] = useState("");

  // HERO IMAGE HANDLER
  const heroImagePreviewHandler = (e) => {
    setImagePreview(URL.createObjectURL(e.target.files[0]));
  };

  // INSERT/UPDATE HERO IMAGE
  const heroImageHandler = async (e) => {
    const formData = new FormData();
    const image = e.target.files[0];

    heroImagePreviewHandler(e);

    formData.append("hero-image", image);

    await axios
      .put(updateHeroImage(), formData, { headers: authHeader.contentHeader() })
      .then((x) => {
        if (x.data.success) window.alert(x.data.message);
        else window.alert(x.data.message);
      })
      .catch((error) => {
        window.alert(`Image could not be uploaded. ${error.message}.`);
      });
  };

  // HTML AND JSX RENDER
  return (
    <section className="add-hero-image">
      <div className="container">
        <h2>Add hero image</h2>

        <div className="card">
          <div className="card-body">
            <form method={"post"} encType={"multipart/form-data"}>
              <Input
                label={"Add home page image"}
                type={"file"}
                accept={"image/*"}
                multiple={false}
                name={"hero-image"}
                onChange={heroImageHandler}
              />

              {imagePreview.length > 0 ? (
                <img
                  src={imagePreview}
                  alt={imagePreview}
                  className={"img-fluid img-thumbnail"}
                  onChange={imagePreview}
                />
              ) : (
                <p className={"text-center text-muted"}>Image preview</p>
              )}
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

// EDIT HERO IMAGE COMPONENT
export const UpdateHeroImage = () => {
  // HOOKS
  const [hero, setHero] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);

  // USE REF HOOKS
  const heroImageFile = useRef(null);

  // OPEN/CLOSE MODAL FUNCTIONS
  function onModalOpen() {
    setShow(true);
  }

  function onModalClose() {
    setShow(false);
    if (success) window.location.reload();
  }

  function handleHeroImageRef() {
    heroImageFile.current.click();
  }

  // HERO IMAGE HANDLER AND UPDATER
  async function updateHeroImageHandler(e) {
    e.preventDefault();

    const image = e.target.files[0];
    const formData = new FormData();

    formData.append("hero-image", image);
    //setLoading(true);

    await axios
      .put(updateHeroImage(), formData, {
        headers: authHeader.contentHeader(),
      })
      .then((x) => {
        if (!x.data.success) {
          setMessage(x.data.message);
          setSuccess(false);

          onModalOpen();
        } else {
          setMessage(x.data.message);
          setSuccess(true);

          onModalOpen();
        }
      })
      .catch((error) => {
        setSuccess(false);
        setMessage(`Home page image could not be updated. ${error.message}.`);

        onModalOpen();
      });
  }

  // USE EFFECT
  useEffect(() => {
    // RETRIEVE HERO IMAGE THROUGH API CALL
    async function getHero() {
      setLoading(true);
      await axios
        .get(getHeroImage())
        .then((x) => {
          if (x.data.success) {
            setHero(x.data.result);
          } else {
            setMessage(x.data.message);
            onModalOpen();
          }
        })
        .catch((error) => {
          setMessage(
            `Home page image could not be displayed. ${error.message}.`
          );
          onModalOpen();
        });
    }

    getHero().finally(() => {
      setLoading(false);
    });
  }, []);

  // HTML AND RENDER
  return (
    <section className="set-hero-image">
      <div className="container">
        {loading && (
          <div>
            <p className={"text-muted text-center"}>
              Loading home page image...
            </p>

            <div className="spinner">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        )}

        {!hero.length && !loading && (
          <p className={"text-muted text-center"}>
            Home page image could not be displayed.
          </p>
        )}

        {!loading &&
          hero.map((x, index) => {
            return (
              <form key={index} encType="multipart/form-data" method="post">
                <div className="card-group">
                  <div className="card border-primary shadow-sm">
                    <div className="card-body">
                      <div className="button-container">
                        <button
                          className="btn btn-primary shadow-sm mb-3"
                          onClick={handleHeroImageRef}
                          type="button"
                        >
                          <FontAwesomeIcon icon={faPlus} />
                        </button>
                      </div>

                      <input
                        type={"file"}
                        name={"hero-image"}
                        accept={"image/*"}
                        multiple={false}
                        ref={heroImageFile}
                        style={{ display: "none" }}
                        onChange={(e) => updateHeroImageHandler(e)}
                      />

                      <p className={"text-muted text-center"}>
                        *Image must not be larger than 2MB
                      </p>

                      <p className={"text-center text-muted mt-5"}>
                        When uploaded the home page image will be automatically
                        updated
                      </p>
                    </div>
                  </div>

                  <div className="card border-primary shadow-sm">
                    <div className="card-body">
                      <p className="text-center text-muted">
                        Current home page image
                      </p>

                      <img
                        className={"img-fluid"}
                        src={adminHeroImageUrl + x.heroImage}
                        alt={x.heroImage}
                      />
                    </div>
                  </div>
                </div>
              </form>
            );
          })}
      </div>

      {/* MODAL */}
      <DisplayModal
        show={show}
        message={message}
        close={onModalClose}
        title={"Hero"}
      />
    </section>
  );
};
