import React, { Component } from "react";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { CreateSlugTitle } from "../../utils/SlugMaker";
import { format } from "date-fns";

export default class RichText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
      content: "",
    };
  }

  onPostClick = () => {
    window.alert(this.state.content);
  };

  componentDidMount() {
    const lorem = "";

    const contentBlock = htmlToDraft(lorem);
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    const _editorState = EditorState.createWithContent(contentState);

    this.setState({ editorState: _editorState });
  }

  uploadImageCallBack = async (file) => {
    console.log(file);
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest(); // eslint-disable-line no-undef
      xhr.open("POST", "https://api.imgur.com/3/image");
      xhr.setRequestHeader("Authorization", "Client-ID 26abe03379a864f");
      const data = new FormData(); // eslint-disable-line no-undef
      data.append("image", file);
      xhr.send(data);
      xhr.addEventListener("load", () => {
        const response = JSON.parse(xhr.responseText);
        resolve(response);
      });
      xhr.addEventListener("error", () => {
        const error = JSON.parse(xhr.responseText);
        reject(error);
      });
    });
  };

  render() {
    const { editorState } = this.state;

    return (
      <div id={"rich-text"}>
        <div className="container">
          <h1>Rich Text</h1>
          <p>{format(Date.now(), "yyyy-MM-dd")}</p>
          <p>{format(Date.now() + 2160000, "yyyy-MM-dd")}</p>
          <Editor
            editorState={editorState}
            onEditorStateChange={(newState) => {
              this.setState({
                editorState: newState,
                content: draftToHtml(
                  convertToRaw(newState.getCurrentContent())
                ),
              });
            }}
            id={"rich-text-editor"}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            spellCheck
            toolbar={{
              inline: { inDropdown: true },
              list: { inDropdown: true },
              textAlign: { inDropdown: true },
              link: { inDropdown: true },
              history: { inDropdown: true },
              image: {
                uploadCallback: this.uploadImageCallBack,
                alt: { present: true, mandatory: false },
              },
            }}
          />
          <button
            className="btn btn-primary"
            onClick={() =>
              CreateSlugTitle("Hello World, just testing out this slug ting")
            }
          >
            Post
          </button>
        </div>
      </div>
    );
  }
}
