import jwtDecode from "jwt-decode";
require("dotenv").config();

export function registerUser() {
  return process.env.REACT_APP_API_URL + "/api/register";
}

export function registerAdmin() {
  return process.env.REACT_APP_API_URL + "/admin/add_admin";
}

export function verify(token, id) {
  return process.env.REACT_APP_API_URL + `/verify?token=${token}&id=${id}`;
}

export function resendVerificationEmail() {
  return process.env.REACT_APP_API_URL + `/resend_verification`;
}

export function loginUser() {
  return process.env.REACT_APP_API_URL + "/api/login";
}

export function forceLogout() {
  localStorage.removeItem(process.env.REACT_APP_TOKEN_KEY);
  window.location.href = "/";
}

export function logout() {
  let logoutConfirm = window.confirm("Are you sure you want to logout?");
  if (logoutConfirm) {
    localStorage.removeItem(process.env.REACT_APP_TOKEN_KEY);
    window.location.href = "/";
  }
}

export function sendPasswordResetLink() {
  return process.env.REACT_APP_API_URL + `/api/forgot_password`;
}

export function updatePassword() {
  return process.env.REACT_APP_API_URL + "/update-password";
}

export function _changePassword(token, id) {
  return (
    process.env.REACT_APP_API_URL + `/change-password?token=${token}&id=${id}`
  );
}

export function setToken(token) {
  return localStorage.setItem(process.env.REACT_APP_TOKEN_KEY, token);
}

export function checkAuthentication() {
  return process.env.REACT_APP_API_URL + "/is_user_auth";
}

export function getCurrentUser() {
  try {
    const jwt = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY);
    return jwtDecode(jwt);
  } catch (e) {
    return null;
  }
}

export function getToken() {
  const jwt = localStorage.getItem(process.env.REACT_APP_TOKEN_KEY);
  return jwt;
}
