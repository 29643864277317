import React, {useEffect, useRef, useState} from "react";
import Axios from "axios";
import {useNavigate, useParams} from "react-router-dom";
import {CreateSlugTitle} from "../../../utils/SlugMaker";
import {format} from "date-fns";
import {getCurrentUser} from "../../../service/auth";

import ChangeStatus from "../../../utils/StatusToggle";

// RESTful
import {
    addBlogPost,
    allComments,
    getBlog,
    getBlogByAdmin,
    getBlogPostByID,
    getPost,
    updateAdminCommentStatus,
    updateBlogImage,
    updateBlogPost,
    updateBlogStatus,
    updateMemberCommentStatus,
} from "../../../service/blog";

import authHeader from "../../../service/auth-header";

// IMAGE URL
import blogImg from "../../../assets/img/blog.png";
import {adminBlogImageUrl} from "../../../config/config.json";

// FONTAWESOME
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

// WYSIWYG COMPONENT
import {convertToRaw, EditorState} from "draft-js";
import draftToHtml from "draftjs-to-html";
import {Editor} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import RichText from "../../../component/common/TextEditor";

// CONTROLS
import {Input} from "../../../component/common/input";
import {DisplayModal} from "../../../component/modal/modal";
import Paginate from "../../../component/pagination/paginate";

// .ENV
require("dotenv").config();

function uploadImageCallBack(file) {
  console.log(file);
  return new Promise((resolve, reject) => {
    const reader = new FileReader(); // eslint-disable-line no-undef
    reader.onload = (e) => resolve({ data: { link: e.target.result } });
    reader.onerror = (e) => reject(e);
    reader.readAsDataURL(file);
  });
}

// ADMINISTRATOR BLOG
const AdminBlog = () => {
  return (
    <div className="admin-blog-section">
      <div className={"container"}>
        <h1 className="display-3">Blog Section</h1>
        <img src={blogImg} alt={blogImg} className={"img-fluid"} />
      </div>
    </div>
  );
};

// ADD NEW BLOG COMPONENT
const AddNewBlogPost = () => {
  // ADMINISTRATOR INFORMATION
  const user = getCurrentUser();
  const adminID = user.id;
  const admin = user.name + " " + user.surname;

  // HOOKS
  const [blogTitle, setBlogTitle] = useState("");
  const [postText, setPostText] = useState("");
  const [image, setImage] = useState();
  const [message, setMessage] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);

  // USE REF HOOK AND FUNCTION
  const blogImageFile = useRef(null);

  // USE NAVIGATE HOOK
  const navigate = useNavigate();

  function blogImageHandlerRef() {
    blogImageFile.current.click();
  }

  // WYSIWYG HOOKS
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [content, setContent] = useState("");

  // OPEN AND CLOSE MODAL FUNCTION
  function onModalOpen() {
    setShow(true);
  }

  function onModalClose() {
    setShow(false);
    if (success) navigate("/dashboard/blog/view_blog_post");
  }

  // BLOG IMAGE HANDLER
  function imageHandler(e) {
    setImage(e.target.files[0]);
    imagePreviewHandler(e);
  }

  function imagePreviewHandler(e) {
    setImagePreview(URL.createObjectURL(e.target.files[0]));
  }

  // UPLOAD BLOG FUNCTION
  async function uploadBlogPost(e) {
    try {
      e.preventDefault();

      let titleSlug = CreateSlugTitle(blogTitle);

      let formData = new FormData();

      formData.append("adminID", adminID);
      formData.append("title", blogTitle);
      formData.append("author", admin);
      formData.append("postText", postText);
      formData.append("image", image);
      formData.append("slug", titleSlug);

      await Axios.post(addBlogPost(), formData, {
        headers: authHeader.adminMultiHeader(),
      })
        .then((blog) => {
          if (blog.data.addBlog) {
            setMessage(blog.data.message);
            setSuccess(true);

            onModalOpen();
          } else {
            setSuccess(false);
            setMessage(blog.data.message);

            onModalOpen();
          }
        })
        .catch((error) => {
          setSuccess(false);
          setMessage(
            `An error has occurred while attempting to upload blog post. ${error.message}.`
          );
          onModalOpen();
        });
    } catch (error) {
      setMessage(`Blog post could not be uploaded. ${error.message}.`);
      onModalOpen();
    }
  }

  // HTML AND JSX RENDER
  return (
    <div className="container add-blog">
      <h1>Add new blog post</h1>

      <div className="card shadow-sm border-primary">
        <div className="card-body">
          <form method="post" encType="multipart/form-data">
            <div className="row">
              <div className="col-md-6">
                <Input
                  label={"Enter blog post title"}
                  type={"text"}
                  name={"title"}
                  placeholder={"Enter a title for the blog post"}
                  onChange={(e) => setBlogTitle(e.target.value)}
                />
              </div>

              <div className="col-md-6">
                <p className="mb-0">Add blog image</p>

                <button
                  className="btn btn-primary shadow-sm mt-3"
                  type="button"
                  onClick={blogImageHandlerRef}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </button>

                <input
                  type={"file"}
                  name={"image"}
                  id="image"
                  onChange={imageHandler}
                  ref={blogImageFile}
                  accept={"image/*"}
                  multiple={false}
                  style={{ display: "none" }}
                />
              </div>

              <div className="col-md-12 mt-3">
                <hr />

                {imagePreview.length > 0 ? (
                  <img
                    src={imagePreview}
                    alt={imagePreview}
                    className="img-fluid"
                    onChange={imagePreview}
                    style={{ width: "400px", height: "auto" }}
                  />
                ) : (
                  <p className="text-muted">Blog image preview</p>
                )}

                <hr />
              </div>

              <div className="col-md-12 blog-post-text">
                <label htmlFor="postText">Enter your blog post text</label>

                <Editor
                  editorState={editorState}
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  toolbarClassName="toolbar-class"
                  spellCheck
                  toolbar={{
                    inline: { inDropdown: true },
                    list: { inDropdown: true },
                    textAlign: { inDropdown: true },
                    link: { inDropdown: true },
                    history: { inDropdown: true },
                    image: {
                      uploadCallback: uploadImageCallBack,
                      alt: { present: true, mandatory: false },
                    },
                  }}
                  onEditorStateChange={(newState) => {
                    setEditorState(newState);
                    setContent(
                      draftToHtml(convertToRaw(newState.getCurrentContent()))
                    );
                    setPostText(content);
                  }}
                />
              </div>
            </div>

            <button
              className="btn btn-primary shadow-sm"
              onClick={(e) => uploadBlogPost(e)}
            >
              Upload Blog Post
            </button>
          </form>
        </div>

        {/* MODAL */}
        <DisplayModal
          show={show}
          close={onModalClose}
          message={message}
          title={"Blog"}
        />
      </div>
    </div>
  );
};

// VIEW UPLOADED BLOG COMPONENTS
const ViewBlogPost = () => {
  // HOOKS
  const [blogData, setBlogData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const [refresh, setFresh] = useState(false);

  // NEW PAGINATION IMPLEMENTATION
  const [activePage, setActivePage] = useState(1);
  const rowsPerPage = 5;
  const [count, setCount] = useState(0);
  const totalPages = Math.ceil(count / rowsPerPage);
  const calculatedRows = blogData.slice(
    (activePage - 1) * rowsPerPage,
    activePage * rowsPerPage
  );

  // ADMINISTRATOR INFORMATION
  const admin = getCurrentUser();
  let adminID = admin.id;
  let adminRole = admin.role;

  // COLUMN LABELS
  const columnLabel = [
    { label: "Name" },
    { label: "Date posted" },
    { label: "Status" },
  ];

  // MODAL FUNCTION
  function onModalOpen() {
    setShow(true);
  }

  function onModalClose() {
    setShow(false);
  }

  // USE EFFECT
  useEffect(() => {
    // RETRIEVE ALL BLOG POST THROUGH API CALL
    async function getAllBlogPost() {
      setLoading(true);

      await Axios.get(getBlog())
        .then((data) => {
          if (data.data.success) {
            setBlogData(data.data.result);
            setCount(data.data.result.length);
          } else {
            setLoading(false);
            setMessage(data.data.message);
            onModalOpen();
          }
        })
        .catch((error) => {
          setMessage(`Blog posts could not be displayed. ${error.message}`);
          onModalOpen();
        });
    }

    // RETRIEVE BLOG POSTS UPLOADED BY SPECIFIC ADMINISTRATOR THROUGH API CALL
    async function getBlogPost() {
      setLoading(true);

      await Axios.post(getBlogByAdmin(), { adminID: adminID })
        .then((blog) => {
          if (blog.data.success) {
            setBlogData(blog.data.result);
            setCount(blog.data.result.length);
          } else {
            setMessage(blog.data.message);
            onModalOpen();
          }
        })
        .catch((error) => {
          setMessage(`Blog posts could not be displayed. ${error.message}.`);
          onModalOpen();
        });
    }

    if (adminRole === process.env.REACT_APP_HIGHEST)
      getAllBlogPost().finally(() => {
        setLoading(false);
      });
    else
      getBlogPost().finally(() => {
        setLoading(false);
      });
  }, [adminID, adminRole, refresh]);

  // HTML AND JSX RENDER
  return (
    <div className={"container view-blog"}>
      <h1>Stepout Blog Posts List</h1>
      <div className="card shadow-sm border-primary">
        <div className="card-body">
          <table className="table table-hover">
            <thead>
              <tr>
                {columnLabel.map((item, index) => {
                  return <th key={index}>{item.label}</th>;
                })}
              </tr>
            </thead>

            <tbody>
              {loading && (
                <tr>
                  <td colSpan={6} className={"text-center"}>
                    <p className="text-muted">Loading blog posts...</p>

                    <div className="admin-spinner">
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </td>
                </tr>
              )}

              {!blogData.length && !loading && (
                <tr>
                  <td colSpan={6} className={"text-center"}>
                    <p className="text-muted">No blog posts to display.</p>
                  </td>
                </tr>
              )}

              {!loading &&
                calculatedRows.map((item, index) => {
                  return (
                    <tr key={item.blogID}>
                      <td>{item.blogName}</td>
                      <td>
                        {format(new Date(item.dateAdded), "yyyy-MM-dd' 'HH:mm")}
                      </td>
                      <td>{item.status}</td>
                      <td>
                        <div className="btn-group">
                          <a
                            className={"btn btn-primary btn-sm text-nowrap"}
                            href={getBlogPostByID(item.slug)}
                          >
                            Edit blog bost
                          </a>
                          <button
                            onClick={() =>
                              ChangeStatus(
                                item.blogID,
                                item.blogName,
                                item.status,
                                updateBlogStatus(),
                                setMessage,
                                setFresh,
                                setShow
                              )
                            }
                            className={
                              "btn btn-sm btn-outline-primary text-nowrap"
                            }
                          >
                            Change blog post status
                          </button>
                          <a
                            href={`/dashboard/blog/blog_comment/${item.blogID}`}
                            className="btn btn-outline-primary btn-sm"
                          >
                            View blog comments
                          </a>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>

          {/* MODAL */}
          <DisplayModal
            show={show}
            close={onModalClose}
            message={message}
            title={"Blog"}
          />

          {/*NEW PAGINATION*/}
          <Paginate
            activePage={activePage}
            count={count}
            rowsPerPage={rowsPerPage}
            totalPages={totalPages}
            setActivePage={setActivePage}
          />
        </div>
      </div>
    </div>
  );
};

// EDIT SELECTED BLOG POST COMPONENT
const EditBlogPost = () => {
  // HOOKS
  const [blog, setBlog] = useState([]);
  const [blogTitle, setBlogTitle] = useState("");
  const [blogPost, setBlogPost] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const { slug } = useParams();

  // USE NAVIGATE HOOK
  const navigate = useNavigate();

  // USE REF HOOK AND FUNCTION
  const blogImageFile = useRef(null);

  function blogImageHandlerRef() {
    blogImageFile.current.click();
  }

  // OPEN/CLOSE MODAL FUNCTION
  function onModalOpen() {
    setShow(true);
  }

  function onModalClose() {
    setShow(false);
    if (success) navigate("/dashboard/blog/view_blog_post");
  }

  // USE EFFECT
  useEffect(() => {
    // RETRIEVE SELECTED BLOG POST THROUGH API CALL
    async function blogs() {
      setLoading(true);
      await Axios.post(getPost(), { slug: slug })
        .then((data) => {
          if (data.data.success) {
            setBlog(data.data.result);
          } else {
            setMessage(data.data.message);
            onModalOpen();
          }
        })
        .catch((error) => {
          setMessage(
            `Blog post details could not be displayed. ${error.message}.`
          );
          onModalOpen();
        });
    }

    blogs().finally(() => {
      setLoading(false);
    });
  }, [slug]);

  // UPDATE BLOG POST
  async function updatePost(e) {
    e.preventDefault();

    try {
      await blog.forEach((item) => {
        if (item) {
          Axios.put(
            updateBlogPost(),
            {
              blogID: item.blogID,
              slug: blogTitle === "" ? item.slug : CreateSlugTitle(blogTitle),
              blogTitle: blogTitle === "" ? item.blogName : blogTitle,
              blogPost: blogPost === "" ? item.blogPost : blogPost,
            },
            { headers: authHeader.authHeader() }
          )
            .then((data) => {
              if (data.data.updated) {
                setMessage(data.data.message);
                setSuccess(true);
                onModalOpen();
              } else {
                setMessage(data.data.message);
                setSuccess(false);
                onModalOpen();
              }
            })
            .catch((error) => {
              setSuccess(false);
              setMessage(`Blog post could not be updated. ${error.message}.`);
              onModalOpen();
            });
        }
      });
    } catch (error) {
      setMessage(`Selected blog post could not be updated. ${error.message}.`);
      onModalOpen();
    }
  }

  // BLOG POST IMAGE HANDLER AND UPDATER
  async function imageHandler(e) {
    const image = e.target.files[0];
    const formData = new FormData();

    formData.append("image", image);
    formData.append("id", slug);

    await Axios.post(updateBlogImage(), formData, {
      headers: authHeader.adminMultiHeader(),
    })
      .then((data) => {
        if (data.data.update) {
          setMessage(data.data.message);
          setSuccess(true);
          onModalOpen();
        } else {
          setSuccess(false);
          setMessage(data.data.message);
          e.target.value = "";
          onModalOpen();
        }
      })
      .catch((error) => {
        setSuccess(false);
        setMessage(`Blog image could not be updated. ${error.message}.`);
        onModalOpen();
      });
  }

  // HTML AND JSX RENDER
  return (
    <div className="container edit-blog">
      {loading && (
        <p className={"text-muted text-center"}>Loading blog post details...</p>
      )}

      {!blog.length && !loading && (
        <p className={"text-muted text-center"}>
          Blog post details could not be displayed.
        </p>
      )}

      {!loading &&
        blog.map((item, index) => {
          return (
            <div key={index}>
              <div className="card shadow-sm border-primary">
                <div className="card-header">
                  <h1>Edit {item.blogName}</h1>
                  <p>By {item.adminName + " " + item.adminSurname}</p>
                </div>

                <div className="card-body">
                  <form encType="multipart/form-data" method="post">
                    <div className="row">
                      <label
                        htmlFor="blog-image"
                        className={"col-sm-2 col-form-label"}
                      >
                        Blog Post Image
                      </label>

                      <div className="col-sm-10">
                        <div className="text-center">
                          <img
                            src={adminBlogImageUrl + item.blogImage}
                            alt={item.blogImage}
                            className="img-fluid"
                            style={{ width: "500px", height: "auto" }}
                          />
                        </div>

                        <div className="image-handler-container text-center">
                          <p className="mb-0">Update blog image</p>

                          <button
                            className="btn btn-primary shadow-sm mt-1"
                            type="button"
                            onClick={blogImageHandlerRef}
                          >
                            <FontAwesomeIcon icon={faPlus} />
                          </button>

                          <input
                            type="file"
                            name={"image"}
                            id={"update-blog-image"}
                            className="form-control"
                            onChange={(e) => imageHandler(e)}
                            ref={blogImageFile}
                            style={{ display: "none" }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <label
                        htmlFor="title"
                        className="col-sm-2 col-form-label"
                      >
                        Post Title
                      </label>

                      <div className="col-sm-10">
                        <input
                          type="text"
                          className="form-control"
                          id="title"
                          defaultValue={item.blogName}
                          onChange={(e) => setBlogTitle(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <label htmlFor="date" className="col-sm-2 col-form-label">
                        Date Posted
                      </label>

                      <div className="col-sm-10">
                        <input
                          type="text"
                          className="form-control"
                          id="date"
                          disabled
                          defaultValue={format(
                            new Date(item.dateAdded),
                            "yyyy-MM-dd' 'HH:mm"
                          )}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <label
                        htmlFor="postText"
                        className="col-sm-2 col-form-label"
                      >
                        Blog Post Entry
                      </label>

                      <div className="col-sm-10">
                        <RichText
                          context={item.blogPost}
                          setContextValue={setBlogPost}
                        />
                      </div>
                    </div>

                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn btn-primary shadow-sm"
                        onClick={(e) => updatePost(e)}
                      >
                        Update Post
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          );
        })}

      {/* MODAL */}
      <DisplayModal
        show={show}
        close={onModalClose}
        message={message}
        title={"Blog"}
      />
    </div>
  );
};

// BLOG POST COMMENTS COMPONENT
const BlogComment = () => {
  // HOOKS
  const [comment, setComment] = useState([]);
  const [blogTitle, setBlogTitle] = useState("");
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  // BLOG ID
  const { id } = useParams();

  // OPEN/CLOSE MODAL FUNCTION
  function onModalOpen() {
    setShow(true);
  }

  function onModalClose() {
    setShow(false);
  }

  // TOGGLE COMMENT ACTIVE/INACTIVE STATE
  async function commentState(adminID, memberID, blogID, currentState) {
    let prompt = window.confirm(
      `Do you want to change the status of this comment?`
    );

    let status = "";

    setRefresh(false);

    if (prompt) {
      // CHECK IF THE STATUS OF THE COMMENT IS INACTIVE
      if (currentState === "inactive") {
        status = "active";

        if (adminID === null) {
          await Axios.put(
            updateMemberCommentStatus(),
            { memberID, blogID, status },
            { headers: authHeader.authHeader() }
          )
            .then((data) => {
              if (data.data.success) {
                setMessage(data.data.message);
                setRefresh(true);
                onModalOpen();
              } else {
                setMessage(data.data.message);
                onModalOpen();
              }
            })
            .catch((error) => {
              setMessage(
                `Comment status could not be changed. ${error.message}.`
              );
              onModalOpen();
            });
        }

        if (memberID === null) {
          await Axios.put(
            updateAdminCommentStatus(),
            { adminID, blogID, status },
            { headers: authHeader.authHeader() }
          )
            .then((data) => {
              if (data.data.success) {
                setMessage(data.data.message);
                setRefresh(true);
                onModalOpen();
              } else {
                setMessage(data.data.message);
                onModalOpen();
              }
            })
            .catch((error) => {
              setMessage(
                `Comment status could not be changed. ${error.message}.`
              );
              onModalOpen();
            });
        }
      }

      // CHECK IF THE STATUS OF THE COMMENT IS ACTIVE
      if (currentState === "active") {
        status = "inactive";

        if (adminID === null) {
          await Axios.put(
            updateMemberCommentStatus(),
            { memberID, blogID, status },
            { headers: authHeader.authHeader() }
          )
            .then((data) => {
              if (data.data.success) {
                setMessage(data.data.message);
                setRefresh(true);
                onModalOpen();
              } else {
                setMessage(data.data.message);
                onModalOpen();
              }
            })
            .catch((error) => {
              setMessage(
                `Comment status could not be changed. ${error.message}.`
              );
              onModalOpen();
            });
        }

        if (memberID === null) {
          await Axios.put(
            updateAdminCommentStatus(),
            { adminID, blogID, status },
            { headers: authHeader.authHeader() }
          )
            .then((data) => {
              if (data.data.success) {
                setMessage(data.data.message);
                setRefresh(true);
                onModalOpen();
              } else {
                setMessage(data.data.message);
                console.log(data.data.message);
                onModalOpen();
              }
            })
            .catch((error) => {
              console.log(error.message);
              setMessage(
                `Comment status could not be changed. ${error.message}.`
              );
              onModalOpen();
            });
        }
      }
    }
  }

  const columnLabel = [{ label: "Comment details" }, { label: "Options" }];

  useEffect(() => {
    // RETRIEVE ALL COMMENTS OF A SELECTED BLOG POST THROUGH AN API CALL
    async function getAllComments() {
      setLoading(true);

      await Axios.get(allComments(id))
        .then((data) => {
          if (data.data.success) {
            setComment(data.data.result);
            setBlogTitle(data.data.result[0].blogName);
          } else {
            setMessage(data.data.message);
            onModalOpen();
          }
        })
        .catch((error) => {
          setMessage(`Blog comments could not be loaded. ${error.message}.`);
          onModalOpen();
        });
    }

    getAllComments().finally(() => {
      setLoading(false);
    });
  }, [id, refresh]);

  // HTML AND JSX RENDER
  return (
    <div className="blog-comments">
      <div className="container">
        <div className="card shadow-sm border-primary">
          <div className="card-header">
            <h4 className="text-center">{blogTitle} comments</h4>
          </div>

          <div className="card-body">
            <table className="table">
              <thead>
                <tr>
                  {columnLabel.map((x, index) => {
                    return <th key={index}>{x.label}</th>;
                  })}
                </tr>
              </thead>

              <tbody>
                {loading && (
                  <tr>
                    <td colSpan={2} className={"text-center"}>
                      <p>Loading blog comments...</p>

                      <div className="admin-spinner">
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}

                {comment.length > 0 ? (
                  comment.map((x, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {x.adminRole !== null ? (
                            <p className="text-muted mb-0">
                              {"Commentor name: " +
                                x.adminName +
                                " " +
                                x.adminSurname}
                            </p>
                          ) : (
                            <p className="text-muted mb-0">
                              {"Commentor name: " + x.name + " " + x.surname}
                            </p>
                          )}

                          <p className="text-muted mb-0">
                            {"Comment status: " + x.status}
                          </p>

                          <p className="text-muted">
                            {"Date of comment: " +
                              format(
                                new Date(x.dateOfComment),
                                "yyyy-MM-dd' 'HH:mm"
                              )}
                          </p>

                          <p>{x.commentText}</p>
                        </td>

                        <td>
                          <button
                            className="btn btn-sm btn-primary text-nowrap"
                            onClick={() =>
                              commentState(
                                x.adminID,
                                x.memberID,
                                x.blogID,
                                x.status
                              )
                            }
                          >
                            Change comment status
                          </button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={2}>
                      <p className="text-muted text-center">
                        No comments to display
                      </p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* MODAL */}
      <DisplayModal
        show={show}
        close={onModalClose}
        message={message}
        title={"Blog"}
      />
    </div>
  );
};

export { AdminBlog, AddNewBlogPost, ViewBlogPost, EditBlogPost, BlogComment };
