import Axios from "axios";
import authHeader from "../service/auth-header";

export default async function ChangeMemberCommentStatus(
  id,
  name,
  comment,
  api,
  setMessage,
  setRefresh,
  setShow
) {
  let prompt = window.confirm(
    `Do you want to change ${name}'s comment status?`
  );

  if (prompt) {
    setRefresh(false);

    await Axios.put(
      api,
      { comment, id, name },
      {
        headers: authHeader.authHeader(),
      }
    )
      .then((x) => {
        if (x.data.success) {
          setMessage(x.data.message);
          setRefresh(true);
          setShow(true);
        } else {
          setMessage(x.data.message);
          setRefresh(false);
          setShow(true);
        }
      })
      .catch((error) => {
        setMessage(
          `An error has occurred while attempting to update ${name}'s comment status. ${error.message}.`
        );
        setRefresh(false);
        setShow(true);
      });
  }
}
