// Imports
import React, {Component} from "react";
import Axios from "axios";
import Joi from "joi";
import {Link} from "react-router-dom";

// IMAGE URL
import logo from "../../assets/img/STEP_OUT_LOGO_RED.png";

// FONTAWESOME
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";

// RESTful AND TOKEN
import {loginUser, setToken} from "../../service/auth";

// CONTROLS
import {FloatInput} from "../../component/common/input";
import {DisplayModal} from "../../component/modal/modal";
import {Helmet} from "react-helmet";

// .ENV
require("dotenv").config();

// LOGIN CLASS COMPONENT
class Login extends Component {
  // STATE
  state = {
    data: {
      username: "",
      password: "",
    },
    loading: false,
    errors: {},
    show: false,
    message: "",
    sendVerifyLink: false,
    isRevealPassword: false,
  };

  // CLASS CONSTRUCTOR
  constructor(props) {
    super(props);

    this.iconRevealPassword = React.createRef();

    // DOM INPUT CONTROL BIND
    this.onModalOpen = this.onModalOpen.bind(this);
    this.onModalClose = this.onModalClose.bind(this);
    this.login = this.login.bind(this);
    this.validate = this.validate.bind(this);
    this.validateControl = this.validateControl.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.togglePassword = this.togglePassword.bind(this);
    this.passwordEye = this.passwordEye.bind(this);

    Axios.defaults.withCredentials = true;
  }

  // OPEN/CLOSE MODAL FUNCTIONS
  onModalOpen = () => {
    this.setState({ show: true });
  };

  onModalClose = () => {
    this.setState({ show: false });
  };

  // JOI INPUT VALUE VALIDATION SCHEMA
  schema = Joi.object({
    username: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label("Username"),
    password: Joi.string().required().label("Password"),
  }).options({ abortEarly: false });

  // SEND LOGIN INFORMATION THROUGH API CALL
  login = async () => {
    const { data } = this.state;

    this.setState({ loading: true });

    await Axios.post(loginUser(), {
      username: data.username,
      password: data.password,
    })
      .then((response) => {
        // CHECK IF MEMBER HAS BEEN SUCCESSFULLY AUTHENTICATED
        if (!response.data.auth) {
          // CHECK IF MEMBER IS VERIFIED
          if (response.data.notVerified) {
            this.setState({
              message: response.data.message,
              sendVerifyLink: true,
            });

            this.onModalOpen();
          } else {
            this.setState({
              message: response.data.message,
              sendVerifyLink: false,
            });

            this.onModalOpen();
          }
        } else {
          setToken(response.data.token);
          this.checkAuth(response);
        }
      })
      .catch(() => {
        this.setState({
          message:
            "An error has occurred on our end. " +
            "Please report the issue you are encountering by sending us an email from the contact page. " +
            "We apologize for the inconvenience.",
        });

        this.onModalOpen();
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  checkAuth = (response) => {
    // MEMBER/ADMINISTRATOR INFORMATION
    const users = response.data.result;

    // REDIRECT BASED ON ROLE
    users.forEach((item) => {
      if (item.adminRole && item.status === process.env.REACT_APP_ACTIVE) {
        window.location.replace("/dashboard");
      } else if (item.status === process.env.REACT_APP_ACTIVE) {
        window.location.replace("/");
      } else {
        this.setState({
          message: response.data.message,
        });

        this.onModalOpen();
      }
    });
  };

  // INPUT CONTROLS VALIDATION
  validate = () => {
    const errors = {};
    const { data } = this.state;
    const { error } = this.schema.validate(data);

    if (!error) return null;

    for (let item of error.details) errors[item.path[0]] = item.message;

    return errors;
  };

  // INDIVIDUAL INPUT CONTROL VALUE VALIDATION
  validateControl = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = this.schema.validate(obj, schema);

    if (error) return null;

    return error.details[0].message;
  };

  // HANDLE INPUT CONTROL CHANGES
  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateControl(input);

    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({ data, errors });
  };

  // SUBMIT INPUT CONTROL VALUES
  handleSubmit = (e) => {
    e.preventDefault();
    const errors = this.validate();

    if (!errors) this.login();
    this.setState({ errors: errors || {} });

    if (errors) return null;
  };

  // TOGGLE PASSWORD VISIBILITY BOOLEAN
  togglePassword = () => {
    this.setState({ isRevealPassword: !this.state.isRevealPassword });
  };

  // TOGGLE PASSWORD VISIBILITY FUNCTION
  passwordEye = () => {
    return (
      <span
        onClick={this.togglePassword}
        ref={this.iconRevealPassword}
        className={"floating-custom-eye-icon"}
      >
        {this.state.isRevealPassword ? (
          <FontAwesomeIcon icon={faEye} />
        ) : (
          <FontAwesomeIcon icon={faEyeSlash} />
        )}
      </span>
    );
  };

  render() {
    // STATE VARIABLES DESTRUCTURE
    const {
      data,
      errors,
      show,
      message,
      sendVerifyLink,
      isRevealPassword,
      loading,
    } = this.state;

    // HTML AND JSX RENDER
    return (
      <main className={"login"}>
        <Helmet>
          <html lang="en" />
          <title>Login - Stepout</title>
          <meta name={"description"} content={"Login"} />
        </Helmet>

        <form
          className={"border border-primary shadow-sm login-form"}
          onSubmit={this.handleSubmit}
        >
          <img src={logo} alt="logo.png" />

          <div className="input-wrapper">
            {/*Email Input*/}
            <FloatInput
              label={"Email"}
              type={"email"}
              name={"username"}
              placeholder={"Enter your email"}
              value={data.username}
              onChange={this.handleChange}
              error={errors.username}
            />

            {/*Password Input*/}
            <FloatInput
              label={"Password"}
              type={isRevealPassword ? "text" : "password"}
              name={"password"}
              placeholder={"Enter your password"}
              value={data.password}
              onChange={this.handleChange}
              error={errors.password}
              togglePassword={this.passwordEye()}
            />
          </div>

          {/*Login button*/}
          {loading ? (
            <>
              <button className="btn btn-primary btn-large" disabled>
                <span
                  className="spinner-grow spinner-grow-sm me-2"
                  role="status"
                  aria-hidden="true"
                />
                Logging in...
              </button>
            </>
          ) : (
            <button className="btn btn-primary btn-large">Sign in</button>
          )}

          {/*Register and forgot password link*/}
          <div className="border-top link">
            <div className="link-container">
              <Link to={"/register"} className={"register-link"}>
                Create account
              </Link>
              <i className="bi-dot" />
              <Link to={"/forgot-password"} className={"password-change"}>
                Forgot password
              </Link>
            </div>
          </div>
        </form>

        {/*Modal*/}
        <DisplayModal
          show={show}
          close={this.onModalClose}
          message={message}
          title={"Authentication"}
          link={sendVerifyLink}
          extra={data.username}
        />
      </main>
    );
  }
}

export default Login;
