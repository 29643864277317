require("dotenv").config();

export function emailTest() {
  return process.env.REACT_APP_API_URL + "/send_email";
}

export function redirectTest() {
  return process.env.REACT_APP_API_URL + "/test_redirect";
}

export function getImageTest() {
  return process.env.REACT_APP_API_URL + "/image/get";
}

export function imageTest() {
  return process.env.REACT_APP_API_URL + "/image/test";
}

export function validationTest() {
  return process.env.REACT_APP_API_URL + "/test/test_validation";
}

export function anotherTest() {
  return process.env.REACT_APP_API_URL + "/test/another_test";
}
