require("dotenv").config();

// ACTIVITY GALLERY FUNCTIONS
export function uploadActivityGalleryImages(activityName) {
  return (
    process.env.REACT_APP_API_URL +
    `/gallery/upload_activity_gallery_images/${activityName}`
  );
}

export function updateActivityGalleryImages() {
  return (
    process.env.REACT_APP_API_URL + "/gallery/update_activity_gallery_images"
  );
}

export function getActivityGalleryImagesByID() {
  return (
    process.env.REACT_APP_API_URL + "/gallery/get_activity_gallery_images_by_id"
  );
}

export function changeActivityGalleryImage(id, name) {
  return `/dashboard/activity/change_activity_gallery_image?id=${id}&name=${name}`;
}

// EVENT GALLERY FUNCTIONS
export function getEventGalleryImagesByID() {
  return (
    process.env.REACT_APP_API_URL + "/gallery/get_event_gallery_images_by_id"
  );
}

export function changeEventGalleryImage(id, name) {
  return `/dashboard/event/change_event_gallery_image?id=${id}&name=${name}`;
}

export function uploadEventGallery(eventName) {
  return (
    process.env.REACT_APP_API_URL +
    `/gallery/upload_event_gallery_images/${eventName}`
  );
}

export function updateEventGalleryImages() {
  return process.env.REACT_APP_API_URL + "/gallery/update_event_gallery_image";
}
