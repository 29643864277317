import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import Axios from "axios";
import { format } from "date-fns";
import Joi from "joi";
import { registerAdmin } from "../../../service/auth";
import { adminProfileImgUrl } from "../../../config/config.json";

// IMAGE URL
import adminImg from "../../../assets/img/admin.png";

// RESTful
import {
  getAdminDetail,
  getAdminInfo,
  getAdministrator,
  getRole,
  updateAdminStatus,
  updateRole,
} from "../../../service/admin";

import authHeader from "../../../service/auth-header";

// FONTAWESOME
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

// CONTROLS
import { Input } from "../../../component/common/input";
import { DisplayModal } from "../../../component/modal/modal";
import Paginate from "../../../component/pagination/paginate";

// ADMINISTRATION SECTION
const AdminSection = () => {
  return (
    <div className="admin-section">
      <div className="container">
        <h1 className="display-3">Administrator Section</h1>
        <img src={adminImg} alt={adminImg} className={"img-fluid"} />
      </div>
    </div>
  );
};

// ADD ADMINISTRATOR COMPONENT
const Administration = () => {
  // HOOKS
  const [adminDetails, setAdminDetails] = useState({
    adminName: "",
    adminSurname: "",
    adminEmail: "",
    adminContact: "",
    adminPassword: "",
    adminConfirmPassword: "",
  });

  const [getAdminRole, setGetAdminRole] = useState("");
  const [adminRole, setAdminRole] = useState([]);
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState({});
  const [isRevealPassword, setIsRevealPassword] = useState(false);

  // NAVIGATE HOOK
  const navigate = useNavigate();

  // USE EFFECT
  useEffect(() => {
    // RETRIEVE ADMINISTRATOR ROLE LEVELS THROUGH API CALL
    async function _getAdminRole() {
      await Axios.get(getRole(), {
        headers: authHeader.authHeader(),
      }).then((data) => {
        if (data.data.success) setAdminRole(data.data.result);
      });
    }

    _getAdminRole();
  }, []);

  // OPEN AND CLOSE MODAL FUNCTION
  function onModalOpen() {
    setShow(true);
  }

  function onModalClose() {
    setShow(false);
    if (success) navigate("/dashboard/admin_sec/admin_role");
  }

  // TOGGLE PASSWORD VISIBILITY BOOLEAN
  function togglePassword() {
    setIsRevealPassword(!isRevealPassword);
  }

  // TOGGLE PASSWORD VISIBILITY FUNCTION
  function passwordEye() {
    return (
      <span onClick={togglePassword} className={"custom-eye-icon"}>
        {isRevealPassword ? (
          <FontAwesomeIcon icon={faEye} />
        ) : (
          <FontAwesomeIcon icon={faEyeSlash} />
        )}
      </span>
    );
  }

  // JOI VALIDATION
  const schema = Joi.object({
    adminName: Joi.string().required().label("Name"),
    adminSurname: Joi.string().required().label("Surname"),
    adminEmail: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label("Email"),
    adminContact: Joi.string()
      .length(10)
      .pattern(/^[0-9]+$/)
      .required()
      .label("Contact"),
    adminPassword: Joi.string()
      .required()
      .min(8)
      .label("Password")
      .messages({
        "string.pattern.base":
          "Your password must be at least 8 characters long, contain at least one number and special character, " +
          "and have a mixture of uppercase and lowercase letters.",
        "string.pattern.name":
          "Your password must be at least 8 characters long, contain at least one number and special character, " +
          "and have a mixture of uppercase and lowercase letters.",
        "string.min": "Your password must be at least 8 characters long.",
      }),
    adminConfirmPassword: Joi.any()
      .valid(Joi.ref("adminPassword"))
      .required()
      .messages({ "any.only": "Password must match." }),
  }).options({ abortEarly: false });

  // VALIDATION FUNCTION
  function validate() {
    const { error } = schema.validate(adminDetails);

    if (!error) return null;
    else {
      const errors = {};
      for (let item of error.details) {
        const name = item.path[0];
        const message = item.message;
        errors[name] = message;
      }
      setErrors(errors);
      return errors;
    }
  }

  // CONTROL VALIDATION FUNCTION
  function validateControl(e) {
    const { name, value } = e.target;
    const obj = { [name]: value };
    const inputSchema = { [name]: schema[name] };
    const { error } = schema.validate(obj, inputSchema);

    if (error) return null;
    return error.details[0].message;
  }

  // DROPDOWN INPUT VALUE HANDLER
  function handleChange(e) {
    setGetAdminRole(e.target.value);
  }

  // INPUT CONTROL VALUE HANDLER
  function handleInputChange(e) {
    let { name, value } = e.target;
    let error = { ...errors };
    const errorMessage = validateControl(e);
    if (errorMessage) {
      error[name] = errorMessage;
    } else delete error[name];

    let admin = { ...adminDetails };
    admin[name] = value;
    setAdminDetails(admin);
    setErrors(error);
  }

  // ADD ADMINISTRATOR FUNCTION
  async function addAdministrator(e) {
    e.preventDefault();
    
    let admin = { ...adminDetails };

    let data = {
      adminName: admin.adminName,
      adminSurname: admin.adminSurname,
      adminEmail: admin.adminEmail,
      getAdminRole: getAdminRole,
      adminContact: admin.adminContact,
      adminPassword: admin.adminPassword,
    };

    try {
      await Axios.post(registerAdmin(), data, {
        headers: authHeader.authHeader(),
      })
        .then((register) => {
          if (register.data.success) {
            setSuccess(true);
            setMessage(register.data.message);

            onModalOpen();
          } else {
            setSuccess(false);
            setMessage(register.data.message);

            onModalOpen();
          }
        })
        .catch((error) => {
          setSuccess(false);
          setMessage(
            `An error has occurred while attempting to add a new administrator. ${error.message}.`,
          );

          onModalOpen();
        });
    } catch (error) {
      setMessage(`New administrator could not be added. ${error.message}.`);
      onModalOpen();
    }
  }

  function submitAdminDetails(e) {
    e.preventDefault();
    const errors = validate();
    if (!errors) {
      addAdministrator(e);
    } else {
      console.log(errors);
    }

    setErrors(errors || {});

    if (errors) return;
  }

  // HTML AND JSX RENDER
  return (
    <div className={"add-admin-form"}>
      <div className="container">
        <h1>Add Administrator</h1>

        <form
          encType={"multipart/form-data"}
          onSubmit={(e) => submitAdminDetails(e)}
        >
          <div className="card shadow-sm border-primary">
            <div className="card-body">
              <Input
                label={"Name"}
                type={"text"}
                name={"adminName"}
                placeholder={"Enter administrator's name"}
                onChange={handleInputChange}
                value={adminDetails.adminName}
                error={errors.adminName}
              />

              <Input
                label={"Surname"}
                type={"text"}
                name={"adminSurname"}
                placeholder={"Enter administrator's surname"}
                onChange={handleInputChange}
                value={adminDetails.adminSurname}
                error={errors.adminSurname}
              />

              <div className="form-group role-select">
                <label htmlFor="adminRole">Select Administrator level</label>
                <select
                  name="role"
                  id="adminRole"
                  className="form-select"
                  onChange={handleChange}
                >
                  <option value="">Choose a role</option>
                  {adminRole.map((item) => {
                    return (
                      <option value={item.adminRoleType} key={item.adminRoleID}>
                        {item.adminRoleType}
                      </option>
                    );
                  })}
                </select>
              </div>

              <Input
                label={"Email"}
                type={"email"}
                name={"adminEmail"}
                placeholder={"Enter administrator's email"}
                onChange={handleInputChange}
                value={adminDetails.adminEmail}
                error={errors.adminEmail}
              />

              <Input
                label={"Contact number"}
                type={"tel"}
                name={"adminContact"}
                placeholder={"Enter contact number"}
                onChange={handleInputChange}
                value={adminDetails.adminContact}
                error={errors.adminContact}
              />

              <Input
                label={"Password"}
                type={isRevealPassword ? "text" : "password"}
                name={"adminPassword"}
                placeholder={"Enter password"}
                onChange={handleInputChange}
                value={adminDetails.adminPassword}
                error={errors.adminPassword}
                togglePassword={passwordEye()}
              />

              <Input
                label={"Confirm Password"}
                type={isRevealPassword ? "text" : "password"}
                name={"adminConfirmPassword"}
                placeholder={"Confirm password"}
                onChange={handleInputChange}
                value={adminDetails.adminConfirmPassword}
                error={errors.adminConfirmPassword}
                togglePassword={passwordEye()}
              />

              <div className="text-center mt-4">
                <button className="btn btn-primary shadow-sm" type="submit">
                  Register Administrator
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>

      <DisplayModal
        show={show}
        message={message}
        close={onModalClose}
        title={"Administrator"}
      />
    </div>
  );
};

// DISPLAY LIST OF ADMINISTRATORS COMPONENT
const Role = () => {
  // HOOKS
  const [adminList, setAdminList] = useState([]);
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  // NEW PAGINATION IMPLEMENTATION
  const [activePage, setActivePage] = useState(1);
  const rowsPerPage = 5;
  const [count, setCount] = useState(0);
  const totalPages = Math.ceil(count / rowsPerPage);
  const calculatedRows = adminList.slice(
    (activePage - 1) * rowsPerPage,
    activePage * rowsPerPage,
  );

  // TABLE COLUMNS
  const columnLabel = [
    { label: "" },
    { label: "Name" },
    { label: "Surname" },
    { label: "Admin role level" },
    { label: "Date created" },
    { label: "Status" },
  ];

  // OPEN AND CLOSE MODAL FUNCTION
  function onModalOpen() {
    setShow(true);
  }

  function onModalClose() {
    setShow(false);
  }

  // ADJUST ADMINISTRATOR ACTIVE STATE
  async function adminState(id, name, surname, currentStatus) {
    let status = "";
    let prompt = window.confirm(
      `Do you want to change ${name} ${surname}'s status?`,
    );

    if (prompt) {
      if (currentStatus === "active") {
        status = "inactive";
        setRefresh(false);

        await Axios.put(
          updateAdminStatus(),
          { id, status },
          { headers: authHeader.authHeader() },
        )
          .then((status) => {
            if (status.data.updated) {
              setMessage(`${name} ${surname}'s ` + status.data.message);
              setRefresh(true);
              onModalOpen();
            } else {
              setMessage(`${name} ${surname}'s ` + status.data.message);
              setRefresh(false);
              onModalOpen();
            }
          })
          .catch((error) => {
            setMessage(
              `An error has occurred while updating ${name} ${surname}'s status. ${error.message}.`,
            );
            setRefresh(false);

            onModalOpen();
          });
      }

      if (currentStatus === "inactive") {
        status = "active";
        setRefresh(false);

        await Axios.put(
          updateAdminStatus(),
          { id, status },
          { headers: authHeader.authHeader() },
        )
          .then((status) => {
            if (status.data.updated) {
              setMessage(`${name} ${surname}'s ` + status.data.message);
              setRefresh(true);
              onModalOpen();
            } else {
              setMessage(`${name} ${surname}'s ` + status.data.message);
              setRefresh(false);
              onModalOpen();
            }
          })
          .catch((error) => {
            setMessage(
              `An error has occurred while updating ${name} ${surname}'s status. ${error.message}.`,
            );
            setRefresh(false);
            onModalOpen();
          });
      }
    }
  }

  // USE EFFECT
  useEffect(() => {
    // RETRIEVE ADMINISTRATOR DETAILS THROUGH API CALL
    async function administratorDetails() {
      setLoading(true);

      await Axios.get(getAdministrator(), {
        headers: authHeader.authHeader(),
      })
        .then((admin) => {
          if (admin.data.success) {
            setAdminList(admin.data.result);
            setCount(admin.data.result.length);
          } else {
            setMessage(admin.data.message);
            onModalOpen();
          }
        })
        .catch((error) => {
          setMessage(
            `Administrator information could not be displayed. ${error.message}.`,
          );
          onModalOpen();
        });
    }

    administratorDetails().finally(() => {
      setLoading(false);
    });
  }, [refresh]);

  // HTML AND JSX RENDER
  return (
    <div className="admin-role">
      <div className="container">
        <h1 className={"text-center"}>Edit Role of Administrators</h1>

        <div className="card shadow-sm border-primary">
          <div className="card-body">
            <table className="table table-hover">
              <thead>
                <tr>
                  {columnLabel.map((label, index) => {
                    return <th key={index}>{label.label}</th>;
                  })}
                </tr>
              </thead>

              <tbody>
                {loading && (
                  <tr>
                    <td colSpan={6} className={"text-center"}>
                      <p className={"text-muted"}>
                        Loading administrator details...
                      </p>

                      <div className="admin-spinner">
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}

                {!adminList.length && !loading && (
                  <tr>
                    <td colSpan={6} className={"text-center"}>
                      <p className={"text-muted"}>
                        Administrator details could not be displayed.
                      </p>
                    </td>
                  </tr>
                )}

                {!loading &&
                  calculatedRows.map((list, index) => {
                    return (
                      <tr key={list.adminID}>
                        <td>{index + 1}</td>
                        <td>{list.adminName}</td>
                        <td>{list.adminSurname}</td>
                        <td>{list.adminRole}</td>
                        <td>
                          {format(
                            new Date(list.dateAdded),
                            "yyyy-MM-dd' 'HH:mm",
                          )}
                        </td>
                        <td>{list.status}</td>
                        <td>
                          <div className="btn-group">
                            <a
                              className={"btn btn-sm btn-primary"}
                              href={getAdminDetail(list.adminID)}
                            >
                              Edit role
                            </a>

                            <button
                              onClick={() =>
                                adminState(
                                  list.adminID,
                                  list.adminName,
                                  list.adminSurname,
                                  list.status,
                                )
                              }
                              className={"btn btn-sm btn-outline-primary"}
                            >
                              Change {list.adminName + " " + list.adminSurname}
                              's status
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>

            {/*NEW PAGINATION*/}
            <Paginate
              activePage={activePage}
              count={count}
              rowsPerPage={rowsPerPage}
              totalPages={totalPages}
              setActivePage={setActivePage}
            />
          </div>
        </div>
      </div>

      {/* MODAL */}
      <DisplayModal
        show={show}
        message={message}
        close={onModalClose}
        title={"Administrator"}
      />
    </div>
  );
};

// EDIT ADMINISTRATOR ROLE COMPONENT
const EditRole = () => {
  // HOOKS
  const [adminDetail, setAdminDetail] = useState([]);
  const [adminRole, setAdminRole] = useState([]);
  const [role, setRole] = useState("");
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);

  // NAVIGATE HOOK
  const navigate = useNavigate();

  // ADMINISTRATOR ID
  const { id } = useParams();

  // DROPDOWN CONTROL VALUE HANDLER
  function handleRole(e) {
    setRole(e.target.value);
  }

  // OPEN AND CLOSE MODAL FUNCTION
  function onModalOpen() {
    setShow(true);
  }

  function onModalClose() {
    setShow(true);
    if (success) navigate("/dashboard/admin_sec/admin_role");
  }

  // UPDATE ADMINISTRATOR ROLE
  async function updateAdminRole(e) {
    e.preventDefault();

    await Axios.put(
      updateRole(id),
      { role },
      { headers: authHeader.authHeader() },
    )
      .then((roleUpdate) => {
        if (roleUpdate.data.update) {
          setMessage(roleUpdate.data.message);
          setSuccess(true);
          onModalOpen();
        } else {
          setMessage(roleUpdate.data.message);
          setSuccess(false);
          onModalOpen();
        }
      })
      .catch((error) => {
        setMessage(
          `Administrator role could not be updated. ${error.message}.`,
        );
        setSuccess(false);
        onModalOpen();
      });
  }

  // USE EFFECT
  useEffect(() => {
    // RETRIEVE ADMINISTRATOR DETAILS THROUGH API CALL
    async function adminDetails() {
      // Loading selected administrator details
      await Axios.get(getAdminInfo(id), {
        headers: authHeader.authHeader(),
      })
        .then((adminInfo) => {
          if (adminInfo.data.success) setAdminDetail(adminInfo.data.result);
          else {
            setMessage(adminInfo.data.message);
            onModalOpen();
          }
        })
        .catch((error) => {
          setMessage(
            `Administrator details could not be displayed. ${error.message}.`,
          );

          onModalOpen();
        });
    }

    // RETRIEVE SELECTED ADMINISTRATOR ROLE THROUGH API CALL
    async function getAdminRole() {
      await Axios.get(getRole(), { headers: authHeader.authHeader() })
        .then((role) => {
          if (role.data.success) setAdminRole(role.data.result);
          else {
            setMessage(role.data.message);
            onModalOpen();
          }
        })
        .catch((error) => {
          setMessage(
            `Administrator roles could not be displayed onto the dropdown menu. ${error.message}.`,
          );

          onModalOpen();
        });
    }

    adminDetails();
    getAdminRole();
  }, [id]);

  // HTML AND JSX RENDER
  return (
    <div className="edit-role">
      <div className="container text-center">
        <div className="card shadow-sm border-primary">
          <div className="card-body">
            {adminDetail.map((admin) => {
              return (
                <div key={admin.adminID}>
                  <p>Name: {admin.adminName}</p>
                  <hr />
                  <p>Surname: {admin.adminSurname}</p>
                  <hr />
                  <p>Current administrator role level: {admin.adminRole}</p>

                  <hr />

                  {admin.adminProfileImage === null && (
                    <p className="text-muted">No Profile Picture</p>
                  )}

                  {admin.adminProfileImage === "" && (
                    <p className="text-muted">No Profile Picture</p>
                  )}

                  {admin.adminProfileImage && (
                    <img
                      src={adminProfileImgUrl + admin.adminProfileImage}
                      alt={admin.adminProfileImage}
                      className="img-fluid mb-2 mt-2"
                    />
                  )}

                  <hr />

                  <p className={"text-muted"}>
                    Select a role to update {admin.adminName}'s level of
                    authority
                  </p>
                </div>
              );
            })}

            <select
              name="role"
              id="role"
              className={"form-select"}
              onChange={handleRole}
            >
              <option value="">Choose a role</option>
              {adminRole.map((role) => {
                return (
                  <option value={role.adminRoleType} key={role.adimRoleID}>
                    {role.adminRoleType}
                  </option>
                );
              })}
            </select>

            <button
              className="btn btn-primary shadow-sm"
              onClick={(e) => updateAdminRole(e)}
            >
              Update Administrator Role
            </button>
          </div>
        </div>
      </div>

      <DisplayModal
        show={show}
        message={message}
        close={onModalClose}
        title={"Administrator"}
      />
    </div>
  );
};

export { AdminSection, Administration, Role, EditRole };
