import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { getCurrentUser } from "../../../service/auth";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ChangeStatus from "../../../utils/StatusToggle";

// RESTful
import {
  getEvent,
  getEventById,
  getEventDetail,
  insertEvent,
  updateEvent,
  updateEventImage,
  updateEventStatus,
} from "../../../service/event";

import {
  changeEventGalleryImage,
  getEventGalleryImagesByID,
  updateEventGalleryImages,
  uploadEventGallery,
} from "../../../service/gallery";

import authHeader from "../../../service/auth-header";

// IMAGE URL
import eventImg from "../../../assets/img/planner.png";
import {
  adminEventImgUrl,
  adminGalleryImageUrl,
} from "../../../config/config.json";

// WYSIWYG
import { convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import RichText from "../../../component/common/TextEditor";

// CONTROLS
import Paginate from "../../../component/pagination/paginate";
import { Input } from "../../../component/common/input";
import { DisplayModal } from "../../../component/modal/modal";

// FONTAWESOME
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// EVENT COMPONENT
export const EventMain = () => {
  return (
    <section className={"event-main"}>
      <div className="container">
        <h1 className="display-3">Events Section</h1>
        <img src={eventImg} alt="eventImg" className={"img-fluid"} />
      </div>
    </section>
  );
};

// ADD NEW EVENT COMPONENT
export const Event = () => {
  // GET LOGGED IN ADMIN INFO
  const admin = getCurrentUser();
  const adminID = admin.id;

  // HOOKS
  const [eventName, setEventName] = useState("");
  const [eventDescription, setEventDescription] = useState("");
  const [eventImage, setEventImage] = useState("");
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [imagePreview, setImagePreview] = useState("");

  // USE REF HOOKS
  const inputImageFile = useRef(null);
  const galleryInputImageFiles = useRef(null);

  // USE NAVIGATE HOOK
  const navigate = useNavigate();

  // EVENT GALLERY HOOKS
  const [eventGalleryImage, setEventGalleryImage] = useState([]);
  const [previewEventGalleryImages, setPreviewEventGalleryImages] = useState(
    [],
  );

  // WYSIWYG HOOKS
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [content, setContent] = useState("");

  // OPEN/CLOSE MODAL FUNCTIONS
  const onModalOpen = () => {
    setShow(true);
  };

  const onModalClose = () => {
    setShow(false);
    if (success) navigate("/dashboard/event/view_event");
  };

  // UPLOAD EVENT GALLERY IMAGES
  async function uploadEventGalleryImages(e) {
    e.preventDefault();
    if (eventGalleryImage.length > 0) {
      let formData = new FormData();

      for (let index = 0; index < eventGalleryImage.length; index++) {
        formData.append("event-gallery-img", eventGalleryImage[index]);
      }

      await axios
        .post(uploadEventGallery(eventName.replaceAll(/\s/g, "+")), formData, {
          headers: authHeader.adminMultiHeader(),
        })
        .then((x) => {
          if (!x.data.success) {
            setMessage(x.data.message);
            onModalOpen();
          }
        })
        .catch((error) => {
          setMessage(
            `An error has occurred while attempting to upload event gallery images. ${error.message}.`,
          );
          onModalOpen();
        });
    }
  }

  // ADD A NEW EVENT FUNCTION
  async function addEvent(e) {
    e.preventDefault();

    let formData = new FormData();

    if (eventName === " " || eventDescription === " ") {
      setMessage(
        "Please complete the event entry, you have left out one or more required information.",
      );
      onModalOpen();
    } else {
      formData.append("adminID", adminID);
      formData.append("eventName", eventName);
      formData.append("eventDescription", eventDescription);
      formData.append("event-image", eventImage);

      await axios
        .post(insertEvent(), formData, {
          headers: authHeader.adminMultiHeader(),
        })
        .then((eventData) => {
          if (eventData.data.success) {
            setSuccess(true);
            setMessage(eventData.data.message);
            onModalOpen();

            // EVENT GALLERY UPLOAD
            uploadEventGalleryImages(e);
          } else {
            setSuccess(false);
            setMessage(eventData.data.message);
            onModalOpen();
          }
        })
        .catch((error) => {
          setSuccess(false);
          setMessage(`Could not add event. ${error.message}.`);
          onModalOpen();
        });
    }
  }

  // EVENT IMAGE HANDLER
  function eventImageHandler(e) {
    setEventImage(e.target.files[0]);
    eventImagePreviewHandler(e);
  }

  // ACTIVITY IMAGE PREVIEW FUNCTION
  function eventImagePreviewHandler(e) {
    setImagePreview(URL.createObjectURL(e.target.files[0]));
  }

  // FUNCTION USED TO DISABLE IMAGE INPUT WHEN MORE THAN 3 IMAGES ARE UPLOADED
  function imageCapacity() {
    return eventGalleryImage.length > 4;
  }

  function handleImageRef() {
    inputImageFile.current.click();
  }

  function handleGalleryImageRef() {
    galleryInputImageFiles.current.click();
  }

  // EVENT GALLERY IMAGE HANDLER
  function handleEventGalleryImages(e) {
    setEventGalleryImage([...eventGalleryImage, ...e.target.files]);
  }

  // USE EFFECT
  useEffect(() => {
    if (eventGalleryImage.length < 1) return;

    const newImagesUrls = [];

    eventGalleryImage.forEach((image) => {
      newImagesUrls.push(URL.createObjectURL(image));
    });

    setPreviewEventGalleryImages(newImagesUrls);
  }, [eventGalleryImage]);

  // HTML AND JSX RENDER
  return (
    <section className={"event"}>
      <div className="container">
        <h1>Add upcoming Stepout events</h1>

        <div className="card shadow-sm border-primary mb-5">
          <div className="card-body">
            <form method={"post"} encType={"multipart/form-data"}>
              <div className="row">
                <div className="col-lg-6">
                  <Input
                    label={"Enter event name"}
                    type={"text"}
                    name={"eventName"}
                    placeholder={"Enter the name of the event"}
                    onChange={(e) => setEventName(e.target.value)}
                  />

                  <Editor
                    editorState={editorState}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    spellCheck
                    toolbar={{
                      inline: { inDropdown: true },
                      list: { inDropdown: true },
                      textAlign: { inDropdown: true },
                      link: { inDropdown: true },
                      history: { inDropdown: true },
                    }}
                    onEditorStateChange={(newState) => {
                      setEditorState(newState);
                      setContent(
                        draftToHtml(convertToRaw(newState.getCurrentContent())),
                      );
                      setEventDescription(content);
                    }}
                  />
                </div>

                <div className="col-lg-6">
                  <p className={"text-muted text-center mt-2"}>
                    *Image must not be larger than 2MB
                  </p>

                  {/* BUTTONS */}
                  <button
                    className="btn btn-primary shadow-sm mb-3"
                    type="button"
                    disabled={eventImage}
                    onClick={handleImageRef}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </button>

                  {/* HIDDEN INPUT */}
                  <input
                    id="event-image"
                    name="event-image"
                    type={"file"}
                    accept="image/*"
                    multiple="false"
                    ref={inputImageFile}
                    onChange={(e) => eventImageHandler(e)}
                    style={{ display: "none" }}
                  />

                  {imagePreview.length > 0 ? (
                    <img
                      src={imagePreview}
                      alt={imagePreview}
                      className={"img-fluid mb-3"}
                      onChange={imagePreview}
                    />
                  ) : (
                    <p className={"image-preview-text text-muted"}>
                      Image preview
                    </p>
                  )}

                  <div className="border-bottom border-primary mt-2 mb-4" />

                  {/* BUTTONS */}
                  <button
                    className="btn btn-primary shadow-sm"
                    type="button"
                    disabled={imageCapacity()}
                    onClick={handleGalleryImageRef}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </button>

                  {/* HIDDEN INPUT */}
                  <input
                    id="event-image"
                    name="event-image"
                    type={"file"}
                    accept="image/*"
                    multiple="true"
                    ref={galleryInputImageFiles}
                    onChange={handleEventGalleryImages}
                    style={{ display: "none" }}
                    disabled={imageCapacity()}
                  />

                  <div className="row row-cols-1 row-cols-md-1 rows-col-lg-2 row-cols-xl-2 g-4 mt-2">
                    {previewEventGalleryImages.map((x, index) => {
                      return (
                        <div key={index}>
                          <div className="col">
                            <img src={x} alt={x} className={"img-fluid"} />
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  {previewEventGalleryImages.length === 0 && (
                    <p className={"text-center text-muted mt-2"}>
                      Event image gallery preview
                    </p>
                  )}
                </div>
              </div>

              <div className="mt-4">
                <button
                  className={"btn btn-primary mt-3 shadow-sm"}
                  onClick={addEvent}
                >
                  Add event
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* MODAL */}
      <DisplayModal
        show={show}
        message={message}
        close={onModalClose}
        title={"Event"}
      />
    </section>
  );
};

// VIEW ALL UPLOADED EVENTS COMPONENT
export const ViewEvent = () => {
  // HOOKS
  const [eventData, setEventData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState(false);

  // NEW PAGINATION IMPLEMENTATION
  const [activePage, setActivePage] = useState(1);
  const rowsPerPage = 5;
  const [count, setCount] = useState(0);
  const totalPages = Math.ceil(count / rowsPerPage);
  const calculatedRows = eventData.slice(
    (activePage - 1) * rowsPerPage,
    activePage * rowsPerPage,
  );

  // OPEN/CLOSE MODAL FUNCTIONS
  const onModalOpen = () => {
    setShow(true);
  };

  const onModalClose = () => {
    setShow(false);
  };

  // COLUMN LABELS
  const columnLabel = [{ label: "Name" }, { label: "Status" }];

  // TOGGLE EVENT ACTIVE/INACTIVE STATE
  const eventStatus = async (id, event, currentStatus) => {
    setRefresh(false);

    let status = "";
    let prompt = window.confirm(`Do you want to change ${event}'s status?`);

    if (prompt) {
      if (currentStatus === process.env.REACT_APP_ACTIVE) {
        status = process.env.REACT_APP_INACTIVE;
        await axios
          .put(
            updateEventStatus(),
            { id, status },
            { headers: authHeader.authHeader() },
          )
          .then((eventStatus) => {
            if (eventStatus.data.update) {
              setMessage(eventStatus.data.message);
              setRefresh(true);
              onModalOpen();
            } else {
              setMessage(eventStatus.data.message);
              setRefresh(false);
              onModalOpen();
            }
          })
          .catch((error) => {
            setMessage(
              `${event} status could not be changed. ${error.message}.`,
            );
            setRefresh(false);
            onModalOpen();
          });
      }

      if (currentStatus === process.env.REACT_APP_INACTIVE) {
        status = process.env.REACT_APP_ACTIVE;
        setRefresh(false);

        await axios
          .put(
            updateEventStatus(),
            { id, status },
            { headers: authHeader.authHeader() },
          )
          .then((eventStatus) => {
            if (eventStatus.data.update) {
              setMessage(eventStatus.data.message);
              setRefresh(true);
              onModalOpen();
            } else {
              setMessage(eventStatus.data.message);
              setRefresh(false);
              onModalOpen();
            }
          })
          .catch((error) => {
            setMessage(
              `${event} status could not be changed. ${error.message}.`,
            );
            setRefresh(false);
            onModalOpen();
          });
      }
    }
  };

  // USE EFFECT
  useEffect(() => {
    // RETRIEVE ALL EVENT INFORMATION THROUGH API CALL
    async function event() {
      setLoading(true);

      await axios
        .get(getEvent(), { headers: authHeader.authHeader() })
        .then((eventData) => {
          if (eventData.data.success) {
            setEventData(eventData.data.result);
            setCount(eventData.data.result.length);
          } else {
            setMessage(eventData.data.message);
            onModalOpen();
          }
        })
        .catch((error) => {
          setMessage(`Events could not be loaded. ${error.message}.`);
          onModalOpen();
        });
    }

    event().finally(() => {
      setLoading(false);
    });
  }, [refresh]);

  // HTML AND JSX
  return (
    <section className={"view-event"}>
      <div className="container">
        <h1>Stepout events</h1>

        <div className="card shadow-sm border-primary">
          <div className="card-body">
            <table className="table table-hover">
              <thead>
                <tr>
                  {columnLabel.map((item, index) => {
                    return <th key={index}>{item.label}</th>;
                  })}
                </tr>
              </thead>

              <tbody>
                {loading && (
                  <tr>
                    <td colSpan={7} className={"text-center"}>
                      <p>Loading events...</p>

                      <div className="admin-spinner">
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}

                {!eventData.length && !loading && (
                  <tr>
                    <td colSpan={7} className={"text-center"}>
                      <p>There are no events to view.</p>
                    </td>
                  </tr>
                )}

                {!loading &&
                  calculatedRows.map((eventList, index) => {
                    return (
                      <tr key={index}>
                        <td>{eventList.eventName}</td>
                        <td>{eventList.status}</td>
                        <td>
                          <div className="btn-group text-nowrap">
                            <a
                              href={getEventById(eventList.eventID)}
                              className={"btn btn-sm btn-primary"}
                            >
                              Edit event details
                            </a>

                            <a
                              href={changeEventGalleryImage(
                                eventList.eventID,
                                eventList.eventName.replaceAll(/\s/g, "+"),
                              )}
                              className={"btn btn-sm btn-outline-primary"}
                            >
                              Edit event gallery images
                            </a>

                            <button
                              className={"btn btn-sm btn-outline-primary"}
                              onClick={() =>
                                ChangeStatus(
                                  eventList.eventID,
                                  eventList.eventName,
                                  eventList.status,
                                  updateEventStatus(),
                                  setMessage,
                                  setRefresh,
                                  setShow,
                                )
                              }
                            >
                              Change event status
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>

            {/*NEW PAGINATION*/}
            <Paginate
              activePage={activePage}
              count={count}
              rowsPerPage={rowsPerPage}
              totalPages={totalPages}
              setActivePage={setActivePage}
            />
          </div>
        </div>
      </div>

      {/* MODAL */}
      <DisplayModal
        show={show}
        message={message}
        close={onModalClose}
        title={"Event"}
      />
    </section>
  );
};

// EDIT SELECTED EVENT INFORMATION COMPONENT
export const EditEvent = () => {
  // EVENT ID
  const { id } = useParams();

  // HOOKS
  const [event, setEvent] = useState([]);
  const [eventName, setEventName] = useState("");
  const [eventDescription, setEventDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);

  // USE NAVIGATE HOOK
  const navigate = useNavigate();

  // USE REF HOOK AND FUNCTION
  const eventImageFile = useRef(null);

  function eventImageHandlerRef() {
    eventImageFile.current.click();
  }

  // OPEN/CLOSE MODAL FUNCTIONS
  const onModalOpen = () => {
    setShow(true);
  };

  const onModalClose = () => {
    setShow(false);
    if (success) navigate("/dashboard/event/view_event");
  };

  // UPDATE EVENT INFORMATION
  async function updateEventDetail(e) {
    e.preventDefault();

    await event.forEach((item) => {
      if (item) {
        axios
          .put(
            updateEvent(),
            {
              id: id,
              eventName: eventName === "" ? item.eventName : eventName,
              eventDescription:
                eventDescription === ""
                  ? item.eventDescription
                  : eventDescription,
            },
            { headers: authHeader.authHeader() },
          )
          .then((event) => {
            if (event.data.success) {
              setSuccess(true);
              setMessage(event.data.message);
              onModalOpen();
            } else {
              setMessage(event.data.message);
              setSuccess(false);
              onModalOpen();
            }
          })
          .catch((error) => {
            setSuccess(false);
            setMessage(`Could not update event details. ${error.message}.`);
            onModalOpen();
          });
      }
    });
  }

  // EVENT IMAGE HANDLER AND UPDATER
  async function updateEventImageHandler(e) {
    e.preventDefault();

    const image = e.target.files[0];
    const formData = new FormData();

    formData.append("event-image", image);
    formData.append("id", id);

    await axios
      .put(updateEventImage(), formData, {
        headers: authHeader.adminMultiHeader(),
      })
      .then((event) => {
        if (event.data.success) {
          setMessage(event.data.message);
          setSuccess(true);
          onModalOpen();
        } else {
          e.target.value = "";
          setMessage(event.data.message);
          setSuccess(false);
          onModalOpen();
        }
      })
      .catch((error) => {
        setSuccess(false);
        setMessage(`Event image could not be updated. ${error.message}.`);
        onModalOpen();
      });
  }

  // USE EFFECT
  useEffect(() => {
    async function eventDetail() {
      setLoading(true);

      await axios
        .post(
          getEventDetail(),
          { id: id },
          { headers: authHeader.authHeader() },
        )
        .then((event) => {
          if (event.data.success) {
            setEvent(event.data.result);
          } else {
            setMessage(event.data.message);
            onModalOpen();
          }
        })
        .catch((error) => {
          setMessage(`Event data could not be loaded. ${error.message}.`);
          onModalOpen();
        });
    }

    eventDetail().finally(() => {
      setLoading(false);
    });
  }, [id]);

  // HTML AND JSX RENDER
  return (
    <div className={"edit-event"}>
      <div className="container">
        <h1>Edit event details</h1>

        {loading && (
          <div>
            <p className={"text-muted center"}>Loading event details...</p>

            <div className="spinner">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        )}

        {!event.length && !loading && (
          <p className={"text-muted center"}>
            Event details could not be loaded.
          </p>
        )}

        {!loading &&
          event.map((event) => {
            return (
              <form
                method={"post"}
                encType="multipart/form-data"
                key={event.eventID}
              >
                <div className="card-group">
                  <div className="card shadow-sm border-primary">
                    <div className="card-body">
                      <h3 className={"card-title mt-4 text-center"}>
                        Change {event.eventName} details
                      </h3>

                      <div className="mb-3">
                        <Input
                          label={"Event name"}
                          type={"text"}
                          name={"eventName"}
                          defaultValue={event.eventName}
                          onChange={(e) => setEventName(e.target.value)}
                        />
                      </div>

                      <RichText
                        context={event.eventDescription}
                        setContextValue={setEventDescription}
                      />

                      <div className="text-center">
                        <button
                          className="btn btn-primary shadow-sm"
                          onClick={(e) => updateEventDetail(e)}
                        >
                          Update event
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="card shadow-sm border-primary">
                    <div className="card-body">
                      <h3 className="mt-4 text-center">
                        Change {event.eventName} image
                      </h3>

                      <div className="mt-4">
                        <img
                          src={adminEventImgUrl + event.eventImage}
                          alt={event.eventImage}
                          className={"img-fluid"}
                        />

                        <div className="mt-4">
                          {/* BUTTONS */}
                          <div className="text-center">
                            <p>Update event image</p>
                            <button
                              className="btn btn-primary shadow-sm"
                              type="button"
                              onClick={eventImageHandlerRef}
                            >
                              <FontAwesomeIcon icon={faPlus} />
                            </button>
                          </div>

                          <input
                            type={"file"}
                            name={"event-image"}
                            id={"event-image"}
                            multiple={false}
                            ref={eventImageFile}
                            onChange={(e) => updateEventImageHandler(e)}
                            style={{ display: "none" }}
                          />

                          <p className={"text-muted text-center mt-3"}>
                            *Image must not be larger than 2MB
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            );
          })}
      </div>

      {/* MODAL */}
      <DisplayModal
        show={show}
        message={message}
        close={onModalClose}
        title={"Event"}
      />
    </div>
  );
};

export const EventGalleryImage = () => {
  // HOOKS
  const [eventGalleryImages, setEventGalleryImages] = useState([]);
  const [files, setFiles] = useState([]);
  const [imageURL, setImageURL] = useState([]);
  const [eventGalleryID, setEventGalleryID] = useState("");
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  // USE NAVIGATE HOOK
  const navigate = useNavigate();

  // USE REF HOOKS
  const inputImageFile = useRef(null);
  const updateEventGalleryImage = useRef(null);

  // EVENT ID AND NAME
  const id = searchParams.get("id") || "";
  const name = searchParams.get("name") || "";

  // OPEN AND CLOSE MODAL FUNCTION
  function onModalOpen() {
    setShow(true);
  }

  function onModalClose() {
    setShow(false);
    if (success) navigate("/dashboard/event/view_event");
  }

  function handleEventGallery() {
    inputImageFile.current.click();
  }

  function handleEventGalleryImageUpdate(id) {
    updateEventGalleryImage.current.click();
    setEventGalleryID(id);
  }

  // HANDLE SELECTED IMAGES
  function handleImages(e) {
    setFiles([...files, ...e.target.files]);
  }

  // FUNCTION USED TO DISABLE IMAGE INPUT WHEN MORE THAN 3 IMAGES ARE UPLOADED
  function imageCapacity() {
    const sum = eventGalleryImages.length + files.length;
    if (sum > 4) return true;
    return files.length > 4;
  }

  // DISABLE UPLOAD BUTTON IF THERE ARE NO IMAGES
  function disableUploadButton() {
    return files.length === 0;
  }

  // INSERT NEW EVENT GALLERY IMAGES THROUGH API CALL
  async function insertEventGalleryImages(e) {
    e.preventDefault();

    if (files.length > 0) {
      let formData = new FormData();

      formData.append("eventName", name);
      formData.append("eventID", id);

      for (let index = 0; index < files.length; index++) {
        formData.append("event-gallery-img", files[index]);
      }

      await axios
        .post(uploadEventGallery(name), formData, {
          headers: authHeader.adminMultiHeader(),
        })
        .then((data) => {
          if (data.data.success) {
            setMessage(data.data.message);
            setSuccess(true);
            onModalOpen();
          } else {
            setMessage(data.data.message);
            setSuccess(false);
            onModalOpen();
          }
        })
        .catch((error) => {
          setMessage(
            `An error has occurred while adding a new event gallery images. ${error.message}`,
          );

          onModalOpen();
        });
    }
  }

  // UPDATE EVENT GALLERY IMAGES THROUGH API CALL
  async function updateEventGallery(e) {
    e.preventDefault();

    const image = e.target.files[0];
    const formData = new FormData();

    formData.append("eventID", id);
    formData.append("event-gallery-img", image);
    formData.append("galleryEventID", eventGalleryID);

    await axios
      .put(updateEventGalleryImages(), formData, {
        headers: authHeader.adminMultiHeader(),
      })
      .then((data) => {
        if (data.data.success) {
          setMessage(data.data.message);
          setSuccess(true);
          onModalOpen();
        } else {
          setMessage(data.data.message);
          setSuccess(false);
          onModalOpen();
        }
      })
      .catch((error) => {
        setMessage(
          `An error has occurred while updating event gallery images. ${error.message}`,
        );
        onModalOpen();
      });
  }

  // USE EFFECT
  useEffect(() => {
    // RETRIEVING EVENT GALLERY IMAGES THROUGH API CALL
    async function getEventGalleryImages() {
      await axios
        .post(getEventGalleryImagesByID(), { id: id })
        .then((data) => {
          if (data.data.success) {
            setEventGalleryImages(data.data.result);
          }
        })
        .catch((error) => {
          setMessage(
            `An error has occurred while attempting to load event gallery images. ${error.message}.`,
          );
          onModalOpen();
        });
    }

    getEventGalleryImages();

    if (files.length < 1) return;

    const newImageUrls = [];

    files.forEach((image) => newImageUrls.push(URL.createObjectURL(image)));

    setImageURL(newImageUrls);
  }, [files, id]);

  return (
    <section className="event-gallery">
      <div className="container">
        <h2 className="text-center mb-3">
          Currently used event gallery images
        </h2>

        {/* DISPLAY EVENT GALLERY IMAGES FROM DATABASE */}
        <div
          className={
            "row row-cols-1 row-cols-md-2 rows-col-lg-3 row-cols-xl-3 g-4"
          }
        >
          {eventGalleryImages.length > 0 ? (
            eventGalleryImages.map((x, index) => {
              return (
                <div key={index}>
                  <div className="col">
                    <div className="gallery-wrap">
                      <img
                        src={adminGalleryImageUrl + x.image}
                        alt={x.image}
                        className={"img-fluid"}
                      />

                      <div className="gallery-info">
                        <button
                          className="btn btn-primary btn-sm shadow-sm"
                          onClick={() =>
                            handleEventGalleryImageUpdate(x.galleryEventID)
                          }
                        >
                          Update image
                        </button>

                        {/* HIDDEN INPUT */}
                        <form encType="multipart/form-data">
                          <input
                            type="file"
                            name="event-gallery-img"
                            id="event-gallery-img"
                            ref={updateEventGalleryImage}
                            onChange={(e) => updateEventGallery(e)}
                            accept={"image/*"}
                            multiple={false}
                            style={{ display: "none" }}
                          />
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="col">
              <p className="text-muted">
                This event does not have any gallery images
              </p>
            </div>
          )}
        </div>

        <div className="border-bottom border-primary mt-4" />

        <div className="mt-4">
          <div className="text-center">
            {/* BUTTONS */}
            <button
              className="btn btn-primary shadow-sm me-3"
              onClick={() => handleEventGallery()}
              disabled={imageCapacity()}
            >
              <FontAwesomeIcon icon={faPlus} size="2x" />
            </button>

            {/* HIDDEN INPUT */}
            <input
              type="file"
              name="image"
              id="image"
              ref={inputImageFile}
              multiple={true}
              onChange={handleImages}
              style={{ display: "none" }}
              disabled={imageCapacity()}
            />

            {/* IMAGE PREVIEW */}
            <div className="row row-cols-1 row-cols-md-2 rows-col-lg-3 row-cols-xl-3 g-4 mt-2">
              {imageURL.map((x, index) => {
                return (
                  <div className="mt-3 mb-3" key={index}>
                    <div className="col">
                      <img src={x} alt={x} className={"img-fluid"} />
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="border-bottom border-primary mt-4 mb-3" />

            <button
              className="btn btn-primary shadow-sm"
              onClick={(e) => insertEventGalleryImages(e)}
              disabled={disableUploadButton()}
            >
              {"Upload event gallery images"}
            </button>
          </div>
        </div>

        {/* MODAL */}
        <DisplayModal
          show={show}
          message={message}
          close={onModalClose}
          title={"Event"}
        />
      </div>
    </section>
  );
};
