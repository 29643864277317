import React from "react";

export function Loading() {
  return (
    <section className="loading-page">
      <div className="load-content-container">
        <div className="">
          <div
            className="spinner-grow text-light loading-spinner-grow"
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </section>
  );
}
