require("dotenv").config();

export function getOccupation() {
  return process.env.REACT_APP_API_URL + "/api/get_occupation";
}

export function insertOccupation() {
  return process.env.REACT_APP_API_URL + "/api/add_occupation";
}

export function editOccupation(id) {
  return `/dashboard/occupation/edit_occupation/${id}`;
}

export function getOccupationByID(id) {
  return process.env.REACT_APP_API_URL + `/api/occupation_details/${id}`;
}

export function getMembersByOccupation() {
  return process.env.REACT_APP_API_URL + "/member/get_members_by_occupation";
}

export function _updateOccupation() {
  return process.env.REACT_APP_API_URL + "/api/update_occupation";
}
