require("dotenv").config();

export function getUser() {
  return process.env.REACT_APP_API_URL + "/member/users";
}

export function getNonVerifiedMembers() {
  return process.env.REACT_APP_API_URL + "/member/nonverified_members";
}

export function getSpecificMemberDetails() {
  return process.env.REACT_APP_API_URL + "/member/member_info";
}

export function getUserById(id) {
  return `/dashboard/customers/view_user_detail/${id}`;
}

export function getMember() {
  return process.env.REACT_APP_API_URL + "/member/get_user_details";
}

export function getUserDetail() {
  return process.env.REACT_APP_API_URL + "/member/get_user_details";
}

export function updateUserDetail() {
  return process.env.REACT_APP_API_URL + "/member/update_details";
}

export function updateProfileImage() {
  return process.env.REACT_APP_API_URL + "/member/update_profile_image";
}

export function removeProfileImage() {
  return process.env.REACT_APP_API_URL + "/member/remove_profile_image";
}

export function memberComments(id) {
  return `/dashboard/customer/view_member_comments/${id}`;
}

export function getMemberComments(id) {
  return process.env.REACT_APP_API_URL + `/member_comments/${id}`;
}
