import React, { Component } from "react";
import Axios from "axios";
import axios from "axios";
import Joi from "joi";

// FONTAWESOME
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

// RESTful
import { registerUser } from "../../service/auth";
import { verifyUserAccount } from "../../service/contact";
import { getResidentialArea } from "../../service/ResidentialArea";
import { getOccupation } from "../../service/occupation";

// CONTROLS
import { Input } from "../../component/common/input";
import { DisplayModal } from "../../component/modal/modal";
import { Helmet } from "react-helmet";

// REGISTER CLASS COMPONENT
class Register extends Component {
  // CLASS CONSTRUCTOR
  constructor(props) {
    super(props);

    this.iconRevealPassword = React.createRef();

    // STATE VARIABLE
    this.state = {
      data: {
        name: "",
        surname: "",
        username: "",
        password: "",
        confirm_password: "",
        contact: "",
      },
      option: {
        occupation: "",
        residentialArea: "",
      },
      errors: {},
      show: false,
      message: "",
      loadArea: [],
      loadOccupation: [],
      loading: false,
      isRevealPassword: false,
    };

    // DOM INPUT CONTROL BIND
    this.onModalOpen = this.onModalOpen.bind(this);
    this.onModalClose = this.onModalClose.bind(this);
    this.togglePassword = this.togglePassword.bind(this);
    this.passwordEye = this.passwordEye.bind(this);
    this.register = this.register.bind(this);
    this.validate = this.validate.bind(this);
    this.validateControl = this.validateControl.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
  }

  async _residentialArea() {
    await Axios.get(getResidentialArea())
      .then((data) => {
        switch (data.data.success) {
          case true:
            this.setState({ loadArea: data.data.result });
            break;
          case false:
            this.onModalOpen();
            this.setState({ message: data.data.message });
            break;
          default:
            break;
        }
      })
      .catch(() => {
        /*        this.onModalOpen();
                                                this.setState({
                                                  message:
                                                    "Unable to load residential area. Please report the issue you are encoutering by sending us an email from the Contact page. Sorry for the inconvenience.",
                                                });*/
      });
  }

  async _occupation() {
    await Axios.get(getOccupation())
      .then((data) => {
        switch (data.data.success) {
          case true:
            this.setState({ loadOccupation: data.data.result });
            break;
          case false:
            this.onModalOpen();
            this.setState({ message: data.data.message });
            break;
          default:
            break;
        }
      })
      .catch(() => {
        /*        this.onModalOpen();
                                                this.setState({
                                                  message:
                                                    "Unable to load occupation. Please report the issue you are encountering by sending us an email from the Contact page. Sorry for the inconvenience.",
                                                });*/
      });
  }

  // ON PAGE LOAD COMPONENT
  componentDidMount() {
    // LOADING OCCUPATION
    this._occupation();
    // LOADING RESIDENTIAL AREA
    this._residentialArea();
  }

  // OPEN/CLOSE MODAL FUNCTIONS
  onModalOpen = () => {
    this.setState({ show: true });
  };

  onModalClose = () => {
    this.setState({ show: false });
  };

  // TOGGLE PASSWORD VISIBILITY BOOLEAN
  togglePassword = () => {
    this.setState({ isRevealPassword: !this.state.isRevealPassword });
  };

  // TOGGLE PASSWORD VISIBILITY FUNCTION
  passwordEye = () => {
    return (
      <span
        onClick={this.togglePassword}
        ref={this.iconRevealPassword}
        className={"custom-eye-icon"}
      >
        {this.state.isRevealPassword ? (
          <FontAwesomeIcon icon={faEye} />
        ) : (
          <FontAwesomeIcon icon={faEyeSlash} />
        )}
      </span>
    );
  };

  // JOI INPUT VALUE VALIDATION SCHEMA
  schema = Joi.object({
    name: Joi.string().required().label("Name"),
    surname: Joi.string().required().label("Surname"),
    username: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label("Email"),
    contact: Joi.string()
      .length(10)
      .pattern(/^[0-9]+$/)
      .required()
      .label("Contact"),
    password: Joi.string()
      .required()
      .min(8)
      .label("Password")
      .messages({
        "string.pattern.base":
          "Your password must be at least 8 characters long, contain at least one number and special character, " +
          "and have a mixture of uppercase and lowercase letters.",
        "string.pattern.name":
          "Your password must be at least 8 characters long, contain at least one number and special character, " +
          "and have a mixture of uppercase and lowercase letters.",
        "string.min": "Your password must be at least 8 characters long.",
      }),
    confirm_password: Joi.any()
      .valid(Joi.ref("password"))
      .required()
      .messages({ "any.only": "Password must match." }),
  }).options({ abortEarly: false });

  // POST MEMBER REGISTRATION INFORMATION THROUGH API CALL
  register = async () => {
    let { data, option } = this.state;
    this.setState({ loading: true });

    await Axios.post(registerUser(), {
      name: data.name,
      surname: data.surname,
      username: data.username,
      password: data.password,
      contact: data.contact,
      occupation: option.occupation,
      area: option.residentialArea,
    }).then((response) => {
      if (!response.data.success) {
        this.setState({ message: response.data.message, loading: false });
        this.onModalOpen();
      } else {
        this.setState({ message: response.data.message, loading: false });
        this.onModalOpen();
        axios.post(verifyUserAccount(), { email: data.username });
        window.location.replace("/login");
      }
    });
  };

  // INPUT CONTROLS VALIDATION
  validate = () => {
    const errors = {};
    const { data } = this.state;

    const { error } = this.schema.validate(data);

    if (!error) return null;

    for (let item of error.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  };

  // INDIVIDUAL INPUT CONTROL VALUE VALIDATION
  validateControl = ({ name, value }) => {
    const obj = { [name]: value };
    let schema = { [name]: this.schema[name] };
    const { error } = this.schema.validate(obj, schema);

    if (error) return null;
    return error.details[0].message;
  };

  // HANDLE INPUT CONTROL CHANGES
  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateControl(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({ data, errors });
  };

  // SUBMIT INPUT CONTROL VALUES
  handleSubmit = (e) => {
    e.preventDefault();
    const errors = this.validate();

    if (!errors) this.register();
    this.setState({ errors: errors || {} });

    if (errors) return;
  };

  // HANDLE DROPDOWN LIST VALUE SELECTION
  handleDropdownChange = ({ currentTarget: select }) => {
    const option = { ...this.state.option };
    option[select.name] = select.value;
    this.setState({ option });
  };

  render() {
    // STATE VARIABLE DESTRUCTURE
    const { data, errors, show, message, isRevealPassword, loading } =
      this.state;

    // HTML AND JSX RENDER
    return (
      <main className="register">
        <Helmet>
          <html lang="en" />
          <title>Register - Stepout</title>
          <meta
            name={"description"}
            content={"Become part of the Stepout community"}
          />
        </Helmet>

        <div className="container register-container">
          <h1>Be part of the Stepout community</h1>

          <form
            className={"border border-primary shadow-sm register-form"}
            onSubmit={this.handleSubmit}
          >
            {/*Full name input control*/}
            <Input
              label={"Name"}
              type={"text"}
              name={"name"}
              placeholder={"Enter your full name"}
              value={data.name}
              onChange={this.handleChange}
              error={errors.name}
            />

            {/*Surname input control*/}
            <Input
              label={"Surname"}
              type={"text"}
              name={"surname"}
              placeholder={"Enter your surname"}
              value={data.surname}
              onChange={this.handleChange}
              error={errors.surname}
            />

            {/*Username/Email input control*/}
            <Input
              label={"Username/Email"}
              type={"email"}
              name={"username"}
              placeholder={"Enter your email"}
              value={data.username}
              onChange={this.handleChange}
              error={errors.username}
            />

            {/*Contact number input control*/}
            <Input
              label={"Contact number"}
              type={"tel"}
              name={"contact"}
              placeholder={"Enter your contact number"}
              value={data.contact}
              onChange={this.handleChange}
              error={errors.contact}
            />

            {/*Residential Area*/}
            <div className="mb-3">
              <p className="mb-1">Select where your located at</p>
              <select
                name="residentialArea"
                id="residential-area"
                className="form-select"
                onChange={this.handleDropdownChange}
              >
                <option value="">Choose where you area located at</option>
                {this.state.loadArea.map((item) => {
                  return (
                    <option value={item.areaName} key={item.areaID}>
                      {item.areaName}
                    </option>
                  );
                })}
              </select>
            </div>

            {/*Occupation selection*/}
            <div className="mb-3">
              <p className={"mb-1"}>Select your occupation</p>
              <select
                name={"occupation"}
                id={"occupation"}
                className={"form-select"}
                onChange={this.handleDropdownChange}
              >
                <option value={""}>Choose occupation type</option>
                {this.state.loadOccupation.map((item) => {
                  return (
                    <option
                      value={item.nameOfOccupation}
                      key={item.occupationID}
                    >
                      {item.nameOfOccupation}
                    </option>
                  );
                })}
              </select>
            </div>

            {/*Password input control*/}
            <Input
              label={"Password"}
              type={isRevealPassword ? "text" : "password"}
              name={"password"}
              placeholder={"Enter your password"}
              value={data.password}
              onChange={this.handleChange}
              error={errors.password}
              togglePassword={this.passwordEye()}
            />

            {/*Confirm password input control*/}
            <Input
              label={"Confirm password"}
              type={isRevealPassword ? "text" : "password"}
              name={"confirm_password"}
              placeholder={"Confirm your password"}
              value={data.confirm_password}
              onChange={this.handleChange}
              error={errors.confirm_password}
              togglePassword={this.passwordEye()}
            />

            {/*Submit user details for registration*/}
            {loading ? (
              <button className="btn btn-primary" disabled type="button">
                <span
                  className="spinner-grow spinner-grow-sm me-2"
                  role="status"
                  aria-hidden="true"
                />
                Please wait...
              </button>
            ) : (
              <button className="btn btn-primary btn-large" type={"submit"}>
                Register
              </button>
            )}
          </form>
        </div>

        {/*Modal*/}
        <DisplayModal
          show={show}
          close={this.onModalClose}
          message={message}
          title={"Stepout Registration"}
        />
      </main>
    );
  }
}

export default Register;
