import React, { useEffect, useState } from "react";
import axios from "axios";

// API
import {
  getActiveActivityCount,
  getActiveBlogCount,
  getActiveEventsCount,
  getInactiveActivityCount,
  getInactiveBlogCount,
  getInactiveEventsCount,
  getNonVerifiedMembers,
  getOccupationCount,
  getResidentialAreaCount,
  getVerifiedMembers,
} from "../../../service/analytics";

const Board = () => {
  const [verified, setVerified] = useState("");
  const [nonVerified, setNonVerified] = useState("");
  const [activeActivity, setActiveActivity] = useState("");
  const [inactiveActivity, setInactiveActivity] = useState("");
  const [activeBlog, setActiveBlog] = useState("");
  const [inactiveBlog, setInactiveBlog] = useState("");
  const [activeEvent, setActiveEvent] = useState("");
  const [inactiveEvent, setInactiveEvent] = useState("");
  const [occupationCount, setOccupationCount] = useState("");
  const [areaCount, setAreaCount] = useState("");

  useEffect(() => {
    // FUNCTION DECLARATIONS
    // MEMBERS
    async function verifiedMembers() {
      await axios
        .get(getVerifiedMembers())
        .then((data) => {
          if (data.data) {
            setVerified(data.data[0].verifiedMembers);
          }
        })
        .catch((error) => {});
    }

    async function nonVerifiedMembers() {
      await axios
        .get(getNonVerifiedMembers())
        .then((data) => {
          if (data.data) {
            setNonVerified(data.data[0].verifiedMembers);
          }
        })
        .catch((error) => {});
    }

    // ACTIVITIES
    async function getActiveActivity() {
      await axios
        .get(getActiveActivityCount())
        .then((data) => {
          if (data.data) setActiveActivity(data.data[0].activeActivities);
        })
        .catch((error) => {});
    }

    async function getInactiveActivity() {
      await axios
        .get(getInactiveActivityCount())
        .then((data) => {
          if (data.data) setInactiveActivity(data.data[0].inactiveActivities);
        })
        .catch((error) => {});
    }

    // BLOG
    async function getActiveBlog() {
      await axios
        .get(getActiveBlogCount())
        .then((data) => {
          if (data.data) setActiveBlog(data.data[0].activeBlogs);
        })
        .catch((error) => {});
    }

    async function getInactiveBlog() {
      await axios
        .get(getInactiveBlogCount())
        .then((data) => {
          if (data.data) setInactiveBlog(data.data[0].inactiveBlogs);
        })
        .catch((error) => {});
    }

    // EVENTS
    async function getActiveEvents() {
      await axios
        .get(getActiveEventsCount())
        .then((data) => {
          if (data.data) setActiveEvent(data.data[0].activeEvents);
        })
        .catch((error) => {});
    }

    async function getInactiveEvents() {
      await axios
        .get(getInactiveEventsCount())
        .then((data) => {
          if (data.data) setInactiveEvent(data.data[0].inactiveEvents);
        })
        .catch((error) => {});
    }

    // OCCUPATION
    async function getOccupation() {
      await axios
        .get(getOccupationCount())
        .then((data) => {
          if (data.data) setOccupationCount(data.data[0].numOfOccupations);
        })
        .catch((error) => {});
    }

    // RESIDENTIAL AREA
    async function getArea() {
      await axios
        .get(getResidentialAreaCount())
        .then((data) => {
          if (data.data) setAreaCount(data.data[0].numOfResidentialAreas);
        })
        .catch((error) => {});
    }

    // FUNCTION CALLS
    verifiedMembers();
    nonVerifiedMembers();
    getActiveActivity();
    getInactiveActivity();
    getActiveBlog();
    getInactiveBlog();
    getActiveEvents();
    getInactiveEvents();
    getOccupation();
    getArea();
  }, []);

  return (
    <section className="board">
      <div className="container text-center">
        <h1 className={"display-2"}>Dashboard</h1>

        <div className="card-container">
          <div className="row">
            <div className="col-lg-4 mb-3">
              <div className="card shadow-sm border-primary">
                <div className="card-body">
                  <h5>Registered members</h5>
                  <div className="row">
                    <div className="col-md-6">
                      <p className="text-muted">Verified</p>
                      <p>{verified}</p>
                    </div>
                    <div className="col-md-6">
                      <p className="text-muted">Non-verified</p>
                      <p>{nonVerified}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 mb-3">
              <div className="card shadow-sm border-primary">
                <div className="card-body">
                  <h5>Stepout Activities</h5>
                  <div className="row">
                    <div className="col-md-6">
                      <p className="text-muted">Active</p>
                      <p>{activeActivity}</p>
                    </div>
                    <div className="col-md-6">
                      <p className="text-muted">Inactive</p>
                      <p>{inactiveActivity}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 mb-3">
              <div className="card shadow-sm border-primary">
                <div className="card-body">
                  <h5>Stepout Blogs</h5>
                  <div className="row">
                    <div className="col-md-6">
                      <p className="text-muted">Active</p>
                      <p>{activeBlog}</p>
                    </div>
                    <div className="col-md-6">
                      <p className="text-muted">Inactive</p>
                      <p>{inactiveBlog}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 mb-3">
              <div className="card shadow-sm border-primary">
                <div className="card-body">
                  <h5>Stepout Events</h5>
                  <div className="row">
                    <div className="col-md-6">
                      <p className="text-muted">Active</p>
                      <p>{activeEvent}</p>
                    </div>
                    <div className="col-md-6">
                      <p className="text-muted">Inactive</p>
                      <p>{inactiveEvent}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 mb-3">
              <div className="card shadow-sm border-primary">
                <div className="card-body">
                  <h5>Stepout Occupations</h5>
                  <p className="text-muted">List of occupation stored</p>
                  <p>{occupationCount}</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 mb-3">
              <div className="card shadow-sm border-primary">
                <div className="card-body">
                  <h5>Stepout Residential Areas</h5>
                  <p className="text-muted">List of residential stored</p>
                  <p>{areaCount}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Board;
