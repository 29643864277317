require("dotenv").config();

export function getEvent() {
  return process.env.REACT_APP_API_URL + `/api/get_event`;
}

export function getEventByDate() {
  return process.env.REACT_APP_API_URL + `/api/get_event_by_date`;
}

export function insertEvent() {
  return process.env.REACT_APP_API_URL + `/api/add_event`;
}

export function getEventById(id) {
  return `/dashboard/event/edit_event/${id}`;
}

export function getEventDetail() {
  return process.env.REACT_APP_API_URL + `/api/get_event_details`;
}

export function updateEvent() {
  return process.env.REACT_APP_API_URL + `/api/update_event`;
}

export function updateEventImage() {
  return process.env.REACT_APP_API_URL + `/api/update_event_image`;
}

export function updateEventStatus() {
  return process.env.REACT_APP_API_URL + `/api/update_event_status`;
}
