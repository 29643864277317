// Imports
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Joi from "joi";
import Axios from "axios";

// FONTAWESOME
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

// RESTful
import {
  _changePassword,
  sendPasswordResetLink,
  updatePassword,
} from "../../service/auth";

// CONTROLS
import { Input } from "../../component/common/input";
import { DisplayModal } from "../../component/modal/modal";
import { PageHeader } from "../../component/common/PageHeader";

// PASSWORD RESET COMPONENT
export const ResetPasswordLink = () => {
  // HOOKS
  const [email, setEmail] = useState("");
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  // REDIRECT
  const navigate = useNavigate();

  // OPEN/CLOSE MODAL FUNCTION
  const onModalOpen = () => {
    setShow(true);
  };

  const onModalClose = () => {
    setShow(false);
    if (success) navigate("/");
  };

  // REQUEST PASSWORD RESET LINK THROUGH API CALL
  const passwordResetLink = async (e) => {
    setLoading(true);

    e.preventDefault();

    await Axios.post(sendPasswordResetLink(), { email })
      .then((data) => {
        switch (data.data.success) {
          case true:
            setMessage(data.data.message);
            setSuccess(true);
            setLoading(false);
            onModalOpen();
            break;
          case false:
            setMessage(data.data.message);
            setSuccess(false);
            setLoading(false);
            onModalOpen();
            break;
          default:
            break;
        }
      })
      .catch(() => {
        setMessage("Could not send email. Please try again later.");
        setLoading(false);
        onModalOpen();
      });
  };

  // HTML AND JSX RENDER
  return (
    <div className="forgot-password">
      <PageHeader title={"Forgot Password"} />

      <div className="container">
        <div className="card shadow-sm border-primary">
          <div className="card-body">
            <p className="card-text mb-3">
              Reset your password by submitting your email in the text box
              below. You will receive an email to change your password.
            </p>

            {loading && (
              <>
                <p className={"text-muted text-center"}>Sending email...</p>
                <div className="spinner">
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              </>
            )}

            <form onSubmit={passwordResetLink} className="mt-3">
              <Input
                label={"Please enter your email address"}
                type={"email"}
                name={"email"}
                placeholder={"Enter your email address"}
                onChange={(e) => setEmail(e.target.value)}
              />

              <button className="btn btn-primary shadow-sm" type={"submit"}>
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>

      {/* MODAL */}
      <DisplayModal
        show={show}
        close={onModalClose}
        message={message}
        title={"Password Reset"}
      />
    </div>
  );
};

// NEW PASSWORD CREATION COMPONENT
export const Password = () => {
  // HOOKS
  const [username, setUsername] = useState("");
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [searchParams] = useSearchParams();
  const [errors, setErrors] = useState({});
  const [isData, setIsData] = useState(false);
  const [isRevealPassword, setIsRevealPassword] = useState(false);
  const [success, setSuccess] = useState(false);

  const [passwordDetail, setPasswordDetail] = useState({
    newPassword: "",
    newConfirmPassword: "",
  });

  // REDIRECT
  const navigate = useNavigate();

  // QUERY STRINGS
  const token = searchParams.get("token");
  const id = searchParams.get("id");

  // OPEN/CLOSE MODAL FUNCTION
  const onModalOpen = () => {
    setShow(true);
  };

  const onModalClose = () => {
    setShow(false);
    if (success) navigate("/");
  };

  // TOGGLE PASSWORD VISIBILITY
  const togglePassword = () => {
    setIsRevealPassword(!isRevealPassword);
  };

  // TOGGLE PASSWORD FUNCTION
  const passwordEye = () => {
    return (
      <span onClick={togglePassword} className={"custom-eye-icon"}>
        {isRevealPassword ? (
          <FontAwesomeIcon icon={faEye} />
        ) : (
          <FontAwesomeIcon icon={faEyeSlash} />
        )}
      </span>
    );
  };

  // JOI INPUT VALIDATION SCHEMA
  const schema = Joi.object({
    newPassword: Joi.string()
      .required()
      .min(8)
      .label("Password")
      .messages({
        "string.pattern.base":
          "Your password must be at least 8 characters long, contain at least one number and " +
          "special character, and have a mixture of uppercase and lowercase letters.",
        "string.pattern.name":
          "Your password must be at least 8 characters long, contain at least one number and " +
          "special character, and have a mixture of uppercase and lowercase letters.",
        "string.min":
          "Your password must be at least 8 characters long, contain at least one number and " +
          "special character, and have a mixture of uppercase and lowercase letters.",
      }),
    newConfirmPassword: Joi.any()
      .valid(Joi.ref("newPassword"))
      .required()
      .messages({ "any.only": "Password must match." }),
  }).options({ abortEarly: false });

  // INPUT CONTROLS VALIDATION FUNCTION
  const validate = () => {
    const { error } = schema.validate(passwordDetail);

    if (!error) return null;
    else {
      const errors = {};
      for (let item of error.details) {
        const name = item.path[0];
        const message = item.message;
        errors[name] = message;
      }
      setErrors(errors);
      return errors;
    }
  };

  // INDIVIDUAL INPUT CONTROL VALIDATION FUNCTION
  const validateControl = (e) => {
    const { name, value } = e.target;
    const obj = { [name]: value };
    const joiSchema = { [name]: schema[name] };
    const { error } = schema.validate(obj, joiSchema);

    if (error) return null;
    return error.details[0].message;
  };

  // HANDLE INPUT VALUE CHANGE
  const handleChange = (e) => {
    const { name, value } = e.target;
    let error = { ...errors };
    const errorMessage = validateControl(e);
    if (errorMessage) {
      error[name] = errorMessage;
    } else delete error[name];

    let passwordData = { ...passwordDetail };
    passwordData[name] = value;
    setPasswordDetail(passwordData);
    setErrors(error);
  };

  // SUBMIT INPUT VALUES
  const submit = (e) => {
    e.preventDefault();
    const errors = validate();
    if (!errors) {
      passwordChange();
    }

    setErrors(errors || {});

    if (errors) return null;
  };

  // USE EFFECT
  useEffect(() => {
    // RETRIEVE TEMPORARY DATA THROUGH API CALL
    const passwordReset = async () => {
      await Axios.get(_changePassword(token, id))
        .then((data) => {
          switch (data.data.success) {
            case true:
              setUsername(data.data.email);
              setIsData(true);
              break;
            case false:
              setIsData(false);
              break;
            default:
              break;
          }
        })
        .catch((err) => {
          setMessage(
            "An error has occurred, we apologize for this inconvenience, please try again later." +
              err.message,
          );
          onModalOpen();
        });
    };

    passwordReset();
  }, [id, token]);

  // UPDATE PASSWORD FUNCTION
  const passwordChange = async () => {
    const i = { ...passwordDetail };
    const password = i.newPassword;
    try {
      await Axios.put(updatePassword(), { password, username, id })
        .then((data) => {
          switch (data.data.success) {
            case true:
              setMessage(data.data.message);
              setSuccess(true);
              onModalOpen();
              break;
            case false:
              setMessage(data.data.message);
              setSuccess(false);
              onModalOpen();
              break;
            default:
              break;
          }
        })
        .catch(() => {
          setMessage(
            "An error has occurred, we apologize for this inconvenience, please try again later.",
          );
          onModalOpen();
        });
    } catch (e) {
      return null;
    }
  };

  // HTML AND JSX RENDER
  return (
    <div className="new-password">
      <PageHeader title={"Reset Password - Stepout"} />

      <div className="container">
        <h2>Password Renewal</h2>

        {isData ? (
          <form
            onSubmit={submit}
            className={"border border-primary shadow-sm password-form"}
          >
            {/*Password input control*/}
            <Input
              label={"Enter new password"}
              type={isRevealPassword ? "text" : "password"}
              name={"newPassword"}
              placeholder={"Enter your new password"}
              value={passwordDetail.newPassword}
              onChange={handleChange}
              error={errors.newPassword}
              togglePassword={passwordEye()}
            />

            {/*Confirm password input control*/}
            <Input
              label={"Confirm password"}
              type={isRevealPassword ? "text" : "password"}
              name={"newConfirmPassword"}
              placeholder={"Confirm your new password"}
              value={passwordDetail.newConfirmPassword}
              onChange={handleChange}
              error={errors.newConfirmPassword}
              togglePassword={passwordEye()}
            />

            {/*Submit password update*/}
            <button
              className="btn btn-primary"
              type="submit"
              id={"change-password-btn"}
            >
              Change Password
            </button>
          </form>
        ) : (
          <div className="text-center mt-4">
            <p className="text-muted">
              Password change has been completed or session has expired.
            </p>
          </div>
        )}
      </div>

      {/*Modal*/}
      <DisplayModal
        show={show}
        close={onModalClose}
        message={message}
        title={"Password Reset"}
      />
    </div>
  );
};
