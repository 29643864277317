import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import Axios from "axios";
import DOMPurify from "dompurify";

// BLOG IMAGE HEADER AND COMMENT COMPONENT
import BlogHeader from "./BlogHeader";
import { Comment } from "./comment";

// RESTful
import { getPost } from "../../service/blog";

// TOKEN
import { getCurrentUser } from "../../service/auth";
import { imageUrl } from "../../config/config.json";
import { PageHeader } from "../../component/common/PageHeader";

// BLOG CONTENT COMPONENT
export const VieBlogPost = () => {
  // HOOKS
  let [blogPost, setBlogPost] = useState([]);
  let [loading, setLoading] = useState(false);
  let [blogTitle, setBlogTitle] = useState("");

  // useRef HOOKS
  const canComment = useRef(1);
  const canMemberComment = useRef(null);
  const user = useRef(null);
  user.current = getCurrentUser(); // GET USE INFORMATION THROUGH TOKEN

  // BLOG POST ID
  const { slug } = useParams();

  // CHECK A MEMBER IS LOGGED IN, THEN CHECK IF A MEMBER IS ABLE TO COMMENT
  if (user.current) {
    canMemberComment.current = user.comment === canComment.current;
  }

  // CLEAN DIRTY HTML
  const sanitizer = DOMPurify.sanitize;

  // USE EFFECT
  useEffect(() => {
    // RETRIEVE SELECTED BLOG POST THROUGH API CALL
    const post = async () => {
      setLoading(true);
      await Axios.post(getPost(), { slug: slug })
        .then((post) => {
          switch (post.data.success) {
            case true:
              setBlogPost(post.data.result);
              setBlogTitle(post.data.result[0].blogName);
              setLoading(false);
              break;
            case false:
              setLoading(false);
              break;
            default:
              break;
          }
        })
        .catch(() => {
          return null;
        });
    };

    post().finally(() => {
      setLoading(false);
    });
  }, [slug]);

  // HTML AND JSX RENDER
  return (
    <div className={"blog-post-view"}>
      {/*Loading blog post*/}
      {loading && (
        <div className={"loading-container text-center message-format"}>
          <p>Loading blog post...</p>
          <div className="spinner">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      )}

      {!blogPost.length && !loading && (
        <div className={"loading-container text-center message-format"}>
          <p className={"text-muted loading-text"}>
            Blog post could not be loaded right now...
          </p>
        </div>
      )}

      {!loading &&
        blogPost.map((item) => {
          return (
            <div key={item.slug} className={"container-fluid px-0"}>
              <PageHeader title={item.blogName + " - " + "Stepout"} />

              <BlogHeader
                title={item.blogName}
                image={imageUrl + item.blogImage}
                author={item.adminName + " " + item.adminSurname}
              />

              <div className="container pt-4">
                <div className="p-3 mt-4">
                  <article
                    dangerouslySetInnerHTML={{
                      __html: sanitizer(item.blogPost),
                    }}
                  />
                </div>
              </div>
              {/* COMMENT COMPONENT */}
              <Comment
                blogID={item.blogID}
                blogTitle={blogTitle}
                canComment={canMemberComment.current}
              />
            </div>
          );
        })}
    </div>
  );
};
