require("dotenv").config();

export function getBlog() {
  return process.env.REACT_APP_API_URL + "/blog/get_blog_post";
}

export function getBlogByAdmin() {
  return process.env.REACT_APP_API_URL + `/blog/get_admin_blog_post`;
}

export function addBlogPost() {
  return process.env.REACT_APP_API_URL + "/blog/add_blog_post";
}

export function getPost() {
  return process.env.REACT_APP_API_URL + "/blog/get_post";
}

export function getBlogPostByID(slug) {
  return `/dashboard/blog/edit_blog_post/${slug}`;
}

export function updateBlogPost() {
  return process.env.REACT_APP_API_URL + "/blog/update_blog_post";
}

export function updateBlogImage() {
  return process.env.REACT_APP_API_URL + "/blog/update_blog_image";
}

export function updateBlogStatus() {
  return process.env.REACT_APP_API_URL + `/blog/update_blog_status`;
}

export function like(id) {
  return process.env.REACT_APP_API_URL + `/blog/like_post/${id}`;
}

export function adminLike(id) {
  return process.env.REACT_APP_API_URL + `/blog/admin_like_post/${id}`;
}

export function checkLikes() {
  return process.env.REACT_APP_API_URL + `/blog/check-like`;
}

export function comment() {
  return process.env.REACT_APP_API_URL + "/api/comment";
}

export function admin_comment() {
  return process.env.REACT_APP_API_URL + "/api/admin_comment";
}

export function allComments(id) {
  return process.env.REACT_APP_API_URL + `/all_comments/${id}`;
}

export function commentCount(id) {
  return process.env.REACT_APP_API_URL + `/total_comments/${id}`;
}

export function updateMemberCommentStatus() {
  return process.env.REACT_APP_API_URL + `/comment_status`;
}

export function updateAdminCommentStatus() {
  return process.env.REACT_APP_API_URL + `/admin_comment_status`;
}
