import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import axios from "axios";
import {format} from "date-fns";
import {getMemberComments} from "../../../service/member";
import {DisplayModal} from "../../../component/modal/modal";

// MEMBER COMMENTS COMPONENT
export const MmeberComments = () => {
  const [memberComments, setMemberComments] = useState([]);
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  // MEMBER ID
  const { id } = useParams();

  // OPEN/CLOSE MODAL FUNCTIONS
  function onModalOpen() {
    setShow(true);
  }

  function onModalClose() {
    setShow(false);
  }

  // USE EFFECT
  useEffect(() => {
    // RETRIEVE ALL MEMBER COMMENTS THROUGH API CALL
    async function allMemberComments() {
      setLoading(true);

      await axios
        .get(getMemberComments(id))
        .then((data) => {
          if (data.data.success) {
            setMemberComments(data.data.result);
            setName(data.data.result[0].name);
            setSurname(data.data.result[0].surname);
            setLoading(false);
          } else {
            setMessage(data.data.message);

            onModalOpen();
          }
        })
        .catch((error) => {
          setLoading(false);
          setMessage(
            `Comments could not be loaded right now. ${error.message}.`
          );

          onModalOpen();
        });
    }

    allMemberComments();
  }, [id]);

  // HTML AND JSX RENDER
  return (
    <div className="container">
      <div className="card shadow-sm border-primary">
        <div className="card-header">
          <h5 className="text-center">
            Comments made by {name + " " + surname}
          </h5>
        </div>
        <div className="card-body">
          {loading && (
            <>
              <p className="text-muted text-center">Loading comments...</p>
              <div className="admin-spinner">
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </>
          )}

          {!loading && memberComments.length === 0 && (
            <p className="text-muted text-center">No comments were made.</p>
          )}

          {memberComments.map((x) => {
            return (
              <div key={x.commentID}>
                <p className="text-muted mb-0">{"Post title: " + x.title}</p>

                <p className="text-muted mb-0">
                  {"Comment posted on: " +
                    format(new Date(x.dateOfComment), "yyyy-MM-dd' 'HH:mm")}
                </p>

                <p className="text-muted">{"Comment status: " + x.status}</p>

                <p>{x.commentText}</p>

                <hr />
              </div>
            );
          })}
        </div>
      </div>

      {/* MODAL */}
      <DisplayModal
        show={show}
        close={onModalClose}
        message={message}
        title={"Member comments"}
      />
    </div>
  );
};
