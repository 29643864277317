import React, { Component, lazy, Suspense } from "react";

import { Route, Routes } from "react-router-dom";

import { Loading } from "./component/loading/LoadingPage";

// ADMIN ABOUT COMPONENT
import { AdminAbout, SetAbout } from "./views/administrator/pages/about";

// ADMINISTRATOR COMPONENTS
import Board from "./views/administrator/pages/board";

// ADMIN MANAGEMENT COMPONENTS
import {
  Administration,
  AdminSection,
  EditRole,
  Role,
} from "./views/administrator/pages/administration";

// ADMIN ACTIVITY COMPONENTS
import {
  Act,
  ActivityGallery,
  AddActivity,
  EditActivity,
  ViewActivity,
} from "./views/administrator/pages/act";

// BLOG COMPONENTS
//import Blog from "./component/blog/blog";
import { VieBlogPost } from "./views/blog/BlogPostView";

// ADMIN BLOG COMPONENTS
import {
  AddNewBlogPost,
  AdminBlog,
  BlogComment,
  EditBlogPost,
  ViewBlogPost,
} from "./views/administrator/pages/blog";

// ADMIN EVENT COMPONENTS
import {
  EditEvent,
  Event,
  EventGalleryImage,
  EventMain,
  ViewEvent,
} from "./views/administrator/pages/event";

// HEADER AND FOOTER COMPONENTS
import Header from "./component/navigation/header";
import Footer from "./component/navigation/footer";

// HERO COMPONENTS
import MainHero from "./views/hero/hero";

// ADMIN HERO COMPONENTS
import { AdminHero, UpdateHeroImage } from "./views/administrator/pages/hero";

// MEMBER COMPONENT
import { MmeberComments } from "./views/administrator/pages/MemberComment";

// LOGIN COMPONENT
import Login from "./views/access/login";

// REGISTER COMPONENT
import Register from "./views/access/register";

// ADMIN MEMBER MANAGEMENT COMPONENT
import {
  Member,
  MemberHomePage,
  NonVerifiedMembers,
  ViewUserDetails,
} from "./views/administrator/pages/member";

// VALIDATION COMPONENT
import Validate from "./views/access/validate";

// OCCUPATION COMPONENTS
import {
  AddOccupation,
  EditOccupation,
  Occupation,
  ViewOccupation,
} from "./views/administrator/pages/occupation";

// PASSWORD COMPONENTS
import { Password, ResetPasswordLink } from "./views/access/ForgotPassword";

// ADMIN RESIDENTIAL AREA COMPONENTS
import {
  AddResidentialArea,
  EditResidentialArea,
  ResidentialArea,
  ViewResidentialArea,
} from "./views/administrator/pages/ResidentialArea";

// 404 COMPONENT
import NotFound from "./component/404/notfound";

// TOKEN
import { getCurrentUser } from "./service/auth";
import Test from "./views/testing/query";
import RichText from "./views/testing/richtext";

// TESTING COMPONENTS

// .ENV
require("dotenv").config();

// LAZY LOADING CODE SPLITTING SECTION
const About = lazy(() => import("./views/about/about"));

const Activity = lazy(() => import("./views/activity/activity"));

const Blog = lazy(() => import("./views/blog/blog"));

const Dashboard = lazy(() => import("./views/administrator/dashboard"));

const UserEvent = lazy(() => import("./views/event/Event"));

const Profile = lazy(() => import("./views/profile/profile"));

const ContactForm = lazy(() => import("./views/contact/contact"));

const Verify = lazy(() => import("./views/access/verify"));

class App extends Component {
  constructor(props) {
    super(props);

    //this.logOut = this.logOut.bind(this);

    this.state = {
      name: "",
      image: "",
      loading: false,
    };
  }

  componentDidMount() {
    // LOAD INFORMATION OF SPECIFIC ROLES WHEN A LOGIN REQUEST HAS BEEN MADE
    try {
      const user = getCurrentUser();
      const info = user.role;
      const status = user.status;

      this.setState({ user, status, info });
    } catch (e) {
      return {};
    }
  }

  AccessComponent = (user) => {
    return (
      <>
        {!user ? (
          <>
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/register" element={<Register />} />
          </>
        ) : (
          <Route path="*" element={<NotFound />} />
        )}
        <Route exact path={"/validate"} element={<Validate />} />
        <Route exact path={"/verify"} element={<Verify />} />
      </>
    );
  };

  AdministrationComponent = () => {
    const _About = () => {
      return (
        <>
          <Route
            exact
            path={"/dashboard/admin-about"}
            element={<AdminAbout />}
          />
          <Route
            exact
            path={"/dashboard/about/edit-about"}
            element={<SetAbout />}
          />
        </>
      );
    };

    const _Activity = () => {
      return (
        <>
          <Route exact path="/dashboard/act" element={<Act />} />
          <Route
            exact
            path="/dashboard/activity/add_new_activity"
            element={<AddActivity />}
          />
          <Route
            exact
            path="/dashboard/activity/view_activity"
            element={<ViewActivity />}
          />
          <Route
            exact
            path="/dashboard/activity/edit_activity/:id"
            element={<EditActivity />}
          />
          <Route
            exact
            path="/dashboard/activity/change_activity_gallery_image"
            element={<ActivityGallery />}
          />
        </>
      );
    };

    const _Administrator = () => {
      return (
        <>
          <Route
            exact
            path={"/dashboard/admin_sec"}
            element={<AdminSection />}
          />
          <Route
            exact
            path={"/dashboard/admin_sec/add_admin"}
            element={<Administration />}
          />
          <Route
            exact
            path={"/dashboard/admin_sec/admin_role"}
            element={<Role />}
          />
          <Route
            exact
            path={"/dashboard/get_admin/:id"}
            element={<EditRole />}
          />
        </>
      );
    };

    const _Blog = () => {
      return (
        <>
          <Route exact path="/dashboard/blog" element={<AdminBlog />} />
          <Route
            exact
            path="/dashboard/blog/add_new_blog_post"
            element={<AddNewBlogPost />}
          />
          <Route
            exact
            path="/dashboard/blog/view_blog_post"
            element={<ViewBlogPost />}
          />
          <Route
            exact
            path="/dashboard/blog/edit_blog_post/:slug"
            element={<EditBlogPost />}
          />
          <Route
            exact
            path="/dashboard/blog/blog_comment/:id"
            element={<BlogComment />}
          />
        </>
      );
    };

    const _Dashboard = () => {
      return <Route path="/dashboard" element={<Board />} />;
    };

    const _Event = () => {
      return (
        <>
          <Route exact path="/dashboard/event" element={<EventMain />} />
          <Route exact path="/dashboard/event/add_event" element={<Event />} />
          <Route
            exact
            path="/dashboard/event/view_event"
            element={<ViewEvent />}
          />
          <Route
            exact
            path="/dashboard/event/edit_event/:id"
            element={<EditEvent />}
          />
          <Route
            exact
            path="/dashboard/event/change_event_gallery_image"
            element={<EventGalleryImage />}
          />
        </>
      );
    };

    const _Hero = () => {
      return (
        <>
          <Route exact path="/dashboard/hero" element={<AdminHero />} />
          <Route
            exact
            path="/dashboard/hero/update_hero_image"
            element={<UpdateHeroImage />}
          />
        </>
      );
    };

    const _Member = () => {
      return (
        <>
          <Route exact path="/dashboard/members" element={<MemberHomePage />} />
          <Route
            exact
            path="/dashboard/members/verified_members"
            element={<Member />}
          />
          <Route
            exact
            path="/dashboard/members/non_verified_members"
            element={<NonVerifiedMembers />}
          />
          <Route
            exact
            path="/dashboard/customers/view_user_detail/:id"
            element={<ViewUserDetails />}
          />
          <Route
            exact
            path="/dashboard/customer/view_member_comments/:id"
            element={<MmeberComments />}
          />
        </>
      );
    };

    const _Occupation = () => {
      return (
        <>
          <Route exact path="/dashboard/occupation" element={<Occupation />} />
          <Route
            exact
            path="/dashboard/occupation/add_occupation"
            element={<AddOccupation />}
          />
          <Route
            exact
            path="/dashboard/occupation/view_occupation"
            element={<ViewOccupation />}
          />
          <Route
            exact
            path="/dashboard/occupation/edit_occupation/:id"
            element={<EditOccupation />}
          />
        </>
      );
    };

    const _ResidentialArea = () => {
      return (
        <>
          <Route
            exact
            path="/dashboard/residential_area"
            element={<ResidentialArea />}
          />
          <Route
            exact
            path="/dashboard/residential_area/add_residential_area"
            element={<AddResidentialArea />}
          />
          <Route
            exact
            path="/dashboard/residential_area/view_residential_area"
            element={<ViewResidentialArea />}
          />
          <Route
            exact
            path="/dashboard/residential_area/edit_residential_area/:id"
            element={<EditResidentialArea />}
          />
        </>
      );
    };

    return (
      <>
        <Route exact path={"/dashboard"} element={<Dashboard />}>
          {/* ABOUT SECTION */}
          {_About()}

          {/* ACTIVITY SECTION */}
          {_Activity()}

          {/* ADMINISTRATOR SECTION */}
          {_Administrator()}

          {/* BLOG SECTION */}
          {_Blog()}

          {/* DASHBOARD */}
          {_Dashboard()}

          {/* EVENT SECTION */}
          {_Event()}

          {/* HERO SECTION */}
          {_Hero()}

          {/* MEMBER SECTION */}
          {_Member()}

          {/* OCCUPATION SECTION */}
          {_Occupation()}

          {/* RESIDENTIAL AREA SECTION */}
          {_ResidentialArea()}
        </Route>
      </>
    );
  };

  BlogComponent = (user) => {
    return (
      <>
        <Route exact path="/blog" element={<Blog user={user} />} />
        <Route exaxt path={"/blog/blog_post/:slug"} element={<VieBlogPost />} />
      </>
    );
  };

  MainComponent = (user, info) => {
    return (
      <>
        <Route exact path="/" element={<MainHero />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/activity" element={<Activity />} />
        <Route exact path="/contact" element={<ContactForm />} />
        <Route exact path="/events" element={<UserEvent />} />
        {user && (
          <Route
            exact
            path="/profile"
            element={<Profile details={user} id={user.id} role={info} />}
          />
        )}
      </>
    );
  };

  NotFoundComponent = () => {
    return (
      <>
        <Route path="*" element={<NotFound />} />
      </>
    );
  };

  PasswordComponent = () => {
    return (
      <>
        <Route exact path={"/change-password"} element={<Password />} />
        <Route
          exact
          path={"/forgot-password"}
          element={<ResetPasswordLink />}
        />
      </>
    );
  };

  TestingComponent = () => {
    return (
      <>
        <Route exact path={"/rich"} element={<RichText />} />
        <Route path={"/test"} element={<Test />} />
      </>
    );
  };

  render() {
    const { user, info } = this.state;

    return (
      <>
        <div id={"root"}>
          <Header />
          <Suspense fallback={<Loading />}>
            <Routes>
              {/* ADMINISTRATOR ROUTES */}
              {this.AdministrationComponent()}

              {/* AUTHENTICATION */}
              {this.AccessComponent(user)}

              {/* BLOG */}
              {this.BlogComponent(user)}

              {/* Main */}
              {this.MainComponent(user, info)}

              {this.NotFoundComponent()}

              {/* PASSWORD RESET */}
              {this.PasswordComponent()}

              {/* TESTING PAGES */}
              {this.TestingComponent()}
            </Routes>
          </Suspense>
          <Footer />
        </div>
      </>
    );
  }
}

export default App;
