import React, {Component} from "react";
import Joi from "joi";
import {Input} from "../../component/common/input";
import {PageHeader} from "../../component/common/PageHeader";

// VALIDATION CLASS COMPONENT
class Validate extends Component {
  // STATE
  state = {
    data: {
      username: "",
      password: "",
      confirm_password: "",
    },
    errors: {},
  };

  // JOI SCHEMA
  schema = Joi.object({
    username: Joi.string()
      .email({ minDomainSegments: 2, tlds: { allow: ["com", "net"] } })
      .required()
      .label("Username"),
    password: Joi.string().required().min(8).label("Password"),
    confirm_password: Joi.ref("password"),
  }).options({ abortEarly: false });

  // VALIDATION
  validate = () => {
    const errors = {};
    const { data } = this.state;

    const { error } = this.schema.validate(data);
    console.log("Validate method message: ", error);

    if (!error) return null;

    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  // INPUT CONTROL VALIDATION
  validateControl = ({ name, value }) => {
    const object = { [name]: value };
    let schema = { [name]: this.schema[name] };
    const { error } = this.schema.validate(object, schema);
    console.log("Validate control error:", error);

    // Issue starts here
    if (error) return null;
    console.log("Display error: ", error.details[0].message);
    return error.details[0].message;
  };

  // INPUT CONTROL HANDLER
  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateControl(input);
    //console.log("Another check", this.validateControl(input));
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({ data, errors });
  };

  // SUBMIT HANDLER
  handleSubmit = (e) => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });

    if (errors) return;
    /*    window.alert("Submitted");*/
  };

  render() {
    // STATE VARIABLES
    const { errors, data } = this.state;

    // HTML AND JSX RENDER
    return (
      <div>
        <div className="container">
          <PageHeader title={"Account Validation - Stepout"} />

          <h1>Validation</h1>
          <form action="" onSubmit={this.handleSubmit}>
            <div className="mb-3">
              <Input
                label={"Username"}
                type={"email"}
                value={data.username}
                name={"username"}
                onChange={this.handleChange}
                error={errors.username}
              />
            </div>
            <div className="mb-3">
              <Input
                label={"Password"}
                type={"password"}
                value={data.password}
                name={"password"}
                onChange={this.handleChange}
                error={errors.password}
              />
            </div>
            <div className="mb-3">
              <Input
                label={"Confirm password"}
                type={"password"}
                name={"confirm_password"}
                onChange={this.handleChange}
                error={errors.confirm_password}
              />
            </div>
            <div className="mb-3">
              <button className="btn btn-primary" type={"submit"}>
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default Validate;
