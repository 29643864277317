import React from "react";

const currentYear = () => {
  return new Date().getFullYear();
};

const Footer = () => {
  return (
    <div className={"footer bg-dark border-primary"}>
      <div className="container text-white text-center d-flex justify-content-center">
        <div>
          <strong className={"text-muted"}>
            &copy; 2018 - {currentYear()} StepoutSA. All Rights Reserved
          </strong>
        </div>
      </div>
    </div>
  );
};

export default Footer;
