import React, { useEffect, useState } from "react";
import axios from "axios";
import { PageHeader } from "../../component/common/PageHeader";

// IMAGE URL
import { heroImageUrl } from "../../config/config.json";

// RESTful
import { getHeroImage } from "../../service/hero";

// STYLING HERO IMAGE
const HeroImage = ({ image }) => {
  let heroStyle = {
    backgroundColor: "#eca776",
    background:
      "linear-gradient(rgba(36, 20, 38, 0.5), rgba(36, 39, 38, 0.5)), url(" +
      image +
      ") no-repeat center center",
    WebkitBackgroundSize: "cover",
    MozBackgroundSize: "cover",
    OBackgroundSize: "cover",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: "100%",
    width: "100%",
  };

  // HTML AND JSX RENDER
  return (
    <div style={heroStyle} className={"hero-content"}>
      <PageHeader
        title={"Stepout"}
        name={"description"}
        description={"Create Your Own Gqeberha Experience"}
      />

      <div className="hero-text">
        <h1 className="main-text">Welcome To STEPOUT</h1>
        <h2 className="sub-text">Create Your Own Gqeberha Experience</h2>
      </div>
    </div>
  );
};

// HERO COMPONENT
const MainHero = () => {
  // HOOKS
  const [heroImage, setHeroImage] = useState([]);

  // USE EFFECT
  useEffect(() => {
    // RETRIEVE HERO IMAGE THROUGH API CALL
    const Hero = async () => {
      await axios
        .get(getHeroImage())
        .then((x) => {
          if (x.data.success) setHeroImage(x.data.result);
        })
        .catch(() => {
          return 0;
        });
    };

    Hero();
  }, []);

  // HTML AND JSX RENDER
  return (
    <div className="hero">
      {heroImage.map((x, index) => {
        return <HeroImage image={heroImageUrl + x.heroImage} key={index} />;
      })}
    </div>
  );
};

export default MainHero;
