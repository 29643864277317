import axios from "axios";
import authHeader from "../service/auth-header";

// .ENV
require("dotenv").config();

async function UpdateState(
  id,
  name,
  currentStatus,
  api,
  setMessage,
  setRefresh,
  setShow,
  status
) {
  await axios
    .put(
      api,
      {
        id,
        status,
      },
      { headers: authHeader.authHeader() }
    )
    .then((status) => {
      if (status.data.success) {
        setMessage(`${name} ` + status.data.message);
        setRefresh(true);
        setShow(true);
      } else {
        setMessage(`${name} ` + status.data.message);
        setShow(true);
      }
    })
    .catch((error) => {
      setMessage(`${name} status could not be changed. ${error.message}.`);
      setShow(true);
    });
}

export default async function ChangeStatus(
  id,
  name,
  currentStatus,
  api,
  setMessage,
  setRefresh,
  setShow
) {
  setRefresh(false);

  let prompt = window.confirm(
    `Are you sure you want to change ${name}'s status?`
  );
  let status = "";

  if (prompt) {
    switch (currentStatus) {
      case process.env.REACT_APP_ACTIVE:
        status = process.env.REACT_APP_INACTIVE;
        await UpdateState(
          id,
          name,
          currentStatus,
          api,
          setMessage,
          setRefresh,
          setShow,
          status
        );
        break;

      case process.env.REACT_APP_INACTIVE:
        status = process.env.REACT_APP_ACTIVE;
        await UpdateState(
          id,
          name,
          currentStatus,
          api,
          setMessage,
          setRefresh,
          setShow,
          status
        );
        break;
      default:
        break;
    }
  }
}
