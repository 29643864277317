import React, {useEffect, useState} from "react";
import axios from "axios";
import {useNavigate, useParams} from "react-router-dom";

import authHeader from "../../../service/auth-header";

// CONTROLS
import {Input} from "../../../component/common/input";
import {DisplayModal} from "../../../component/modal/modal";
import Paginate from "../../../component/pagination/paginate";

// IMAGE URL
import resImg from "../../../assets/img/residential.png";

// RESTful
import {
  _updateResidentialArea,
  editResidentialArea,
  getResidentialArea,
  getResidentialAreaByID,
  insertResidentialArea,
} from "../../../service/ResidentialArea";

// RESIDENTIAL AREA COMPONENT
export const ResidentialArea = () => {
  return (
    <section className="residential-area">
      <div className="container">
        <h1 className="display-3">Residential Area</h1>
        <img src={resImg} alt="res.png" className="img-fluid" />
      </div>
    </section>
  );
};

// ADD NEW RESIDENTIAL AREA COMPONENT
export const AddResidentialArea = () => {
  // HOOKS
  const [area, setArea] = useState("");
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);

  // USE NAVIGATE HOOK
  const navigate = useNavigate();

  // OPEN/CLOSE MODAL FUNCTIONS
  function onModalOpen() {
    setShow(true);
  }

  function onModalClose() {
    setShow(false);
    if (success) navigate("/dashboard/residential_area/view_residential_area");
  }

  // ADD NEW RESIDENTIAL AREA FUNCTION
  async function addResidentialArea(e) {
    e.preventDefault();

    await axios
      .post(
        insertResidentialArea(),
        { area },
        { headers: authHeader.authHeader() }
      )
      .then((areaData) => {
        if (areaData.data.success) {
          setSuccess(true);
          setMessage(areaData.data.message);

          onModalOpen();
        } else {
          setSuccess(false);
          setMessage(areaData.data.message);

          onModalOpen();
        }
      })
      .catch((error) => {
        setSuccess(false);
        setMessage(`${area} could not be added. ${error.message}.`);

        onModalOpen();
      });
  }

  // HTML AND JSX RENDER
  return (
    <section className="add-residential-area">
      <div className="container">
        <h1>Add residential area</h1>

        <div className="card border border-primary">
          <div className="card-body">
            <form>
              <Input
                label={"Residential Area"}
                type={"text"}
                name={"residential-area"}
                placeholder={"Enter residential area name e.g Humewood"}
                onChange={(e) => setArea(e.target.value)}
              />

              <div className="text-center">
                <button
                  className="btn btn-primary shadow-sm"
                  onClick={(e) => addResidentialArea(e)}
                >
                  Add residential area
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* MODAL */}
      <DisplayModal
        show={show}
        close={onModalClose}
        message={message}
        title={"Residential Area"}
      />
    </section>
  );
};

// VIEW UPLOADED RESIDENTIAL AREA COMPONENTS
export const ViewResidentialArea = () => {
  // HOOKS
  const [residentialAreaData, setResidentialAreaData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");

  // TABLE COLUMNS
  const columnLabel = [{ label: "Occupation" }];

  // NEW PAGINATION IMPLEMENTATION
  const [activePage, setActivePage] = useState(1);
  const rowsPerPage = 5;
  const [count, setCount] = useState(0);
  const totalPages = Math.ceil(count / rowsPerPage);
  const calculatedRows = residentialAreaData.slice(
    (activePage - 1) * rowsPerPage,
    activePage * rowsPerPage
  );

  // OPEN/CLOSE MODAL FUNCTIONS
  function onModalOpen() {
    setShow(true);
  }

  function onModalClose() {
    setShow(false);
  }

  // USE EFFECT
  useEffect(() => {
    // RETRIEVE ALL RESIDENTIAL AREA THROUGH API CALL
    async function residentialArea() {
      setLoading(true);

      await axios
        .get(getResidentialArea())
        .then((resAreaData) => {
          if (resAreaData.data.success) {
            setResidentialAreaData(resAreaData.data.result);
            setCount(resAreaData.data.result.length);
            setLoading(false);
          } else {
            setLoading(false);
            setMessage(resAreaData.data.message);

            onModalOpen();
          }
        })
        .catch((error) => {
          setLoading(false);
          setMessage(
            `Could not get residential area information. ${error.message}.`
          );

          onModalOpen();
        });
    }

    residentialArea();
  }, []);

  // HTML AND JSX RENDER
  return (
    <section className="view-residential-area">
      <div className="container">
        <h1>View Residential Area</h1>

        <div className="card shadow-sm border-primary">
          <div className="card-body">
            <table className="table table-hover">
              <thead>
                <tr>
                  {columnLabel.map((item, index) => {
                    return <th key={index}>{item.label}</th>;
                  })}
                </tr>
              </thead>

              <tbody>
                {loading && (
                  <td colSpan={3} className={"text-center"}>
                    <p className="text-muted">Loading residential areas...</p>

                    <div className="admin-spinner">
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </td>
                )}

                {!residentialAreaData.length && !loading && (
                  <tr>
                    <td colSpan={3} className={"text-center"}>
                      <p className="text-muted">
                        There are no residential areas to view.
                      </p>
                    </td>
                  </tr>
                )}

                {!loading &&
                  calculatedRows.map((resAreaData, index) => {
                    return (
                      <tr key={resAreaData.areaID}>
                        <td>{resAreaData.areaName}</td>
                        <td>
                          <a
                            href={editResidentialArea(resAreaData.areaID)}
                            className="btn btn-primary btn-sm shadow-sm"
                          >
                            Edit {resAreaData.areaName}
                          </a>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>

            {/*NEW PAGINATION*/}
            <Paginate
              activePage={activePage}
              count={count}
              rowsPerPage={rowsPerPage}
              totalPages={totalPages}
              setActivePage={setActivePage}
            />
          </div>
        </div>
      </div>

      {/* MODAL */}
      <DisplayModal
        show={show}
        close={onModalClose}
        message={message}
        title={"Residential Area"}
      />
    </section>
  );
};

// EDIT SELECTED RESIDENTIAL AREA INFORMATION COMPONENT
export const EditResidentialArea = () => {
  // HOOKS
  const [area, setArea] = useState([]);
  const [areaName, setAreaName] = useState("");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);

  // RESIDENTIAL AREA ID
  const { id } = useParams();

  // USE NAVIGATE HOOK
  const navigate = useNavigate();

  // OPEN/CLOSE MODAL FUNCTION
  function onModalOpen() {
    setShow(true);
  }

  function onModalClose() {
    setShow(false);
    if (success) navigate("/dashboard/residential_area/view_residential_area");
  }

  // UPDATE RESIDENTIAL AREA INFORMATION
  async function updateResidentialArea(e) {
    e.preventDefault();

    await area.forEach((item) => {
      if (item) {
        axios
          .put(
            _updateResidentialArea(),
            {
              id: id,
              areaName: areaName === "" ? item.areaName : areaName,
            },
            { headers: authHeader.authHeader() }
          )
          .then((data) => {
            if (data.data.success) {
              setSuccess(true);
              setMessage(data.data.message);

              onModalOpen();
            } else {
              setSuccess(false);
              setMessage(data.data.message);

              onModalOpen();
            }
          })
          .catch((error) => {
            setSuccess(false);
            setMessage(
              `Residential area details could not be changed. ${error.message}.`
            );

            onModalOpen();
          });
      }
    });
  }

  // USE EFFECT
  useEffect(() => {
    // GET SELECTED RESIDENTIAL AREA INFORMATION THROUGH API CALL
    async function residentialAraInfo() {
      setLoading(true);

      await axios
        .get(getResidentialAreaByID(id), {
          headers: authHeader.authHeader(),
        })
        .then((data) => {
          if (data.data.success) {
            setLoading(false);
            setArea(data.data.result);
          } else {
            setLoading(false);
            setMessage(data.data.message);

            onModalOpen();
          }
        })
        .catch((error) => {
          setLoading(false);
          setMessage(
            `Residential area information could not be loaded. ${error.message}.`
          );

          onModalOpen();
        });
    }

    residentialAraInfo();
  }, [id]);

  // HTML AND JSX RENDER
  return (
    <section className="edit-residential-area">
      <div className="container">
        <h1>Edit Residential Area</h1>

        {loading && (
          <div>
            <p className={"text-muted center"}>Loading area details...</p>

            <div className="spinner">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        )}

        {!area.length && !loading && (
          <p className={"text-muted center"}>
            Residential area details could not be loaded.
          </p>
        )}

        {!loading &&
          area.map((areaItem) => {
            return (
              <div
                className="card shadow-sm border-primary"
                key={areaItem.areaID}
              >
                <div className="card-body">
                  <form onSubmit={(e) => updateResidentialArea(e)}>
                    <Input
                      label={"Residential area name"}
                      type={"text"}
                      name={"residential-area"}
                      onChange={(e) => setAreaName(e.target.value)}
                      defaultValue={areaItem.areaName}
                    />

                    <div className="text-center">
                      <button
                        className="btn btn-primary shadow-sm"
                        type="submit"
                      >
                        Update {areaItem.areaName} details
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            );
          })}
      </div>

      {/* MODAL */}
      <DisplayModal
        show={show}
        close={onModalClose}
        message={message}
        title={"Residential Area"}
      />
    </section>
  );
};
